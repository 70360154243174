import styled from "@emotion/styled";
import * as React from "react";
import { Themed } from "../../lib/types";

export const IMAGE_MIN_WIDTH = "10rem";
export const IMAGE_MAX_WIDTH = "18rem";

const Root = styled.div<Themed & { width: string; clickable?: boolean }>`
  width: 100%;
  height: auto;
  aspect-ratio: 3/4;
  /* max-width: ${({ width }) => width}; */
  overflow: hidden;
  border-radius: 10px;
  transition: 0.5s;
  box-sizing: border-box;
  position: relative;
  ${({ clickable }) =>
    clickable === true
      ? `
    cursor: pointer;

    &:hover img {
      scale: 1.05;
      filter: brightness(110%);
      transition: 0.5s ease-in-out;
    }

    &:hover div {
      transition: 0.3s ease-in-out;
      opacity: 0.2;
    }
  `
      : ``}
`;

export const Image = styled.img<Themed & { width: string }>`
  height: auto;
  display: block;
  border-radius: 10px;
  width: 100%;
  /* max-width: ${({ width }) => width}; */
  z-index: 1;
  aspect-ratio: 3/4;
  position: absolute;
  top: 0;
  left: 0;

  transition: 0.5s;
  filter: brightness(100%);
  scale: 1;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
  opacity: 0;
  width: 100%;
  height: 100%;
`;

type CoverImageProps = {
  alt: string;
  className?: string;
  src: string;
  width?: string;
  onClick?: () => void;
};

const CoverImage = React.forwardRef<HTMLImageElement, CoverImageProps>(({ src, alt, className, width = IMAGE_MAX_WIDTH, onClick }, ref) => (
  <Root width={width} onClick={onClick} clickable={!!onClick}>
    <Overlay />
    <Image ref={ref} className={className} src={src} alt={alt} width={width} crossOrigin="anonymous" />
  </Root>
));

export default CoverImage;
