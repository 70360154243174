import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserGame } from "neorak-game-lib-model";
import { UserGameFilter } from "../../../domain/userGameFilter";
import { sortUserGamesByName } from "../../../lib/sort";
import { MaintainAction } from "../../../lib/types";
import { AppRootState } from "./store";

export type UserGameState = {
  userGames: UserGame[];
  selectedUserGame?: UserGame;
  isLoadingGames: boolean;
  maintainAction?: MaintainAction;
  filter: UserGameFilter;
};

const initialState: UserGameState = {
  isLoadingGames: false,
  userGames: [],
  filter: {},
};

const userGameSlice = createSlice({
  name: "userGame",
  initialState,
  reducers: {
    addUserGameToStore: (state: UserGameState, action: PayloadAction<UserGame>) => {
      state.userGames = [...state.userGames, action.payload].sort(sortUserGamesByName);
      return state;
    },
    loadingUserGameSuccessful: (state: UserGameState, action: PayloadAction<UserGame[]>) => {
      state.isLoadingGames = false;
      state.userGames = action.payload.sort(sortUserGamesByName);
      state.selectedUserGame = undefined;
      state.maintainAction = undefined;
      return state;
    },
    removeUserGameFromStore: (state: UserGameState, action: PayloadAction<UserGame>) => {
      state.userGames = state.userGames.filter((game) => game._id !== action.payload._id).sort(sortUserGamesByName);
      return state;
    },
    startLoadingUserGames: (state: UserGameState) => {
      state.isLoadingGames = true;
      return state;
    },
    storeUpdatedUserGameEntry: (state: UserGameState, action: PayloadAction<UserGame>) => {
      state.userGames = state.userGames.map((game) => (game._id === action.payload._id ? action.payload : game)).sort(sortUserGamesByName);
      return state;
    },
    updateFilter: (state: UserGameState, action: PayloadAction<UserGameFilter>) => {
      state.filter = action.payload;
      return state;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const userGameSelector = (state: AppRootState): UserGameState => state.profile.userGames;

export const { addUserGameToStore, loadingUserGameSuccessful, removeUserGameFromStore, reset, startLoadingUserGames, storeUpdatedUserGameEntry, updateFilter } = userGameSlice.actions;

export default userGameSlice.reducer;
