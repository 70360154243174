import Flag from "@mui/icons-material/Flag";
import NotInterested from "@mui/icons-material/NotInterested";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import PlaylistPlay from "@mui/icons-material/PlaylistPlay";
import Remove from "@mui/icons-material/Remove";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { LegacyGameStatus, UserGameStatus } from "neorak-game-lib-model";

type GameStatusDetail = {
  label: string;
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
};

type GameStatusInfoType = { [key in UserGameStatus]: GameStatusDetail };

const GameStatusInfo: GameStatusInfoType = {
  [UserGameStatus.NONE]: {
    label: "None",
    icon: Remove,
  },
  [UserGameStatus.FINISHED]: {
    label: "Finished",
    icon: Flag,
  },
  [UserGameStatus.WILL_NOT_FINISH]: {
    label: "Will not finish",
    icon: NotInterested,
  },
};

type LegacyGameStatusInfoType = { [key in LegacyGameStatus]: GameStatusDetail };

export const LegacyGameStatusInfo: LegacyGameStatusInfoType = {
  [LegacyGameStatus.NONE]: {
    label: "None",
    icon: Remove,
  },
  [LegacyGameStatus.STARTED]: {
    label: "Currently Playing",
    icon: PlayCircleFilled,
  },
  [LegacyGameStatus.WILL_PLAY_NEXT]: {
    label: "Playing next",
    icon: PlaylistPlay,
  },
  [LegacyGameStatus.FINISHED]: {
    label: "Finished",
    icon: Flag,
  },
  [LegacyGameStatus.WILL_NOT_FINISH]: {
    label: "Will not finish",
    icon: NotInterested,
  },
};

export default GameStatusInfo;
