import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Divider, IconButton, InputBase, LinearProgress, Paper } from "@mui/material";
import { Game, GameMode, Genre, Platform, PlayerPerspective, Theme } from "neorak-game-lib-model";
import React, { useState } from "react";
import { useFocus } from "../../lib/hooks/useFocus";
import { Themed } from "../../lib/types";
import { GameSearchResultItem } from "../AddGame/GameSearchResultItem";

const SearchBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const Root = styled.div<Themed>`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  max-width: 700px;
`;

type Props = {
  gameTitle: string;
  games: Game[];
  searching: boolean;
  genres: Genre[];
  platforms: Platform[];
  gameModes: GameMode[];
  playerPerspectives: PlayerPerspective[];
  themes: Theme[];
  onSearch: (title: string) => void;
  onClick: (game: Game) => void;
};

export const GameSearch: React.FC<Props> = ({ gameTitle, games, searching, genres, platforms, gameModes, playerPerspectives, themes, onSearch, onClick }) => {
  const [title, setTitle] = useState(gameTitle);
  const [inputRef] = useFocus();
  //TODO: Cover aspect ratio: 3:4
  return (
    <Root>
      <SearchBlock>
        <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }}>
          <InputBase
            inputRef={inputRef}
            autoFocus
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Game"
            inputProps={{ "aria-label": "search game" }}
            value={title}
            disabled={searching}
            onChange={(e) => setTitle(e.currentTarget.value || "")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(title);
              }
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search" disabled={!title || searching} onClick={() => onSearch(title)}>
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions" disabled={!title || searching} onClick={() => setTitle("")}>
            <ClearIcon />
          </IconButton>
        </Paper>
        {searching && <LinearProgress />}
      </SearchBlock>
      {games.map((game) => (
        <GameSearchResultItem
          key={game.thirdPartyId}
          game={game}
          gameModes={gameModes}
          genres={genres}
          platforms={platforms}
          themes={themes}
          playerPerspectives={playerPerspectives}
          onClick={() => onClick(game)}
        />
      ))}
    </Root>
  );
};
