import { GameCollection } from "neorak-game-lib-model";
import { FormValidation, ValidationError } from "../lib/types";

export const createNewGameCollection = (): GameCollection => ({
  name: "New Collection",
});

export const validateGameCollection: FormValidation<GameCollection> = (values: GameCollection) => {
  const errors: ValidationError<GameCollection> = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};
