"use strict";
exports.__esModule = true;
exports.PLAYING_COLLECTION = {
    _id: "6wTTblUJpwxGb4HHBw0YM",
    name: "Playing",
    createdAt: 1698793200000,
    updatedAt: 1698793200000
};
exports.PLAYING_NEXT_COLLECTION = {
    _id: "KpqAnlatZnxAF3vDZtFnr",
    name: "Next",
    createdAt: 1698793200000,
    updatedAt: 1698793200000
};
exports.WISHLIST = {
    _id: "o-2RaTAvPuIRai3p5wJ3c",
    name: "Wishlist",
    createdAt: 1698793200000,
    updatedAt: 1698793200000
};
