import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GameCollection } from "neorak-game-lib-model";
import { MaintainAction } from "../../../lib/types";
import { AppRootState } from "./store";

export type GameCollectionsState = {
  collections: GameCollection[];
  loadingGameCollections: boolean;
  selectedGameCollection?: GameCollection;
  maintainAction?: MaintainAction;
};

const initialState: GameCollectionsState = {
  collections: [],
  loadingGameCollections: false,
};

const gameCollectionsSlice = createSlice({
  name: "gameCollections",
  initialState,
  reducers: {
    startLoadingGameCollections: (state: GameCollectionsState) => {
      state.loadingGameCollections = true;
    },
    loadingGameCollectionsSuccessful: (state: GameCollectionsState, action: PayloadAction<GameCollection[]>) => {
      state.loadingGameCollections = false;
      state.collections = action.payload;
      state.selectedGameCollection = undefined;
      state.maintainAction = undefined;
    },
    createGameCollection: (state: GameCollectionsState, action: PayloadAction<GameCollection>) => {
      state.selectedGameCollection = action.payload;
      state.maintainAction = "create";
    },
    editGameCollection: (state: GameCollectionsState, action: PayloadAction<GameCollection>) => {
      state.selectedGameCollection = action.payload;
      state.maintainAction = "edit";
    },
    endMaintainGameCollection: (state: GameCollectionsState) => {
      state.selectedGameCollection = undefined;
      state.maintainAction = undefined;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const gameCollectionsSelector = (state: AppRootState): GameCollectionsState => state.profile.gameCollections;

export const {
  loadingGameCollectionsSuccessful,
  reset,
  startLoadingGameCollections,
  createGameCollection,
  editGameCollection,
  endMaintainGameCollection,
} = gameCollectionsSlice.actions;

export default gameCollectionsSlice.reducer;
