import AddIcon from "@mui/icons-material/Add";
import { Divider, List } from "@mui/material";
import React from "react";
import { DataListItem } from "./DataListItem";
import { FittingDialog } from "./FittingDialog";

type Props<T> = {
  icon: React.ReactNode;
  title: string;
  value?: T;
  values: T[];
  getIcon?: (value: T) => React.ReactNode;
  getId: (value: T) => string;
  getLabel: (value: T) => string;
  getSecondaryLabel?: (value: T) => string | undefined;
  isEqual?: (a?: T, b?: T) => boolean;
  onAddClick?: () => void;
  onClick: (value: T) => void;
  onClose: () => void;
};

export const DataListDialog = <T,>({ icon, title, value, values, onAddClick, onClick, onClose, getId, getLabel, getSecondaryLabel, getIcon, isEqual = (a, b) => a === b }: Props<T>) => (
  <>
    <FittingDialog maxWidth="xs" titleAction={onClose} title={title}>
      <List sx={{ pt: 0 }}>
        {values.map((v) => (
          <DataListItem
            icon={getIcon ? getIcon(v) : icon}
            key={getId(v)}
            label={getLabel(v)}
            secondaryLabel={getSecondaryLabel ? getSecondaryLabel(v) : undefined}
            onClick={() => onClick(v)}
            selected={isEqual(value, v)}
          />
        ))}
        {onAddClick && (
          <>
            {values.length > 0 && <Divider />}
            <DataListItem key="add" icon={<AddIcon />} label="Add" onClick={() => onAddClick()} />
          </>
        )}
      </List>
    </FittingDialog>
  </>
);
