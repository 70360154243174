import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { Outlet } from "react-router-dom";
import { ROUTE_LOGIN } from "../../lib/constants/routes";
import { RequireAuthRouteState } from "../../lib/types";
import { loginSelector } from "../../services/storage/redux/loginReducer";

export const RequireAuth: React.FC<{}> = () => {
  const location = useLocation();
  const { token } = useSelector(loginSelector);
  const routeState: RequireAuthRouteState = { from: location.pathname };

  let path: string | undefined = undefined;
  if (!token) path = ROUTE_LOGIN;

  return path ? <Navigate to={path} state={routeState} /> : <Outlet />;
};
