import { GameCollection } from "neorak-game-lib-model";
import { deleteJson, getJson, postJson, putJson } from "../../lib/http";

const API_PATH = "/api/v2/gameCollection";

export const getGameCollections = async (token?: string): Promise<GameCollection[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get gamecollection-entries");
};

export const createGameCollection = async (entity: GameCollection, token?: string): Promise<void> => {
  await postJson(API_PATH, entity, { token });
};

export const updateGameCollection = async (entity: GameCollection, token?: string): Promise<void> => {
  await putJson(`${API_PATH}/${entity._id}`, entity, { token });
};

export const deleteGameCollection = async (entity: GameCollection, token?: string): Promise<void> => {
  await deleteJson(`${API_PATH}/${entity._id}`, { token });
};
