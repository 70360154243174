import { Genre, LoadStatistic } from "neorak-game-lib-model";
import { getJson } from "../../lib/http";

const API_PATH = "/api/v2/genre";

export const reloadGenres = async (token?: string): Promise<LoadStatistic> => {
  const result = await getJson(`${API_PATH}/load`, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to reload genres");
};

export const getGenres = async (token?: string): Promise<Genre[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get genres");
};
