"use strict";
exports.__esModule = true;
var GameCategory;
(function (GameCategory) {
    GameCategory["main_game"] = "main_game";
    GameCategory["dlc_addon"] = "dlc_addon";
    GameCategory["expansion"] = "expansion";
    GameCategory["bundle"] = "bundle";
    GameCategory["standalone_expansion"] = "standalone_expansion";
    GameCategory["mod"] = "mod";
    GameCategory["episode"] = "episode";
    GameCategory["season"] = "season";
    GameCategory["remake"] = "remake";
    GameCategory["remaster"] = "remaster";
    GameCategory["expanded_game"] = "expanded_game";
    GameCategory["port"] = "port";
    GameCategory["fork"] = "fork";
    GameCategory["pack"] = "pack";
    GameCategory["update"] = "update";
})(GameCategory = exports.GameCategory || (exports.GameCategory = {}));
var GameStatus;
(function (GameStatus) {
    GameStatus["released"] = "released";
    GameStatus["alpha"] = "alpha";
    GameStatus["beta"] = "beta";
    GameStatus["early_access"] = "early_access";
    GameStatus["offline"] = "offline";
    GameStatus["cancelled"] = "cancelled";
    GameStatus["rumored"] = "rumored";
    GameStatus["delisted"] = "delisted";
})(GameStatus = exports.GameStatus || (exports.GameStatus = {}));
