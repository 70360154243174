import { Game } from "neorak-game-lib-model";
import { MigrationStorage, RefdataStorageService } from "../../domain/ports";
import { createNewUserGame } from "../../domain/userGame";

type SelectBundledGameActionDependencies = Pick<MigrationStorage, "bundle" | "setBundledGame"> & Pick<RefdataStorageService, "gameModes" | "themes" | "playerPerspectives" | "genres">;

export const selectBundledGame = async (game: Game, deps: SelectBundledGameActionDependencies) => {
  const { sortName, collections, ownership, statusHistory, platformMedia, calcPlaytime } = deps.bundle!.userGame;
  const userGame = createNewUserGame(
    game,
    {
      gameModes: deps.gameModes,
      genres: deps.genres,
      playerPerspectives: deps.playerPerspectives,
      themes: deps.themes,
    },
    {
      sortName,
      collections,
      ownership,
      statusHistory,
      platformMedia,
      calcPlaytime,
    }
  );
  deps.setBundledGame(userGame);
};
