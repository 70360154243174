import styled from "@emotion/styled";
import { UserGame } from "neorak-game-lib-model";
import React, { useRef } from "react";
import CoverImage from "../CoverImage";

const toCanvas = (img: HTMLImageElement) => {
  const cvs = document.createElement("canvas");
  cvs.width = img.naturalWidth;
  cvs.height = img.naturalHeight;
  cvs.getContext("2d")?.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
  return cvs;
};

const toJpeg = async (canvas: HTMLCanvasElement) =>
  new Promise<Uint8Array>((resolve, reject) => canvas.toBlob(async (blob) => (blob && blob.size > 0 ? resolve(new Uint8Array(await blob.arrayBuffer())) : reject()), "image/jpeg", 0.8));

const Cover = styled(CoverImage)`
  cursor: pointer;
`;

type Props = {
  game: UserGame;
  onClick: (data: Uint8Array) => void;
};

export const ThirdPartyCover: React.FC<Props> = ({ game, onClick }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  return (
    <Cover
      ref={imageRef}
      alt={game.game.name}
      src={`https://images.igdb.com/igdb/image/upload/t_cover_big_2x/${game.game.cover}.jpg`}
      onClick={async () => {
        if (imageRef?.current) {
          const can = toCanvas(imageRef.current);
          const data = await toJpeg(can);
          onClick(data);
        }
      }}
    />
  );
};
