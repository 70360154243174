import { GameCategory, UserGame } from "neorak-game-lib-model";
import { ApiService, ImageStorageService, MigrationStorage, ProfileStorageService } from "../../domain/ports";
import { createUserGame } from "../createUserGame";

export type SaveMigratedGameDependencies = Pick<MigrationStorage, "saveFoundGames" | "startAction" | "saveUserGame" | "setBundle" | "setBundledGame" | "setBundledGames"> &
  Pick<ApiService, "createUserGame" | "searchGames"> &
  Pick<ProfileStorageService, "addUserGame" | "userGames"> &
  Pick<ImageStorageService, "updateImages">;

export const saveMigratedGame = async (userGame: UserGame, coverData: Uint8Array | undefined, deps: SaveMigratedGameDependencies) => {
  let saveUserGame = true;
  if (userGame.game.category === GameCategory.bundle) {
    deps.setBundle({ userGame, coverData });
    deps.setBundledGame(undefined);
    deps.setBundledGames(undefined);
    let bundledGames = await deps.searchGames({ bundleId: userGame.game.thirdPartyId });
    bundledGames = bundledGames.filter((bg) => deps.userGames.find((g) => g.game.thirdPartyId === bg.thirdPartyId) === undefined);
    if (bundledGames.length > 0) {
      deps.setBundledGames(bundledGames);
      saveUserGame = false;
    } else {
      deps.setBundle(undefined);
      deps.setBundledGame(undefined);
      deps.setBundledGames(undefined);
    }
  }
  if (saveUserGame) {
    await createUserGame(userGame, coverData, deps);
    deps.startAction(undefined);
    deps.saveFoundGames([]);
    deps.saveUserGame(undefined);
  }
};
