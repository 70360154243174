import styled from "@emotion/styled";
import { Avatar, Divider, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { GameToMigrate } from "neorak-game-lib-model";
import React from "react";
import { LegacyGameStatusInfo } from "../../lib/constants/gameStatusInfo";

const ListIcon = styled(Avatar)`
  border-radius: 0;
  img {
    object-fit: contain;
  }
`;

type Props = {
  gtms: GameToMigrate[];
  getCoverUrl: (g: GameToMigrate) => string;
  onClick: (g: GameToMigrate) => void;
};

export const GtmList: React.FC<Props> = ({ gtms, getCoverUrl, onClick }) => {
  return (
    <>
      <h1>{`${gtms.length} games to migrate`}</h1>
      <List>
        {gtms.map((gtm) => (
          <React.Fragment key={gtm.id}>
            <ListItemButton alignItems="flex-start" onClick={() => onClick(gtm)}>
              <ListItemAvatar>
                <ListIcon alt={gtm.title} src={getCoverUrl(gtm)} />
              </ListItemAvatar>
              <ListItemText
                primary={`${gtm.title} (${gtm.releaseYear})`}
                secondary={
                  <React.Fragment>
                    <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                      {LegacyGameStatusInfo[gtm.status].label}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItemButton>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
    </>
  );
};
