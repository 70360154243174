import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { ApiService, DataLoadingStatisticStorage, RefdataStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

export const loadPlayerPerspectives = async (
  deps: Pick<ApiService, "getPlayerPerspectives"> &
    Pick<RefdataStorageService, "loadingPlayerPerspectivesSuccessful" | "startLoadingPlayerPerspectives"> &
    Pick<DataLoadingStatisticStorage, "addRefdataLoadingStatistics" | "updateRefdataLoadingStatistic">
) => {
  const stat: DataloadingStatistic = { id: "playerPerspective", name: "Player Perspective", status: "running" };
  try {
    deps.startLoadingPlayerPerspectives();
    deps.addRefdataLoadingStatistics(stat);
    const playerPerspectives = await deps.getPlayerPerspectives();
    deps.loadingPlayerPerspectivesSuccessful(playerPerspectives ? playerPerspectives.sort(sortRefdataByName) : []);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "finished" });
    return playerPerspectives;
  } catch (e) {
    console.log(e);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "failed" });
    return [];
  }
};
