import { UserGame } from "neorak-game-lib-model";
import { useCallback } from "react";
import { getImageId } from "../../domain/dbImage";
import { getImageUrl } from "../../lib/image";
import { useApiService } from "../../services/api";
import { useImageStorage } from "../../services/db/dbAdapter";
import { useHomeStorage, useProfileStorage } from "../../services/storage";
import { deleteGame } from "./deleteGame";
import { saveGame } from "./saveGame";

const relates = (attr: keyof UserGame["game"], a: UserGame, b: UserGame) => (a.game[attr] as number[])?.includes(b.game.thirdPartyId) || (b.game[attr] as number[])?.includes(a.game.thirdPartyId);

export const useHome = () => {
  const homeStorage = useHomeStorage();
  const apiService = useApiService();
  const profileStorage = useProfileStorage();
  const imageStorage = useImageStorage();
  const { coverUrl, relatedGames, selected, setCoverUrl, setMaintainAction, setRelatedGames, setSelected } = homeStorage;

  const selectGame = useCallback(
    async (game?: UserGame, games: UserGame[] = []) => {
      if (game) {
        const cover = await imageStorage.getImage(getImageId(game));
        setCoverUrl(cover ? getImageUrl(cover.data) : undefined);
        setSelected(game);
        setMaintainAction("edit");
        setRelatedGames(
          games.filter(
            (g) =>
              relates("bundleIds", game, g) ||
              relates("dlcIds", game, g) ||
              relates("extensionIds", game, g) ||
              relates("portIds", game, g) ||
              relates("remakeIds", game, g) ||
              relates("forkIds", game, g) ||
              relates("expandedGameIds", game, g) ||
              relates("remasterIds", game, g)
          )
        );
      } else {
        setCoverUrl(undefined);
        setSelected(undefined);
        setMaintainAction(undefined);
        setRelatedGames([]);
      }
    },
    [setCoverUrl, setSelected, setMaintainAction, setRelatedGames, imageStorage]
  );

  return {
    coverUrl,
    relatedGames,
    selected,
    selectGame,
    saveGame: useCallback(
      (ug: UserGame, coverData?: Uint8Array) =>
        saveGame(ug, coverData, selectGame, {
          ...homeStorage,
          ...apiService,
          ...profileStorage,
          ...imageStorage,
        }),
      [homeStorage, apiService, profileStorage, imageStorage, selectGame]
    ),
    deleteGame: useCallback(
      (ug: UserGame, coverData?: Uint8Array) =>
        deleteGame(ug, coverData, selectGame, {
          ...homeStorage,
          ...apiService,
          ...profileStorage,
          ...imageStorage,
        }),
      [homeStorage, apiService, profileStorage, imageStorage, selectGame]
    ),
  };
};
