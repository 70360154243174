import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { ApiService, DataLoadingStatisticStorage, RefdataStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

export const loadGameModes = async (
  deps: Pick<ApiService, "getGameModes"> &
    Pick<RefdataStorageService, "loadingGameModesSuccessful" | "startLoadingGameModes"> &
    Pick<DataLoadingStatisticStorage, "addRefdataLoadingStatistics" | "updateRefdataLoadingStatistic">
) => {
  const stat: DataloadingStatistic = { id: "gameMode", name: "Game Mode", status: "running" };
  try {
    deps.startLoadingGameModes();
    deps.addRefdataLoadingStatistics(stat);
    const gameModes = await deps.getGameModes();
    deps.loadingGameModesSuccessful(gameModes ? gameModes.sort(sortRefdataByName) : []);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "finished" });
    return gameModes;
  } catch (e) {
    console.log(e);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "failed" });
    return [];
  }
};
