import AnimationIcon from "@mui/icons-material/Animation";
import BackHandIcon from "@mui/icons-material/BackHand";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import DevicesOther from "@mui/icons-material/DevicesOther";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import GamePad from "@mui/icons-material/Gamepad";
import Home from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import InsertChart from "@mui/icons-material/InsertChart";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import Lock from "@mui/icons-material/Lock";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PeopleIcon from "@mui/icons-material/People";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import SdCard from "@mui/icons-material/SdCard";
import Settings from "@mui/icons-material/Settings";
import VideoGameAsset from "@mui/icons-material/VideogameAsset";

export const HOME = Home;
export const SETTINGS = Settings;
export const STATISTICS = InsertChart;
export const GAMES = VideoGameAsset;
export const MEDIUM = SdCard;
export const GENRE = GamePad;
export const THEME = AnimationIcon;
export const PLAYER_PERSPECTIVE = SatelliteAltIcon;
export const GAME_MODE = PeopleIcon;
export const PLATFORM = DevicesOther;
export const PLATFORM_FAMILY = Diversity2Icon;
export const LOGIN = Lock;
export const INFO = InfoIcon;
export const PLATFORM_MEDIA = DeviceHubIcon;
export const OWNERSHIP = BackHandIcon;
export const GAME_COLLECTION = LibraryBooksIcon;
export const GAME_STATUS = MilitaryTechIcon;
