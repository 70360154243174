"use strict";
exports.__esModule = true;
var UserGameStatus;
(function (UserGameStatus) {
    UserGameStatus["FINISHED"] = "FINISHED";
    UserGameStatus["WILL_NOT_FINISH"] = "WILL_NOT_FINISH";
    UserGameStatus["NONE"] = "NONE";
})(UserGameStatus = exports.UserGameStatus || (exports.UserGameStatus = {}));
var LegacyGameStatus;
(function (LegacyGameStatus) {
    LegacyGameStatus["FINISHED"] = "FINISHED";
    LegacyGameStatus["WILL_NOT_FINISH"] = "WILL_NOT_FINISH";
    LegacyGameStatus["STARTED"] = "STARTED";
    LegacyGameStatus["WILL_PLAY_NEXT"] = "WILL_PLAY_NEXT";
    LegacyGameStatus["NONE"] = "NONE";
})(LegacyGameStatus = exports.LegacyGameStatus || (exports.LegacyGameStatus = {}));
