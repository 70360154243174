import { Game } from "neorak-game-lib-model";
import { AddGameStorage, RefdataStorageService } from "../../domain/ports";
import { createNewUserGame } from "../../domain/userGame";

type EditNewGameActionDependencies = Pick<AddGameStorage, "startAction" | "saveUserGame"> & Pick<RefdataStorageService, "gameModes" | "themes" | "playerPerspectives" | "genres">;

export const editNewGameAction = async (game: Game, deps: EditNewGameActionDependencies) => {
  deps.startAction("Edit");
  const userGame = createNewUserGame(game, {
    gameModes: deps.gameModes,
    genres: deps.genres,
    playerPerspectives: deps.playerPerspectives,
    themes: deps.themes,
  });
  deps.saveUserGame(userGame);
};
