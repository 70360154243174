import { useMemo } from "react";
import { useHome } from "../../application/home/useHome";
import { groupFinishedGamesPerYear } from "../../domain/userGame";
import { formatCategory } from "../../lib/format";
import { useProfileStorage, useRefdataStorage } from "../../services/storage";
import { RelatedGames } from "../Home/ReleatedGames";
import { AppFrame } from "../shared/AppFrame";
import { EditUserGame } from "../shared/EditUserGame/EditUserGame";
import { FittingDialog } from "../shared/FittingDialog";
import PerYearStatistics from "./PerYearStatistics";

const useStatistics = () => {
  const { userGames } = useProfileStorage();
  const yearStatistics = useMemo(() => groupFinishedGamesPerYear(userGames), [userGames]);
  return { yearStatistics };
};

type Props = {};

export const StatisticsView: React.FC<Props> = () => {
  const { yearStatistics } = useStatistics();
  const { platformMedia, gameCollections, userGames } = useProfileStorage();
  const { genres, platforms, gameModes, playerPerspectives, themes, media } = useRefdataStorage();
  const { selected, relatedGames, coverUrl, deleteGame, selectGame, saveGame } = useHome();
  return (
    <AppFrame>
      <PerYearStatistics statistics={yearStatistics} onClick={selectGame} />
      {selected && coverUrl && (
        <FittingDialog key={selected.game.thirdPartyId} title={`Selected ${formatCategory(selected.game.category)}`} onClose={() => selectGame(undefined)} titleAction={() => selectGame(undefined)}>
          <EditUserGame
            coverUrl={coverUrl}
            game={selected}
            gameCollections={gameCollections}
            gameModes={gameModes}
            genres={genres}
            media={media}
            platformMedia={platformMedia}
            platforms={platforms}
            playerPerspectives={playerPerspectives}
            themes={themes}
            onSave={saveGame}
            onDelete={deleteGame}
            upperBlock={<RelatedGames relatedGames={relatedGames} onClick={(g) => selectGame(g, userGames)} />}
          />
        </FittingDialog>
      )}
    </AppFrame>
  );
};
