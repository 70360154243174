import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { UserGame } from "neorak-game-lib-model";
import { useEffect, useState } from "react";
import { getImageId } from "../../domain/dbImage";
import { getName } from "../../domain/game";
import { useIsInView } from "../../lib/hooks/useIsInView";
import { getImageUrl } from "../../lib/image";
import { useImageStorage } from "../../services/db/dbAdapter";
import CoverImage from "../shared/CoverImage";

const Root = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 3/4;
`;

const LoadingRoot = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

type Props = {
  game: UserGame;
  index?: number;
  onClick: () => void;
};

export const Cover: React.FC<Props> = ({ game, index, onClick }) => {
  const [coverUrl, setCoverUrl] = useState<string | undefined>();
  const imageStorage = useImageStorage();
  const { inView, ref } = useIsInView(".content-block", "100%");
  useEffect(() => {
    const loadCover = async () => {
      const cover = await imageStorage.getImage(getImageId(game));
      if (cover) {
        setCoverUrl(getImageUrl(cover.data));
      }
    };
    if (inView) loadCover();
    return () => {
      coverUrl && URL.revokeObjectURL(coverUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.updatedAt, inView]);
  return (
    <Root ref={ref}>
      {coverUrl ? (
        <CoverImage alt={getName(game)} src={coverUrl} onClick={onClick} />
      ) : (
        <LoadingRoot onClick={onClick}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </LoadingRoot>
      )}
    </Root>
  );
};
