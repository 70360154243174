import { Ownership } from "neorak-game-lib-model";
import React from "react";
import GameOwnershipInfo from "../../../lib/constants/gameOwnership";
import * as Icon from "../../../lib/constants/pageIcons";
import { DataListDialog } from "../DataListDialog";

type Props = {
  value?: Ownership;
  onClose: () => void;
  onClick: (value: Ownership) => void;
};

export const OwnershipDialog: React.FC<Props> = ({ value, onClick, onClose }) => (
  <DataListDialog
    getIcon={() => <Icon.OWNERSHIP />}
    getId={(value) => value}
    getLabel={(value) => GameOwnershipInfo[value].label}
    icon={<Icon.OWNERSHIP />}
    onClick={onClick}
    onClose={onClose}
    title={"Ownership"}
    value={value}
    values={[Ownership.OWN, Ownership.ABO, Ownership.OWNED, Ownership.NONE]}
  />
);
