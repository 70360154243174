import { nanoid } from "@reduxjs/toolkit";
import {
  Game,
  GameCollection,
  GamePlatformMedium,
  GameStatusHistory,
  GameToMigrate,
  LegacyGameStatus,
  LegacyGameStatusHistory,
  LegacyOwnership,
  Ownership,
  PLAYING_COLLECTION,
  PLAYING_NEXT_COLLECTION,
  PlatformMediaToMigrate,
  UserGame,
  UserGameStatus,
  WISHLIST,
} from "neorak-game-lib-model";
import { sortUserGamesByName } from "../lib/sort";
import { calculateStatus } from "./changePlaytime";
import { PlatformMedia } from "./platformMedia";
import { ProfileStorageService, RefdataStorageService } from "./ports";

export type GameToStore = { userGame: UserGame; coverData: Uint8Array | undefined };

type MigrationRefdata = Pick<RefdataStorageService, "gameModes" | "themes" | "playerPerspectives" | "genres"> & Pick<ProfileStorageService, "gameCollections" | "platformMedia">;

export interface FinishedGame {
  game: UserGame;
  stateChangedAt: number;
}

export interface YearStatistics {
  gamesPerYear: Map<number, FinishedGame[]>;
  years: number[];
}

export const toUserGame = (gtm: GameToMigrate, game: Game, refdata: MigrationRefdata): UserGame => {
  const result: UserGame = {
    game: {
      category: game.category,
      checksum: game.checksum,
      cover: game.cover,
      firstReleaseDate: game.firstReleaseDate,
      franchises: game.franchises,
      gameModes: toRefdataIds(refdata.gameModes, game.gameModes),
      themes: toRefdataIds(refdata.themes, game.themes),
      playerPerspectives: toRefdataIds(refdata.playerPerspectives, game.playerPerspectives),
      genres: toRefdataIds(refdata.genres, game.genres),
      name: game.name,
      releaseYear: game.releaseYear,
      summary: game.summary,
      storyline: game.storyline,
      thirdPartyId: game.thirdPartyId,
      status: game.status,
      bundleIds: game.bundleIds,
      dlcIds: game.dlcIds,
      extensionIds: game.extensionIds,
      forkIds: game.forkIds,
      portIds: game.portIds,
      remakeIds: game.remakeIds,
      remasterIds: game.remasterIds,
      expandedGameIds: game.expandedGameIds,
    },
    ownership: toOwnerShip(gtm.ownership),
    status: UserGameStatus.NONE,
    statusHistory: toStatusHistories(gtm),
    coverDetails: gtm.coverDetails,
    playtime: gtm.playtime,
    calcPlaytime: gtm.playtime,
    platformMedia: toPlatformMedia(gtm.platformMedia, refdata.platformMedia),
    statusChangedAt: gtm.stateChangedAt ? new Date(gtm.stateChangedAt).getTime() : undefined,
    sortName: gtm.sortTitle,
    collections: toGameCollections(gtm, refdata.gameCollections),
  };

  result.status = calculateStatus(result.statusHistory);
  return result;
};

const toPlatformMedia = (oldPM: PlatformMediaToMigrate, userPlatformMedia: PlatformMedia[]): GamePlatformMedium[] => {
  const result: GamePlatformMedium[] = [];

  Object.keys(oldPM).forEach((platform) => {
    oldPM[platform].forEach((medium) => {
      const pm = createPlatformMedium(platform, medium, userPlatformMedia);
      if (pm) result.push(pm);
    });
  });

  return result;
};

const createPlatformMedium = (oldPlatform: string, oldMedium: string, userPlatformMedia: PlatformMedia[]): GamePlatformMedium | undefined => {
  const newPlatformName = getNewPlatformName(oldPlatform);
  const newMediumName = getNewMedium(oldMedium);
  const pm = userPlatformMedia.find((upm) => upm.platform.name === newPlatformName);
  const medium = pm?.media.find((m) => m.name === newMediumName);
  if (pm && medium) return { mediumId: medium._id!, platformId: pm.platform._id! };
  return undefined;
};

const getNewPlatformName = (oldPlatform: string): string | undefined => {
  switch (oldPlatform) {
    case "PC":
      return "PC (Microsoft Windows)";
    case "Sony Playstation 5":
      return "PlayStation 5";
    case "Sega Saturn":
      return "Sega Saturn";
    case "Nintendo Gameboy":
      return "Game Boy";
    case "Microsoft Xbox 360":
      return "Xbox 360";
    case "Nintendo N64":
      return "Nintendo 64";
    case "Nintendo Gameboy Advance":
      return "Game Boy Advance";
    case "Sony Playstation 4":
      return "PlayStation 4";
    case "Nintendo Switch":
      return "Nintendo Switch";
    case "Sony Playstation 2":
      return "PlayStation 2";
    case "Sony Playstation 3":
      return "PlayStation 3";
    case "Nintendo Wii":
      return "Wii";
    case "Sega Dreamcast":
      return "Dreamcast";
    case "Sega GameGear":
      return "Sega Game Gear";
    case "Microsoft Xbox":
      return "Xbox";
    case "Sony Playstation Portable":
      return "PlayStation Portable";
    case "Google Stadia":
      return "Google Stadia";
    case "Sony Playstation":
      return "PlayStation";
    case "Sony Playstation Vita":
      return "PlayStation Vita";
    case "Microsoft Xbox Series X/S":
      return "Xbox Series X|S";
    case "Nintendo GameCube":
      return "Nintendo GameCube";
    default:
      console.log(`ERROR: Unknown old Platform: '${oldPlatform}'`);
      return undefined;
  }
};

const getNewMedium = (oldMedium: string): string | undefined => {
  switch (oldMedium) {
    case "Steam":
      return "Steam";
    case "Disk":
    case "Cartridge":
      return "Physical";
    case "VR":
    case "Download":
      return "Digital";
    case "UPlay":
      return "UPlay";
    case "Origin":
      return "Origin";
    case "Stream":
      return "Stream";
    case "Battle.net":
      return "Battle.net";
    case "Bethesda":
      return "Bethesda";
    case "Gamepass":
    case "PS Plus":
    case "Abo":
      return "Abo";
    case "Epic":
      return "Epic";
    case "Unknown":
      return undefined;
    default:
      console.log(`ERROR: Unknown old Medium: '${oldMedium}'`);
      return undefined;
  }
};

type Refdata = Pick<RefdataStorageService, "gameModes" | "themes" | "playerPerspectives" | "genres">;

export const createNewUserGame = (game: Game, refdata: Refdata, initProps: Partial<UserGame> = {}): UserGame => ({
  game: {
    category: game.category,
    checksum: game.checksum,
    cover: game.cover,
    firstReleaseDate: game.firstReleaseDate,
    franchises: game.franchises,
    gameModes: toRefdataIds(refdata.gameModes, game.gameModes),
    themes: toRefdataIds(refdata.themes, game.themes),
    playerPerspectives: toRefdataIds(refdata.playerPerspectives, game.playerPerspectives),
    genres: toRefdataIds(refdata.genres, game.genres),
    name: game.name,
    releaseYear: game.releaseYear,
    summary: game.summary,
    storyline: game.storyline,
    thirdPartyId: game.thirdPartyId,
    status: game.status,
    bundleIds: game.bundleIds,
    dlcIds: game.dlcIds,
    extensionIds: game.extensionIds,
    forkIds: game.forkIds,
    portIds: game.portIds,
    remakeIds: game.remakeIds,
    remasterIds: game.remasterIds,
    expandedGameIds: game.expandedGameIds,
  },
  ownership: Ownership.NONE,
  status: UserGameStatus.NONE,
  statusHistory: [],
  coverDetails: undefined,
  playtime: undefined,
  calcPlaytime: undefined,
  platformMedia: [],
  statusChangedAt: undefined,
  sortName: undefined,
  collections: [],
  ...initProps,
});

const toGameCollections = (game: GameToMigrate, collections: GameCollection[]): string[] => {
  const expectedName = getExpectedCollectionName(game);
  const collection = expectedName ? collections.find((c) => c.name === expectedName) : undefined;
  return collection ? [collection._id!] : [];
};

const getExpectedCollectionName = (game: GameToMigrate): string | undefined => {
  if (game.ownership === LegacyOwnership.WANT_TO) {
    return WISHLIST.name;
  } else if (game.status === LegacyGameStatus.STARTED) {
    return PLAYING_COLLECTION.name;
  } else if (game.status === LegacyGameStatus.WILL_PLAY_NEXT) {
    return PLAYING_NEXT_COLLECTION.name;
  } else {
    return undefined;
  }
};

const toOwnerShip = (ownership: LegacyOwnership): Ownership => {
  switch (ownership) {
    case LegacyOwnership.NONE:
      return Ownership.ABO;
    case LegacyOwnership.OWN:
      return Ownership.OWN;
    case LegacyOwnership.OWNED:
      return Ownership.OWNED;
    case LegacyOwnership.WANT_TO:
      return Ownership.NONE;
  }
};

const toRefdataIds = <T extends { thirdPartyId: number; _id?: string }>(refdata: T[], thirdPartyIds?: number[]): string[] =>
  (thirdPartyIds || []).reduce<string[]>((acc, gmid) => {
    const id = refdata.find((gm) => gm.thirdPartyId === gmid)?._id;
    if (id) return [...acc, id];
    return acc;
  }, []);

const toStatusHistories = (gtm: GameToMigrate): GameStatusHistory[] => {
  const histories = gtm.statusHistory.map(toStatusHistory);

  if ([LegacyGameStatus.FINISHED, LegacyGameStatus.WILL_NOT_FINISH].includes(gtm.status)) {
    histories.push({
      id: nanoid(),
      status: gtm.status === LegacyGameStatus.WILL_NOT_FINISH ? UserGameStatus.WILL_NOT_FINISH : UserGameStatus.FINISHED,
      createdAt: gtm.stateChangedAt ? new Date(gtm.stateChangedAt).getTime() : undefined,
      statusChangedAt: gtm.stateChangedAt ? new Date(gtm.stateChangedAt).getTime() : undefined,
    });
  }
  return histories.sort((a, b) => (b.statusChangedAt || 0) - (a.statusChangedAt || 0));
};

const toStatusHistory = (history: LegacyGameStatusHistory): GameStatusHistory => ({
  id: nanoid(),
  status: history.status === LegacyGameStatus.WILL_NOT_FINISH ? UserGameStatus.WILL_NOT_FINISH : UserGameStatus.FINISHED,
  statusChangedAt: history.changedAt ? new Date(history.changedAt).getTime() : undefined,
  createdAt: history.changedAt ? new Date(history.changedAt).getTime() : undefined,
});

export const createStatusHistory = (status: UserGameStatus): GameStatusHistory => ({
  id: nanoid(),
  status,
});

export const groupFinishedGamesPerYear = (games: UserGame[]) => {
  const gamesPerYear = games
    .filter((game) => game.status === UserGameStatus.FINISHED || game.statusHistory.find((history) => history.status === UserGameStatus.FINISHED))
    .reduce<Map<number, FinishedGame[]>>((acc, game) => {
      // if (game.status === UserGameStatus.FINISHED) {
      //   addGameToYear(game, game.statusChangedAt, acc);
      // }
      game.statusHistory.forEach((history) => {
        if (history.status === UserGameStatus.FINISHED) {
          addGameToYear(game, history.statusChangedAt, acc);
        }
      });
      return acc;
    }, new Map<number, FinishedGame[]>());
  const years = Array.from(gamesPerYear.keys()).sort().reverse();
  return {
    gamesPerYear,
    years,
  };
};

const addGameToYear = (game: UserGame, changedAt: number | undefined, acc: Map<number, FinishedGame[]>) => {
  if (changedAt) {
    const year = new Date(changedAt!).getFullYear();
    if (year >= 2000) {
      const finishedGame: FinishedGame = { game, stateChangedAt: changedAt! };
      const list = acc.get(year);
      if (list) {
        list.push(finishedGame);
        list.sort(finishedByStateChanged);
      } else {
        acc.set(year, [finishedGame]);
      }
    }
  }
};

const finishedByStateChanged = (a: FinishedGame, b: FinishedGame) => {
  if (!a.stateChangedAt && !b.stateChangedAt) {
    return sortUserGamesByName(a.game, b.game);
  } else if (!a.stateChangedAt) {
    return 1;
  } else if (!b.stateChangedAt) {
    return -1;
  }
  return new Date(b.stateChangedAt).getTime() - new Date(a.stateChangedAt).getTime();
};
