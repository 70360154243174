import { LoadStatistic, Platform } from "neorak-game-lib-model";
import { getJson } from "../../lib/http";

const API_PATH = "/api/v2/platform";

export const reloadPlatforms = async (token?: string): Promise<LoadStatistic> => {
  const result = await getJson(`${API_PATH}/load`, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to reload platforms");
};

export const getPlatforms = async (token?: string): Promise<Platform[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get platforms");
};
