import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Box, CircularProgress, Fab } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React from "react";
import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";

type Props = {
  statistic: DataloadingStatistic;
};

export const LoadingStatusIcon: React.FC<Props> = ({ statistic }) => {
  let color: string | undefined;
  let icon: React.ReactNode = <DownloadIcon fontSize="small" />;
  switch (statistic.status) {
    case "finished":
      color = green[500];
      icon = <CheckIcon fontSize="small" />;
      break;
    case "failed":
      color = red[500];
      icon = <PriorityHighIcon fontSize="small" />;
      break;
  }
  return (
    <Box sx={{ m: 1, position: "relative" }}>
      <Fab aria-label="save" color="primary" sx={color ? { bgcolor: color } : {}} size="small">
        {icon}
      </Fab>
      {statistic.status === "running" && (
        <CircularProgress
          size={48}
          sx={{
            position: "absolute",
            top: -4,
            right: -4,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};
