import { sortGame } from "../../domain/game";
import { ApiService, MigrationStorage } from "../../domain/ports";

type SearchGamesActionDependencies = Pick<MigrationStorage, "saveFoundGames" | "startSearching" | "searchingFinished" | "startAction" | "saveUserGame"> & Pick<ApiService, "searchGames">;

export const searchGamesAction = async (title: string, deps: SearchGamesActionDependencies) => {
  deps.saveFoundGames([]);
  deps.saveUserGame(undefined);
  deps.startAction("Search");
  deps.startSearching();
  deps.saveFoundGames((await deps.searchGames({ title })).sort(sortGame));
  deps.searchingFinished();
};
