import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<moment.Moment, any>;

const DateInput: React.FC<Props> = ({ input, meta, ...rest }) => {
  return (
    <MobileDatePicker
      {...input}
      {...rest}
      slotProps={{
        // pass props `actions={['clear']}` to the actionBar slot
        actionBar: { actions: ["clear", "accept", "cancel"] },
        toolbar: {
          // Hide the toolbar
          hidden: true,
        },
      }}
      value={(input.value as any) === "" ? null : input.value}
    />
  );
};

export default DateInput;
