import styled from "@emotion/styled";
import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useMigrate } from "../../application/migrate/useMigrate";
import { getImageId } from "../../domain/dbImage";
import { formatCategory } from "../../lib/format";
import { Themed } from "../../lib/types";
import { useApiService } from "../../services/api";
import { GameSearchResultItem } from "../AddGame/GameSearchResultItem";
import { Cover } from "../Home/Cover";
import { AppFrame } from "../shared/AppFrame";
import { IMAGE_MIN_WIDTH } from "../shared/CoverImage";
import { EditUserGame } from "../shared/EditUserGame/EditUserGame";
import { FittingDialog } from "../shared/FittingDialog";
import { GtmDetails } from "./GtmDetails";
import { GtmList } from "./GtmList";
import { SearchEditColumn } from "./SearchEditColumn";
import { SourceListColumn } from "./SourceListColumn";

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 3fr;
  grid-gap: 1.5rem;
  overflow: auto;
`;

const BundledGamesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GamesGrid = styled.div<Themed & { maxWidth?: string }>`
  align-items: baseline;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${IMAGE_MIN_WIDTH}, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  box-sizing: border-box;
  padding: 2rem;
  width: 100%;
  align-self: baseline;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

type Props = {};

export const MigrateView: React.FC<Props> = () => {
  const {
    bundle,
    bundledGame,
    bundledGames,
    foundGames,
    gameModes,
    gamesToMigrate,
    genres,
    isSearching,
    media,
    migrationAction,
    platformMedia,
    platforms,
    playerPerspectives,
    selectedGameToMigrate,
    themes,
    userGame,
    gameCollections,
    userGames,
    cancelImportOfBundle,
    loadGamesToMigrate,
    saveMigratedGame,
    saveBundledGame,
    searchGames,
    selectBundledGame,
    selectGameToMigrate,
    setBundledGame,
    startMigration,
    deleteGameToMigrate,
  } = useMigrate();
  const { getCoverUrl, getUserGameCoverUrl } = useApiService();

  const gamesByCreation = useMemo(() => [...userGames].sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)).slice(0, 50), [userGames]);

  useEffect(() => {
    loadGamesToMigrate();
  }, [loadGamesToMigrate]);

  if (!genres || !themes || !platforms || !gameModes || !playerPerspectives) return <></>;
  return (
    <AppFrame>
      <Root>
        <SourceListColumn>
          <GtmList gtms={gamesToMigrate} getCoverUrl={getCoverUrl} onClick={selectGameToMigrate} />
        </SourceListColumn>

        <SourceListColumn>
          {selectedGameToMigrate && (
            <GtmDetails
              coverUrl={getCoverUrl(selectedGameToMigrate)}
              selectedGtm={selectedGameToMigrate}
              onDelete={() => deleteGameToMigrate(selectedGameToMigrate)}
              onMigrate={() => searchGames(selectedGameToMigrate.title)}
            />
          )}
        </SourceListColumn>
        {(migrationAction === "Search" || migrationAction === "Edit") && selectedGameToMigrate ? (
          <SearchEditColumn
            coverUrl={getCoverUrl(selectedGameToMigrate)}
            foundGames={foundGames}
            gameCollections={gameCollections}
            gameModes={gameModes}
            genres={genres}
            media={media}
            platformMedia={platformMedia}
            platforms={platforms}
            playerPerspectives={playerPerspectives}
            searching={isSearching}
            selectedGameToMigrate={selectedGameToMigrate}
            themes={themes}
            userGame={userGame}
            onSave={saveMigratedGame}
            onSearch={searchGames}
            onSearchSelect={startMigration}
          />
        ) : (
          <div />
        )}
        <SourceListColumn>
          <Center>
            <Typography variant="h4">Last 50 Created Games</Typography>
          </Center>
          <GamesGrid>
            {gamesByCreation.map((g) => (
              <Cover key={getImageId(g)} game={g} onClick={() => {}} />
            ))}
          </GamesGrid>
        </SourceListColumn>
        {bundle && (
          <FittingDialog
            title="Bundled Games"
            actions={
              <>
                <Button autoFocus onClick={cancelImportOfBundle}>
                  Cancel
                </Button>
              </>
            }
          >
            <BundledGamesList>
              {!bundledGames && (
                <Center>
                  <CircularProgress />
                </Center>
              )}
              {bundledGames &&
                bundledGames.map((game) => (
                  <GameSearchResultItem
                    key={game.thirdPartyId}
                    game={game}
                    gameModes={gameModes}
                    genres={genres}
                    platforms={platforms}
                    themes={themes}
                    playerPerspectives={playerPerspectives}
                    onClick={() => selectBundledGame(game)}
                  />
                ))}
            </BundledGamesList>
          </FittingDialog>
        )}
        {bundledGame && (
          <FittingDialog
            key={bundledGame.game.thirdPartyId}
            title={`Add ${formatCategory(bundledGame.game.category)}`}
            onClose={() => setBundledGame(undefined)}
            titleAction={() => setBundledGame(undefined)}
          >
            <EditUserGame
              coverUrl={getUserGameCoverUrl(bundledGame._id || "new")}
              game={bundledGame}
              gameCollections={gameCollections}
              gameModes={gameModes}
              genres={genres}
              media={media}
              platformMedia={platformMedia}
              platforms={platforms}
              playerPerspectives={playerPerspectives}
              themes={themes}
              onSave={saveBundledGame}
            />
          </FittingDialog>
        )}
      </Root>
    </AppFrame>
  );
};
