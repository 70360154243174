import styled from "@emotion/styled";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, alpha } from "@mui/material";
import React from "react";
import * as Icon from "../../../lib/constants/pageIcons";
import { ROUTE_MIGRATE, ROUTE_STATISTICS } from "../../../lib/constants/routes";
import { Themed } from "../../../lib/types";
import { useNavigationServiceImpl } from "../../../services/navigation";

const StyledMenu = styled((props: MenuProps) => <Menu elevation={8} {...props} />)<Themed>(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 240,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 32,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

type Props = {
  menuId: string;
  anchorEl: null | HTMLElement;
  onAddGame: () => void;
  onClose: () => void;
  onLogout: () => void;
  onRefdata: () => void;
  onProfile: () => void;
  onRefreshData: () => void;
};

export const MainMenu: React.FC<Props> = ({ onAddGame, onClose, onRefdata, onProfile, onLogout, onRefreshData, anchorEl, menuId }) => {
  const { navigateTo } = useNavigationServiceImpl();
  return (
    <StyledMenu
      sx={{ mt: "-30px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={true}
      onClose={onClose}
    >
      <MenuItem onClick={onProfile}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        <ListItemText>Profile</ListItemText>
      </MenuItem>
      <MenuItem onClick={onRefdata}>
        <ListItemIcon>
          <EngineeringIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Refdata</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => navigateTo(ROUTE_MIGRATE)}>
        <ListItemIcon>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Migrate Games</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => navigateTo(ROUTE_STATISTICS)}>
        <ListItemIcon>
          <Icon.STATISTICS fontSize="small" />
        </ListItemIcon>
        <ListItemText>Statistics</ListItemText>
      </MenuItem>
      <MenuItem onClick={onAddGame}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Add Game</ListItemText>
      </MenuItem>
      <MenuItem onClick={onRefreshData}>
        <ListItemIcon>
          <RefreshIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Refresh Data</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={onLogout}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </StyledMenu>
  );
};
