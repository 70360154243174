import { useMemo } from "react";
import { UserGameFilterData, countGames, filterGames } from "../../domain/userGameFilter";
import { useProfileStorage, useRefdataStorage } from "../../services/storage";

export const useGames = () => {
  const { filter, userGames, gameCollections, platformMedia, updateGameFilter } = useProfileStorage();
  const filteredGames = useMemo(() => userGames.filter(filterGames(filter)), [filter, userGames]);
  const { genres, gameModes, playerPerspectives, themes } = useRefdataStorage();
  const filterData: UserGameFilterData = useMemo(
    () => ({
      filter,
      refdata: {
        collections: gameCollections,
        gameModes,
        genres,
        platformMedia,
        playerPerspectives,
        themes,
      },
      statistics: {
        gameCount: countGames(filteredGames),
      },
    }),
    [filter, filteredGames, gameCollections, gameModes, genres, platformMedia, playerPerspectives, themes]
  );

  return {
    filter: filterData,
    filteredGames,
    games: userGames,
    updateGameFilter,
  };
};
