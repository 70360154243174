import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { ApiService, DataLoadingStatisticStorage, RefdataStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

export const loadGenres = async (
  deps: Pick<ApiService, "getGenres"> &
    Pick<RefdataStorageService, "loadingGenresSuccessful" | "startLoadingGenres"> &
    Pick<DataLoadingStatisticStorage, "addRefdataLoadingStatistics" | "updateRefdataLoadingStatistic">
) => {
  const stat: DataloadingStatistic = { id: "genre", name: "Genre", status: "running" };
  try {
    deps.startLoadingGenres();
    deps.addRefdataLoadingStatistics(stat);
    const genres = await deps.getGenres();
    deps.loadingGenresSuccessful(genres ? genres.sort(sortRefdataByName) : []);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "finished" });
    return genres;
  } catch (e) {
    console.log(e);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "failed" });
    return [];
  }
};
