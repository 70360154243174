import { GameCollection, GameMode, Genre, Medium, PlayerPerspective, Theme } from "neorak-game-lib-model";
import React from "react";
import { DataListDialog } from "../shared/DataListDialog";

type Props<T> = {
  title: string;
  icon: React.ReactNode;
  values: T[];
  onClose: () => void;
  onClick: (value: T) => void;
  onAddClick?: () => void;
  getIcon?: (value: T) => React.ReactNode;
};

export const RefdataListDialog = <T extends Genre | GameMode | PlayerPerspective | Theme | Medium | GameCollection>({
  icon,
  title,
  values,
  onAddClick,
  onClick,
  onClose,
  getIcon,
}: Props<T>) => (
  <DataListDialog
    values={values}
    getId={(value) => value._id || ""}
    getLabel={(value) => value.name}
    icon={icon}
    title={title}
    onClick={onClick}
    onClose={onClose}
    onAddClick={onAddClick}
    getIcon={getIcon}
  />
);
