import styled from "@emotion/styled";
import ArticleIcon from "@mui/icons-material/Article";
import BackHandIcon from "@mui/icons-material/BackHand";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";
import SaveIcon from "@mui/icons-material/Save";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { Button, List, ListSubheader } from "@mui/material";
import moment from "moment";
import { GameCollection, GameMode, Genre, Medium, Platform, PlayerPerspective, Theme, UserGame } from "neorak-game-lib-model";
import React, { useMemo, useState } from "react";
import { updatePlaytime } from "../../../domain/changePlaytime";
import { getName } from "../../../domain/game";
import { PlatformMedia } from "../../../domain/platformMedia";
import GameOwnershipInfo from "../../../lib/constants/gameOwnership";
import GameStatusInfo from "../../../lib/constants/gameStatusInfo";
import * as Icon from "../../../lib/constants/pageIcons";
import { formatCategory } from "../../../lib/format";
import { getImageUrl } from "../../../lib/image";
import { Themed } from "../../../lib/types";
import { DeleteButton } from "../DeleteButton";
import { EditCoverDialog } from "./EditCoverDialog";
import { EditGameCollectionDialog } from "./EditGameCollectionDialog";
import { EditGameNameDialog } from "./EditGameNameDialog";
import { EditPlatformDialog } from "./EditPlatformDialog";
import { EditPlayTimeDialog } from "./EditPlayTimeDialog";
import { EditUserGameItemItem } from "./EditUserGameItemItem";
import { GameHeader } from "./GameHeader";
import { OwnershipDialog } from "./OwnershipDialog";

type PM = {
  title: string;
  media: string[];
};

type PMS = {
  [id: string]: PM;
};

const getShortestName = (p?: Platform): string | undefined => {
  if (p) {
    const { abbreviation, alternative_name, name } = p;
    return abbreviation || alternative_name || name;
  }
  return undefined;
};

const formatPlatformMedia = (game: UserGame, platforms: Platform[], media: Medium[]): string => {
  const pms: PMS =
    game.platformMedia.reduce<PMS>((acc, cur) => {
      if (!acc[cur.platformId]) {
        acc[cur.platformId] = {
          title: getShortestName(platforms.find((platform) => platform._id === cur.platformId)) || "Unknown",
          media: [media.find((medium) => medium._id === cur.mediumId)?.name || "Unknown"],
        };
      } else {
        acc[cur.platformId].media.push(media.find((medium) => medium._id === cur.mediumId)?.name || "Unknown");
      }
      return acc;
    }, {}) || {};
  return Object.keys(pms)
    .map((id) => `${pms[id].title} (${pms[id].media.join(" & ")})`)
    .sort()
    .join(", ");
};

const Root = styled.div<Themed>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

const formatRefdata = (refdata: { _id?: string; name: string }[], ids: string[]): string => {
  if (ids)
    return ids
      .map((gmid) => refdata.find((gm) => gm._id === gmid)?.name || "-")
      .sort()
      .join(", ");
  return "";
};

type Props = {
  coverUrl: string;
  game: UserGame;
  gameCollections: GameCollection[];
  gameModes: GameMode[];
  genres: Genre[];
  media: Medium[];
  platforms: Platform[];
  playerPerspectives: PlayerPerspective[];
  platformMedia: PlatformMedia[];
  themes: Theme[];
  upperBlock?: React.ReactNode;
  onSave: (userGame: UserGame, coverData?: Uint8Array) => void;
  onDelete?: (userGame: UserGame) => void;
};

type Action = "Cover" | "Platform" | "Name" | "Playtime" | "Ownership" | "Collection";

export const EditUserGame: React.FC<Props> = ({ coverUrl, game, gameCollections, genres, gameModes, media, platforms, playerPerspectives, platformMedia, themes, upperBlock, onDelete, onSave }) => {
  const [userGame, updateUserGame] = useState<UserGame>(JSON.parse(JSON.stringify(game)));
  const [action, setAction] = useState<Action | undefined>();
  const [newCoverData, setNewCoverData] = useState<Uint8Array | undefined>();
  const newCoverUrl = useMemo(() => getImageUrl(newCoverData) || coverUrl, [newCoverData, coverUrl]);
  return (
    <>
      <Root>
        <GameHeader game={userGame} coverUrl={newCoverUrl} onCoverClick={() => setAction("Cover")} onTitleClick={() => setAction("Name")} onDetailsClick={() => setAction("Playtime")} />
        {upperBlock}
        <List
          sx={{ width: "100%" }}
          subheader={
            <ListSubheader disableSticky style={{ backgroundColor: "transparent" }}>
              Details
            </ListSubheader>
          }
        >
          <EditUserGameItemItem
            icon={<Icon.GAME_STATUS />}
            onClick={() => setAction("Playtime")}
            primary="Status"
            secondary={
              <>
                {userGame.statusHistory.map((sh) => (
                  <span key={sh.id} style={{ display: "block" }}>{`${GameStatusInfo[sh.status].label}${sh.statusChangedAt ? `: ${moment(sh.statusChangedAt).format("L")}` : ""}`}</span>
                ))}
              </>
            }
          />
          <EditUserGameItemItem
            icon={<CalendarMonthIcon />}
            primary={`${formatCategory(userGame.game.category)} Release`}
            secondary={`${moment(userGame.game.firstReleaseDate).format("L")} ${userGame.game.status ? `| ${userGame.game.status}` : ""}`}
          />
          <EditUserGameItemItem icon={<ArticleIcon />} primary="Summery" secondary={userGame.game.summary} />
          {userGame.game.storyline && <EditUserGameItemItem icon={<DescriptionIcon />} primary="Storyline" secondary={userGame.game.storyline} />}
          <EditUserGameItemItem icon={<Icon.GAME_MODE />} primary="Game Mode" secondary={formatRefdata(gameModes, userGame.game.gameModes)} />
          <EditUserGameItemItem icon={<Icon.GENRE />} primary="Genre" secondary={formatRefdata(genres, userGame.game.genres)} />
          <EditUserGameItemItem icon={<Icon.THEME />} primary="Theme" secondary={formatRefdata(themes, userGame.game.themes)} />
          <EditUserGameItemItem icon={<Icon.PLAYER_PERSPECTIVE />} primary="Player Perspective" secondary={formatRefdata(playerPerspectives, userGame.game.playerPerspectives)} />
          {userGame.game.franchises.length > 0 && <EditUserGameItemItem icon={<TagFacesIcon />} primary="Franchise" secondary={userGame.game.franchises.sort().join(", ")} />}
          <EditUserGameItemItem
            icon={<Icon.PLATFORM />}
            primary="Platform"
            secondary={userGame.platformMedia && formatPlatformMedia(userGame, platforms, media)}
            onClick={() => setAction("Platform")}
          />
          <EditUserGameItemItem icon={<BackHandIcon />} primary="Ownership" secondary={GameOwnershipInfo[userGame.ownership].label} onClick={() => setAction("Ownership")} />
          <EditUserGameItemItem
            icon={<Icon.GAME_COLLECTION />}
            primary="Game Collections"
            secondary={formatRefdata(gameCollections, userGame.collections) || "-"}
            onClick={() => setAction("Collection")}
          />
        </List>
        <Button variant="contained" endIcon={<SaveIcon />} fullWidth disabled={userGame.platformMedia.length === 0} onClick={() => onSave(userGame, newCoverData)}>
          Save Game
        </Button>
        {onDelete && <DeleteButton label="Delete Game" message={`Do you really want to delete '${getName(userGame)}'?`} onClick={() => onDelete(userGame)} />}
      </Root>
      {action === "Cover" && (
        <EditCoverDialog
          coverUrl={newCoverUrl}
          game={userGame}
          onCancel={() => setAction(undefined)}
          onUpdate={(data) => {
            setNewCoverData(data);
            setAction(undefined);
          }}
        />
      )}
      {action === "Platform" && (
        <EditPlatformDialog
          platformMedia={platformMedia}
          values={userGame.platformMedia}
          onCancel={() => setAction(undefined)}
          onUpdate={(platformMedia) => {
            updateUserGame((ug) => ({ ...ug, platformMedia }));
            setAction(undefined);
          }}
        />
      )}
      {action === "Name" && (
        <EditGameNameDialog
          game={userGame}
          onCancel={() => setAction(undefined)}
          onUpdate={(values) => {
            updateUserGame({ ...userGame, customName: values.customName, sortName: values.sortName });
            setAction(undefined);
          }}
        />
      )}
      {action === "Playtime" && (
        <EditPlayTimeDialog
          game={userGame}
          platformMedia={platformMedia}
          onCancel={() => setAction(undefined)}
          onUpdate={(values) => {
            updateUserGame(updatePlaytime(userGame, values));
            setAction(undefined);
          }}
        />
      )}
      {action === "Ownership" && (
        <OwnershipDialog
          value={userGame.ownership}
          onClose={() => setAction(undefined)}
          onClick={(value) => {
            updateUserGame({ ...userGame, ownership: value });
            setAction(undefined);
          }}
        />
      )}
      {action === "Collection" && (
        <EditGameCollectionDialog
          collections={gameCollections}
          game={userGame}
          onCancel={() => setAction(undefined)}
          onUpdate={(value) => {
            updateUserGame({ ...userGame, collections: value });
            setAction(undefined);
          }}
        />
      )}
    </>
  );
};
