import { GameCollection } from "neorak-game-lib-model";
import { createNewGameCollection } from "../../domain/gameCollection";
import * as Icon from "../../lib/constants/pageIcons";
import { RefdataListDialog } from "../Refdata/RefdataListDialog";
import { GameCollectionForm } from "./GameCollectionForm";

type Props = {
  edit: boolean;
  collections: GameCollection[];
  selected?: GameCollection;
  onClose: () => void;
  onCancel: () => void;
  onSave: (value: GameCollection) => void;
  onCreate: (value: GameCollection) => void;
  onDelete: (value: GameCollection) => void;
  onSelect: (value: GameCollection) => void;
};

export const GameCollectionDialog: React.FC<Props> = ({
  edit,
  collections,
  selected,
  onSelect,
  onClose,
  onCancel,
  onCreate,
  onSave,
  onDelete,
}) => (
  <>
    <RefdataListDialog
      title="Game Collections"
      icon={<Icon.GAME_COLLECTION />}
      values={collections}
      onClose={onClose}
      onClick={(m) => {
        onSelect(m);
      }}
      onAddClick={() => {
        onCreate(createNewGameCollection());
      }}
    />
    {selected !== undefined && (
      <GameCollectionForm edit={edit} collection={selected} onSubmit={onSave} onCancel={onCancel} onDelete={onDelete} />
    )}
  </>
);
