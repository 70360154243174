import styled from "@emotion/styled";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import { GameCollection } from "neorak-game-lib-model";
import React from "react";
import { Field, Form } from "react-final-form";
import { validateGameCollection } from "../../domain/gameCollection";
import { Themed } from "../../lib/types";
import { FittingDialog } from "../shared/FittingDialog";
import TextInput from "../shared/TextInput";

const FormBody = styled.form<Themed>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
`;

type Props = {
  collection: GameCollection;
  edit: boolean;
  onSubmit: (value: GameCollection) => void | Promise<void>;
  onDelete: (value: GameCollection) => void | Promise<void>;
  onCancel: () => void;
};

export const GameCollectionForm: React.FC<Props> = ({ collection, edit, onSubmit, onCancel, onDelete }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={collection}
      validate={validateGameCollection}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <FittingDialog
          title={`${edit ? "Edit" : "Create"} Medium`}
          maxWidth="xs"
          titleAction={edit ? () => onDelete(collection) : undefined}
          titleActionIcon={<DeleteForeverIcon />}
          actions={
            <>
              <Button autoFocus onClick={onCancel}>
                Cancel
              </Button>
              <Button onClick={handleSubmit} autoFocus disabled={submitting || !valid || pristine}>
                Save
              </Button>
            </>
          }
        >
          <FormBody onSubmit={handleSubmit}>
            <Field<string> name="name" component={TextInput} label="Name" />
          </FormBody>
        </FittingDialog>
      )}
    />
  );
};
