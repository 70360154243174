import { TextField } from "@mui/material";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<string, any>;

const TextInput: React.FC<Props> = ({ input, meta, ...rest }) => (
  <TextField
    type="text"
    variant="standard"
    {...input}
    {...rest}
    error={meta.error && meta.touched}
    helperText={meta.error && meta.touched ? meta.error : ""}
  />
);

export default TextInput;
