import Dexie from "dexie";
import { DBImage } from "../../domain/dbImage";

export const TABLE = {
  IMAGE: "image",
};

const imageDB = new Dexie("neorak-game-images");

// much faster than clearing the image table
export const resetImageDatabase = () =>
  imageDB
    .delete()
    .then(() => imageDB.open())
    .catch(alert);

imageDB
  .version(1)
  .stores({ [`${TABLE.IMAGE}`]: "&id" })
  .upgrade(resetImageDatabase);

export const updateImages = (images: DBImage[]) => imageDB.table<DBImage>(TABLE.IMAGE).bulkPut(images);

export const getImage = (id: string) => imageDB.table<DBImage>(TABLE.IMAGE).where({ id }).first();
export const getImages = (ids: string[]) => imageDB.table<DBImage>(TABLE.IMAGE).where("id").anyOf(ids).toArray();

export const countImages = () => imageDB.table<DBImage>(TABLE.IMAGE).count();

export const getImageIds = async () => (await imageDB.table<DBImage>(TABLE.IMAGE).toCollection().primaryKeys()).map((id: any) => id as string);
export const validateImageIds = async (ids: string[]) => (await imageDB.table<DBImage>(TABLE.IMAGE).where("id").anyOf(ids).primaryKeys()).map((id: any) => id as string);

export const deleteImages = (ids: string[]) => imageDB.table<DBImage>(TABLE.IMAGE).bulkDelete(ids);

export const purgeImages = (days: number) =>
  imageDB.transaction("rw", imageDB.table<DBImage>(TABLE.IMAGE), async () => {
    const purgeTS = Date.now() - days * 24 * 60 * 60 * 1000;
    const imageIdsToPurge = (
      await imageDB
        .table<DBImage>(TABLE.IMAGE)
        .filter((item) => item.updated < purgeTS)
        .primaryKeys()
    ).map((id: any) => id as string);
    // console.debug(imageIdsToPurge);
    await deleteImages(imageIdsToPurge);
    return imageIdsToPurge;
  });
