import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import { Medium } from "neorak-game-lib-model";
import React from "react";
import { Field, Form } from "react-final-form";
import { validateMedium } from "../../domain/medium";
import { FittingDialog } from "../shared/FittingDialog";
import TextInput from "../shared/TextInput";

type Props = {
  medium: Medium;
  edit: boolean;
  onSubmit: (value: Medium) => void | Promise<void>;
  onDelete: (value: Medium) => void | Promise<void>;
  onCancel: () => void;
};

export const MediumForm: React.FC<Props> = ({ medium, edit, onSubmit, onCancel, onDelete }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={medium}
      validate={validateMedium}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <FittingDialog
          title={`${edit ? "Edit" : "Create"} Medium`}
          maxWidth="xs"
          titleAction={edit ? () => onDelete(medium) : undefined}
          titleActionIcon={<DeleteForeverIcon />}
          onSubmit={handleSubmit}
          actions={
            <>
              <Button autoFocus onClick={onCancel}>
                Cancel
              </Button>
              <Button onClick={handleSubmit} autoFocus disabled={submitting || !valid || pristine}>
                Save
              </Button>
            </>
          }
        >
          <Field<string> name="name" component={TextInput} label="Name" />
        </FittingDialog>
      )}
    />
  );
};
