import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Genre } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type GenreState = {
  genres: Genre[];
  selectedGenre?: Genre;
  isLoadingGenre: boolean;
};

const initialState: GenreState = {
  isLoadingGenre: false,
  genres: [],
};

const genreSlice = createSlice({
  name: "genre",
  initialState,
  reducers: {
    starLoadingGenres: (state: GenreState) => {
      state.isLoadingGenre = true;
    },
    loadingGenresSuccessful: (state: GenreState, action: PayloadAction<Genre[]>) => {
      state.isLoadingGenre = false;
      state.genres = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const genreSelector = (state: AppRootState): GenreState => state.refdata.genre;

export const { loadingGenresSuccessful, starLoadingGenres, reset } = genreSlice.actions;

export default genreSlice.reducer;
