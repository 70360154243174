import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useAddGame } from "../../application/addGame/useAddGame";
import { GameSearch } from "../Migrate/GameSearch";
import { EditUserGame } from "../shared/EditUserGame/EditUserGame";
import { FittingDialog } from "../shared/FittingDialog";
import { AddGameStepper } from "./AddGameStepper";
import { GameSearchResultItem } from "./GameSearchResultItem";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BundledGamesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type Props = {
  onClose: () => void;
};

export const AddGameDialog: React.FC<Props> = ({ onClose }) => {
  const {
    addAction,
    bundledGame,
    bundledGames,
    foundGames,
    gameCollections,
    gameModes,
    genres,
    media,
    isSearching,
    platformMedia,
    platforms,
    playerPerspectives,
    themes,
    userGame,
    editNewGame,
    getUserGameCoverUrl,
    saveBundledGame,
    saveNewGame,
    searchGames,
    selectBundledGame,
  } = useAddGame(onClose);
  const title = useMemo(() => {
    switch (addAction) {
      case "ShowBundledGames":
        return "Bundled Games";
      case "Edit":
      case "EditBundledGame":
        return `Add Game '${bundledGame?.game.name || userGame?.game.name || "unkonwn"}'`;
      default:
        return "Search Game";
    }
  }, [bundledGame, userGame, addAction]);
  const step = useMemo(() => {
    switch (addAction) {
      case "Edit":
        return 1;
      case "ShowBundledGames":
        return 2;
      case "EditBundledGame":
        return 3;
      default:
        return 0;
    }
  }, [addAction]);
  return (
    <FittingDialog title={title} onClose={onClose} titleAction={onClose}>
      <AddGameStepper step={step} />
      {addAction === "Search" && (
        <GameSearch
          gameModes={gameModes}
          gameTitle={""}
          games={foundGames}
          genres={genres}
          platforms={platforms}
          playerPerspectives={playerPerspectives}
          searching={isSearching}
          themes={themes}
          onClick={editNewGame}
          onSearch={searchGames}
        />
      )}
      {addAction === "Edit" && userGame && (
        <EditUserGame
          coverUrl={getUserGameCoverUrl(userGame._id || "new")}
          game={userGame}
          gameCollections={gameCollections}
          gameModes={gameModes}
          genres={genres}
          media={media}
          platformMedia={platformMedia}
          platforms={platforms}
          playerPerspectives={playerPerspectives}
          themes={themes}
          onSave={saveNewGame}
        />
      )}
      {addAction === "ShowBundledGames" && (
        <BundledGamesList>
          {!bundledGames && (
            <Center>
              <CircularProgress />
            </Center>
          )}
          {bundledGames &&
            bundledGames.map((game) => (
              <GameSearchResultItem
                key={game.thirdPartyId}
                game={game}
                gameModes={gameModes}
                genres={genres}
                platforms={platforms}
                themes={themes}
                playerPerspectives={playerPerspectives}
                onClick={() => selectBundledGame(game)}
              />
            ))}
        </BundledGamesList>
      )}
      {addAction === "EditBundledGame" && bundledGame && (
        <EditUserGame
          coverUrl={getUserGameCoverUrl(bundledGame._id || "new")}
          game={bundledGame}
          gameCollections={gameCollections}
          gameModes={gameModes}
          genres={genres}
          media={media}
          platformMedia={platformMedia}
          platforms={platforms}
          playerPerspectives={playerPerspectives}
          themes={themes}
          onSave={saveBundledGame}
        />
      )}
    </FittingDialog>
  );
};
