import { PlatformMedia } from "../../domain/platformMedia";
import { ApiService, ProfileStorageService } from "../../domain/ports";

type DeletePlatformMediaActionDependencies = Pick<
  ProfileStorageService,
  "endMaintainPlatformMedia" | "startLoadingPlatformMedia"
> &
  Pick<ApiService, "deletePlatformMedium" | "deleteFavoritePlatform">;

export const deletePlatformMediaAction = async (pm: PlatformMedia, deps: DeletePlatformMediaActionDependencies) => {
  deps.startLoadingPlatformMedia();
  const deleted = pm.platformMedia;
  await Promise.all(deleted.map((entity) => deps.deletePlatformMedium(entity)));
  if (pm.favoritePlatform) await deps.deleteFavoritePlatform(pm.favoritePlatform);
  deps.endMaintainPlatformMedia();
};
