import styled from "@emotion/styled";
import { Send } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { LoginCredentials, validateLoginCredentials } from "../../domain/loginCredentials";
import { Themed } from "../../lib/types";
import GamesLogo from "../assets/games-logo-update.png";
import PasswordInput from "../shared/PasswordInput";
import TextInput from "../shared/TextInput";

const Logo = styled.img<Themed>`
  max-width: 60%;
  align-self: center;
`;

const FormBody = styled.form<Themed>`
  padding: ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
`;

type Props = {
  onSubmit: (value: LoginCredentials) => void | Promise<void>;
};

export const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ mail: "", password: "" } as LoginCredentials}
      validate={validateLoginCredentials}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <FormBody onSubmit={handleSubmit}>
          <Logo src={GamesLogo} />
          <Field<string> name="mail" component={TextInput} label="Email" />
          <Field<string> name="password" component={PasswordInput} label="Password" />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            endIcon={<Send />}
            disabled={submitting || pristine || !valid}
          >
            Sign In
          </Button>
        </FormBody>
      )}
    />
  );
};
