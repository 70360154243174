import styled from "@emotion/styled";
import { useGames } from "../../application/game/useGames";
import { useHome } from "../../application/home/useHome";
import { getImageId } from "../../domain/dbImage";
import { formatCategory } from "../../lib/format";
import { Themed } from "../../lib/types";
import { useProfileStorage, useRefdataStorage } from "../../services/storage";
import { AppFrame } from "../shared/AppFrame";
import { IMAGE_MIN_WIDTH } from "../shared/CoverImage";
import { EditUserGame } from "../shared/EditUserGame/EditUserGame";
import { FittingDialog } from "../shared/FittingDialog";
import { Cover } from "./Cover";
import { RelatedGames } from "./ReleatedGames";

const Root = styled.div<Themed & { maxWidth?: string }>`
  align-items: baseline;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${IMAGE_MIN_WIDTH}, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

type Props = {};

export const HomeView: React.FC<Props> = () => {
  const { filter, filteredGames, games, updateGameFilter } = useGames();
  const { platformMedia, gameCollections } = useProfileStorage();
  const { genres, platforms, gameModes, playerPerspectives, themes, media } = useRefdataStorage();
  const { selected, relatedGames, coverUrl, deleteGame, selectGame, saveGame } = useHome();

  return (
    <AppFrame filter={filter} onFilterChange={updateGameFilter}>
      <Root>
        {filteredGames.map((g) => (
          <Cover key={getImageId(g)} game={g} onClick={() => selectGame(g, games)} />
        ))}
      </Root>
      {selected && coverUrl && (
        <FittingDialog
          key={selected.game.thirdPartyId}
          maxWidth="md"
          title={`Selected ${formatCategory(selected.game.category)}`}
          onClose={() => selectGame(undefined)}
          titleAction={() => selectGame(undefined)}
        >
          <EditUserGame
            coverUrl={coverUrl}
            game={selected}
            gameCollections={gameCollections}
            gameModes={gameModes}
            genres={genres}
            media={media}
            platformMedia={platformMedia}
            platforms={platforms}
            playerPerspectives={playerPerspectives}
            themes={themes}
            onSave={saveGame}
            onDelete={deleteGame}
            upperBlock={<RelatedGames relatedGames={relatedGames} onClick={(g) => selectGame(g, games)} />}
          />
        </FittingDialog>
      )}
    </AppFrame>
  );
};
