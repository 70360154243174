import { FormValidation, ValidationError } from "../lib/types";

export type LoginCredentials = {
  mail: string;
  password: string;
};

const VALID_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

export const validateLoginCredentials: FormValidation<LoginCredentials> = (values: LoginCredentials) => {
  const errors: ValidationError<LoginCredentials> = {};
  if (!values.mail) {
    errors.mail = "Required";
  } else if (!values.mail.match(VALID_EMAIL_REGEX)) {
    errors.mail = "Wrong mail format";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Too short. Password should have at least 8 chars.";
  }
  return errors;
};
