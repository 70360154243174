import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LoadStatistic } from "neorak-game-lib-model";
import React from "react";

const formatValue = (num?: number) => num || "-";

type Props = {
  thirdPartyStatistics: LoadStatistic[];
  onClose: () => void;
};

export const ThirdPartyStatisticsDialog: React.FC<Props> = ({ thirdPartyStatistics, onClose }) => (
  <Dialog fullWidth maxWidth="sm" open={true}>
    <DialogTitle>Reloaded Third-Party Refdata</DialogTitle>
    <DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell align="right">New</TableCell>
            <TableCell align="right">Updated</TableCell>
            <TableCell align="right">Deleted</TableCell>
            <TableCell align="right">Loaded</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {thirdPartyStatistics.map((stat) => (
            <TableRow key={stat.entity} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {stat.entity}
              </TableCell>
              <TableCell align="right">{formatValue(stat.created)}</TableCell>
              <TableCell align="right">{formatValue(stat.updated)}</TableCell>
              <TableCell align="right">{formatValue(stat.deleted)}</TableCell>
              <TableCell align="right">{formatValue(stat.loaded)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);
