import { TextField } from "@mui/material";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<string, any>;

const PasswordInput: React.FC<Props> = ({ input, meta, ...rest }) => (
  <TextField
    type="password"
    variant="standard"
    autoComplete="current-password"
    {...input}
    {...rest}
    error={meta.error && meta.touched}
    helperText={meta.error && meta.touched ? meta.error : ""}
  />
);

export default PasswordInput;
