import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, IconButton, List, ListSubheader, Paper, Typography } from "@mui/material";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-calculate";
import moment from "moment";
import { GameStatusHistory, UserGame, UserGameStatus } from "neorak-game-lib-model";
import React from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { ChangePlaytime, toChangePlaytime } from "../../../domain/changePlaytime";
import { PlatformMedia } from "../../../domain/platformMedia";
import { createStatusHistory } from "../../../domain/userGame";
import GameStatusInfo from "../../../lib/constants/gameStatusInfo";
import { Themed } from "../../../lib/types";
import { DataListItem } from "../DataListItem";
import DateInput from "../DateInput";
import { FittingDialog } from "../FittingDialog";
import NumberInput from "../NumberInput";

const calculator = createDecorator({
  field: /([\d\S]*\.)?(playtime|statusHistory)/, // when a field matching this pattern changes...
  updates: {
    // ...update the calcPlaytime to the result of this function
    calcPlaytime: (_, allValues) => {
      const pt = allValues as ChangePlaytime;
      return (pt?.statusHistory || []).reduce((sum, sh) => sum + Number(sh.playtime || 0), Number(pt.playtime || 0));
    },
  },
});

const FormBody = styled.div<Themed>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`;

const IconCell = styled(Grid)`
  display: flex;
  justify-content: start;
  align-items: center;
`;

type Props = {
  game: UserGame;
  platformMedia: PlatformMedia[];
  onCancel: () => void;
  onUpdate: (values: any) => void;
};

export const EditPlayTimeDialog: React.FC<Props> = ({ game, platformMedia, onCancel, onUpdate }) => {
  const FinishedIcon = GameStatusInfo.FINISHED.icon;
  const WillNotFinishIcon = GameStatusInfo.WILL_NOT_FINISH.icon;
  return (
    <Form
      onSubmit={onUpdate}
      decorators={[calculator]}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={toChangePlaytime(game)}
      render={({
        handleSubmit,
        submitting,
        pristine,
        valid,
        form: {
          mutators: { unshift },
        },
        values,
      }) => {
        return (
          <FittingDialog
            title="Edit Playtime & History"
            maxWidth="sm"
            onSubmit={handleSubmit}
            actions={
              <>
                <Button autoFocus onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit" autoFocus disabled={submitting || pristine || !valid}>
                  Save
                </Button>
              </>
            }
          >
            <FormBody>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field<number> name="calcPlaytime" component={NumberInput} label="Calculated Overall Playtime" disabled />
                </Grid>
                <Grid item xs={12}>
                  <Field<number> name="playtime" component={NumberInput} label="Playtime" />
                </Grid>
                <List sx={{ pt: 0 }} subheader={<ListSubheader style={{ backgroundColor: "transparent" }}>Add Status</ListSubheader>} style={{ width: "100%" }}>
                  <DataListItem key="finish" icon={<FinishedIcon />} label={GameStatusInfo.FINISHED.label} onClick={() => unshift("statusHistory", createStatusHistory(UserGameStatus.FINISHED))} />
                  <DataListItem
                    key="wnf"
                    icon={<WillNotFinishIcon />}
                    label={GameStatusInfo.WILL_NOT_FINISH.label}
                    onClick={() => unshift("statusHistory", createStatusHistory(UserGameStatus.WILL_NOT_FINISH))}
                  />
                </List>
                <FieldArray<GameStatusHistory> name="statusHistory">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const history: GameStatusHistory = fields.value[index];
                      if (!history) return <></>;
                      const StatusIcon = GameStatusInfo[history.status].icon;
                      return (
                        <Grid item xs={12} key={history.id}>
                          <Paper elevation={10} sx={{ padding: "1rem" }}>
                            <Grid container spacing={2}>
                              <IconCell item xs={12}>
                                <StatusIcon />
                                <Typography variant="button" sx={{ flex: 1 }}>
                                  {GameStatusInfo[history.status].label}
                                  {history.statusChangedAt && `: ${moment(history.statusChangedAt).format("L")}`}
                                </Typography>
                                <IconButton aria-label="delete" onClick={() => fields.remove(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </IconCell>
                              <Grid item xs={6}>
                                <Field<number> name={`${name}.playtime`} component={NumberInput} label="Playtime" />
                              </Grid>
                              <Grid item xs={6}>
                                <Field<moment.Moment> name={`${name}.statusChangedAt`} component={DateInput} label="Finished At" />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })
                  }
                </FieldArray>
              </Grid>
            </FormBody>
          </FittingDialog>
        );
      }}
    />
  );
};
