import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type ThemeState = {
  themes: Theme[];
  selectedTheme?: Theme;
  isLoadingTheme: boolean;
};

const initialState: ThemeState = {
  isLoadingTheme: false,
  themes: [],
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    starLoadingThemes: (state: ThemeState) => {
      state.isLoadingTheme = true;
    },
    loadingThemesSuccessful: (state: ThemeState, action: PayloadAction<Theme[]>) => {
      state.isLoadingTheme = false;
      state.themes = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const themeSelector = (state: AppRootState): ThemeState => state.refdata.theme;

export const { loadingThemesSuccessful, starLoadingThemes, reset } = themeSlice.actions;

export default themeSlice.reducer;
