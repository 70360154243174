import { LoginCredentials } from "../../domain/loginCredentials";
import { postJson } from "../../lib/http";
import { AuthToken } from "../../lib/types";

export const login = async (credentials: LoginCredentials): Promise<AuthToken> => {
  const result = await postJson("/auth", credentials, {});
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to authenticate");
};
