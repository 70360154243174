import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadStatistic } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type ThirdPartyRefdataStatisticState = {
  statistics: LoadStatistic[];
  isLoadingThirdParty: boolean;
};

const initialState: ThirdPartyRefdataStatisticState = {
  isLoadingThirdParty: false,
  statistics: [],
};

const ThirdPartyRefdataStatisticSlice = createSlice({
  name: "thirdPartyRefdataStatistic",
  initialState,
  reducers: {
    loadingThirdPartyStatistics: (state: ThirdPartyRefdataStatisticState) => {
      state.isLoadingThirdParty = true;
    },
    loadingThirdPartyStatisticsSuccessful: (
      state: ThirdPartyRefdataStatisticState,
      action: PayloadAction<LoadStatistic[]>
    ) => {
      state.isLoadingThirdParty = false;
      state.statistics = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const thirdPartyRefdataStatisticSelector = (state: AppRootState): ThirdPartyRefdataStatisticState =>
  state.refdata.thirdPartyRefdataStatistic;

export const { loadingThirdPartyStatisticsSuccessful, loadingThirdPartyStatistics, reset } =
  ThirdPartyRefdataStatisticSlice.actions;

export default ThirdPartyRefdataStatisticSlice.reducer;
