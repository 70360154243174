import styled from "@emotion/styled";
import SendIcon from "@mui/icons-material/Send";
import { Button, List } from "@mui/material";
import { GameToMigrate } from "neorak-game-lib-model";
import React from "react";
import { LegacyGameStatusInfo } from "../../lib/constants/gameStatusInfo";
import CoverImage from "../shared/CoverImage";
import { DeleteButton } from "../shared/DeleteButton";
import { GtmDetailsItem } from "./GtmDetailsItem";

const Title = styled.h1`
  text-align: center;
`;

type Props = {
  selectedGtm: GameToMigrate;
  coverUrl: string;
  onDelete: () => void;
  onMigrate: () => void;
};

export const GtmDetails: React.FC<Props> = ({ selectedGtm, coverUrl, onDelete, onMigrate }) => {
  return (
    <>
      <Title>{selectedGtm.title}</Title>
      <CoverImage alt={selectedGtm.title} src={coverUrl} />
      <List sx={{ width: "100%" }}>
        <GtmDetailsItem letter="S" primary="Status" secondary={LegacyGameStatusInfo[selectedGtm.status].label} />
        <GtmDetailsItem letter="R" primary="Release" secondary={selectedGtm.releaseYear} />
        <GtmDetailsItem letter="T" primary="Playtime" secondary={selectedGtm.playtime} />
        <GtmDetailsItem letter="ST" primary="Sorttitle" secondary={selectedGtm.sortTitle} />
        <GtmDetailsItem
          letter="P"
          primary="Platform/Media"
          secondary={Object.keys(selectedGtm.platformMedia)
            .map((platform) => `${platform} (${selectedGtm.platformMedia[platform].join(" & ")})`)
            .sort()
            .join(", ")}
        />
        <GtmDetailsItem letter="G" primary="Genres" secondary={selectedGtm.genres.join(", ")} />
      </List>
      <Button variant="contained" endIcon={<SendIcon />} onClick={onMigrate} fullWidth>
        Search Source Game
      </Button>
      <DeleteButton label="Delete Game" message={`Do you really want to delete '${selectedGtm.title}'?`} onClick={() => onDelete()} />
    </>
  );
};
