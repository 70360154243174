import { useCallback } from "react";
import { DBImage } from "../../domain/dbImage";
import { ImageStorageService } from "../../domain/ports";
import { countImages, deleteImages, getImage, getImageIds, getImages, updateImages, validateImageIds } from "./imageDB";

export const useImageStorage = (): ImageStorageService => {
  return {
    countImages: useCallback(() => countImages(), []),
    deleteImages: useCallback((ids: string[]) => deleteImages(ids), []),
    getImage: useCallback((id: string) => getImage(id), []),
    getImageIds: useCallback(() => getImageIds(), []),
    getImages: useCallback((ids: string[]) => getImages(ids), []),
    updateImages: useCallback((imgs: DBImage[]) => updateImages(imgs), []),
    validateImageIds: useCallback((ids: string[]) => validateImageIds(ids), []),
  };
};
