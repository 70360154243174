import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Medium } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type MediumState = {
  media: Medium[];
  selectedMedium?: Medium;
  isLoadingMedium: boolean;
};

const initialState: MediumState = {
  isLoadingMedium: false,
  media: [],
};

const mediumSlice = createSlice({
  name: "medium",
  initialState,
  reducers: {
    starLoadingMedia: (state: MediumState) => {
      state.isLoadingMedium = true;
    },
    loadingMediaSuccessful: (state: MediumState, action: PayloadAction<Medium[]>) => {
      state.isLoadingMedium = false;
      state.media = action.payload;
    },
    selectMedium: (state: MediumState, action: PayloadAction<Medium | undefined>) => {
      state.selectedMedium = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const mediumSelector = (state: AppRootState): MediumState => state.refdata.medium;

export const { loadingMediaSuccessful, starLoadingMedia, selectMedium, reset } = mediumSlice.actions;

export default mediumSlice.reducer;
