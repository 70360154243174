import { Medium } from "neorak-game-lib-model";
import { useState } from "react";
import { createNewMedium } from "../../domain/medium";
import * as Icon from "../../lib/constants/pageIcons";
import { MediumForm } from "./MediumForm";
import { RefdataListDialog } from "./RefdataListDialog";

type Props = {
  media: Medium[];
  selected?: Medium;
  onClose: () => void;
  onUpdate: (medium: Medium) => void;
  onCreate: (medium: Medium) => void;
  onDelete: (medium: Medium) => void;
  onSelect: (medium: Medium | undefined) => void;
};

export const MediaDialog: React.FC<Props> = ({ media, selected, onSelect, onClose, onCreate, onUpdate, onDelete }) => {
  const [edit, setEdit] = useState<boolean>(false);
  return (
    <>
      <RefdataListDialog
        title="Media"
        icon={<Icon.MEDIUM />}
        values={media}
        onClose={onClose}
        onClick={(m) => {
          onSelect(m);
          setEdit(true);
        }}
        onAddClick={() => {
          onSelect(createNewMedium());
          setEdit(false);
        }}
      />
      {selected !== undefined && (
        <MediumForm
          edit={edit}
          medium={selected}
          onSubmit={(m) => (edit ? onUpdate(m) : onCreate(m))}
          onCancel={() => onSelect(undefined)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};
