import { GameCategory } from "neorak-game-lib-model";

export const formatCategory = (category: GameCategory): string => {
  switch (category) {
    case GameCategory.bundle:
      return "Bundle";
    case GameCategory.dlc_addon:
      return "DLC";
    case GameCategory.episode:
      return "Episode";
    case GameCategory.expanded_game:
      return "Expanded Game";
    case GameCategory.expansion:
      return "Expansion";
    case GameCategory.fork:
      return "Fork";
    case GameCategory.main_game:
      return "Game";
    case GameCategory.mod:
      return "Mod";
    case GameCategory.pack:
      return "Pack";
    case GameCategory.port:
      return "Port";
    case GameCategory.remake:
      return "Remake";
    case GameCategory.remaster:
      return "Remaster";
    case GameCategory.season:
      return "Season";
    case GameCategory.standalone_expansion:
      return "Standalone Expansion";
    case GameCategory.update:
      return "Update";
  }
};
