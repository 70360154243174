import React, { useCallback } from "react";

type ImageDim = {
  width: number;
  height: number;
};

type DataUrl = string | ArrayBuffer | null;

export const readAsDataURL = (file: File): Promise<DataUrl> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
    reader.onload = () => resolve(reader.result);
  });
};

export class ImageCompressor {
  private maxWidth: number;
  private maxHeight: number;
  private quality: number;

  constructor(width: number, height: number, quality: number) {
    this.maxWidth = width;
    this.maxHeight = height;
    this.quality = quality;
  }

  private calculateImageDim = (img: HTMLImageElement): ImageDim => {
    const result: ImageDim = {
      width: img.width,
      height: img.height,
    };

    if (result.height > result.width) {
      const scaleFactor = this.maxHeight / result.height;
      result.height = this.maxHeight;
      result.width = result.width * scaleFactor;
    }

    if (result.height <= result.width || result.width > this.maxWidth) {
      const scaleFactor = this.maxWidth / result.width;
      result.width = this.maxWidth;
      result.height = result.height * scaleFactor;
    }

    return result;
  };

  private toCanvas = (img: HTMLImageElement) => {
    const canvas = document.createElement("canvas");
    const dim = this.calculateImageDim(img);
    canvas.width = dim.width;
    canvas.height = dim.height;
    canvas.getContext("2d")?.drawImage(img, 0, 0, dim.width, dim.height);
    return canvas;
  };

  private toJpeg = async (canvas: HTMLCanvasElement) =>
    new Promise<Uint8Array>((resolve, reject) =>
      canvas.toBlob(
        async (blob) => (blob && blob.size > 0 ? resolve(new Uint8Array(await blob.arrayBuffer())) : reject()),
        "image/jpeg",
        this.quality
      )
    );

  private compressImage = (dataImageUrl: string): Promise<Uint8Array> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = dataImageUrl;
      img.onload = async () => {
        const canvas = this.toCanvas(img);
        try {
          const foo = await this.toJpeg(canvas);
          resolve(foo);
        } catch {
          reject(new Error("Failed to convert canvas into a blob."));
        }
      };
    });
  };

  compress = async (e: React.ChangeEvent<HTMLInputElement>): Promise<Uint8Array> => {
    if (e.target.files) {
      const file = e.target.files[0];
      // const fileName = file.name;
      const dataUrl = await readAsDataURL(file);
      if (!dataUrl) {
        throw new Error("Failed to read file.");
      } else {
        return await this.compressImage(dataUrl.toString());
      }
    } else {
      throw new Error("Event does not contain any files.");
    }
  };
}

export const useInputFileImageCompressor = (onChange: (value: Uint8Array) => void) => {
  const compress = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        try {
          const gameCoverCompressor = new ImageCompressor(350, 450, 0.8);
          const file = await gameCoverCompressor.compress(e);
          onChange(file);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [onChange]
  );
  return compress;
};
