import { UserGame } from "neorak-game-lib-model";
import { getImageId } from "../domain/dbImage";
import { ApiService, ImageStorageService, ProfileStorageService } from "../domain/ports";
import { IGDB_IMAGE_TYPE, bufferToBase64 } from "../lib/image";

export type CreateUserGameDependencies = Pick<ApiService, "createUserGame"> & Pick<ProfileStorageService, "addUserGame"> & Pick<ImageStorageService, "updateImages">;

export const createUserGame = async (userGame: UserGame, coverData: Uint8Array | undefined, deps: CreateUserGameDependencies) => {
  const createdUserGame = await deps.createUserGame({
    game: userGame,
    cover: coverData
      ? {
          data: await bufferToBase64(coverData),
          contentType: IGDB_IMAGE_TYPE,
        }
      : undefined,
  });
  deps.addUserGame(createdUserGame);
  if (coverData) {
    await deps.updateImages([
      {
        id: getImageId(createdUserGame),
        updated: createdUserGame.updatedAt!,
        data: coverData,
      },
    ]);
  }
};
