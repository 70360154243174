import { Medium, Platform } from "neorak-game-lib-model";
import { useCallback } from "react";
import { PlatformMedia } from "../../domain/platformMedia";
import { useApiService } from "../../services/api";
import { useProfileStorage, useRefdataStorage } from "../../services/storage";
import { createPlatformMediaAction } from "./createPlatformMediaAction";
import { deletePlatformMediaAction } from "./deletePlatformMediaAction";
import { editPlatformMediaAction } from "./editPlatformMediaAction";
import { endMaintainPlatformMediaAction } from "./endMaintainPlatformMediaAction";
import { loadPlatformMediaAction } from "./loadPlatformMediaAction";
import { savePlatformMediaAction } from "./savePlatformMediaAction";
import { selectMediumAction } from "./selectMediumAction";
import { toggleFavortiePlatformAction } from "./toggleFavortiePlatformAction";

export const usePlatformMedia = () => {
  const apiService = useApiService();
  const refdataStorage = useRefdataStorage();
  const profileStorage = useProfileStorage();
  return {
    action: profileStorage.maintainPlatformMediaAction,
    isLoading: profileStorage.isLoadingPlatformMedia,
    platformMedia: profileStorage.platformMedia,
    selected: profileStorage.selectedPlatformMedia,
    media: refdataStorage.media,
    platforms: refdataStorage.platforms.filter(
      (p) => profileStorage.platformMedia.find((pm) => pm.platform._id === p._id) === undefined
    ),
    create: useCallback(
      (platform: Platform) => createPlatformMediaAction(platform, { ...profileStorage }),
      [profileStorage]
    ),
    edit: useCallback((pm: PlatformMedia) => editPlatformMediaAction(pm, { ...profileStorage }), [profileStorage]),
    endMaintain: useCallback(() => endMaintainPlatformMediaAction({ ...profileStorage }), [profileStorage]),
    load: useCallback(
      () => loadPlatformMediaAction({ ...apiService, ...profileStorage, ...refdataStorage }),
      [apiService, profileStorage, refdataStorage]
    ),
    save: useCallback(
      (pm: PlatformMedia) => savePlatformMediaAction(pm, { ...apiService, ...profileStorage }),
      [apiService, profileStorage]
    ),
    deletePlatform: useCallback(
      (pm: PlatformMedia) => deletePlatformMediaAction(pm, { ...apiService, ...profileStorage }),
      [apiService, profileStorage]
    ),
    selectMedium: useCallback((m: Medium) => selectMediumAction(m, { ...profileStorage }), [profileStorage]),
    toggleFavortiePlatform: useCallback(() => toggleFavortiePlatformAction({ ...profileStorage }), [profileStorage]),
  };
};
