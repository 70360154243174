import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dataLoadingStatisticReducer from "./dataLoadingStatistic";
import loginReducer from "./loginReducer";
import { profileReducer } from "./profileReducer";
import { refdatatReducer } from "./refdataReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  refdata: refdatatReducer,
  dataLoadingStatistic: dataLoadingStatisticReducer,
  profile: profileReducer,
});

const persistConfig = {
  key: "game-lib",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//https://stackoverflow.com/a/68509710
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppRootState = ReturnType<typeof rootReducer>;
