import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React from "react";
import { Themed } from "../../../lib/types";

const SearchRoot = styled("div")<Themed>(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  flex: 1,
}));

const SearchIconWrapper = styled("div")<Themed>(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)<Themed>(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

export const Search: React.FC<Props> = ({ value, onChange }) => {
  return (
    <SearchRoot>
      <SearchIconWrapper>
        <SearchIcon color={!onChange ? "disabled" : undefined} />
      </SearchIconWrapper>
      <StyledInputBase value={value || ""} onChange={(e) => onChange && onChange(e.target.value || "")} disabled={!onChange} placeholder="Search…" inputProps={{ "aria-label": "search" }} fullWidth />
    </SearchRoot>
  );
};
