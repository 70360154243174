import styled from "@emotion/styled";
import { UserGame } from "neorak-game-lib-model";
import * as React from "react";
import { YearStatistics } from "../../domain/userGame";
import GameStatusInfo from "../../lib/constants/gameStatusInfo";
import Header from "./Header";
import PerYearStatisticsItem from "./PerYearStatisticsItem";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
`;

type PerYearStatisticsProps = {
  statistics: YearStatistics;
  onClick: (game: UserGame) => void;
};

const PerYearStatistics: React.FC<PerYearStatisticsProps> = ({ statistics, onClick }) => {
  const ICON = GameStatusInfo.FINISHED.icon;
  return (
    <div>
      <Center>
        <Header icon={<ICON />} label="Finished Games per year" />
      </Center>
      {statistics.years.map((year) => (
        <PerYearStatisticsItem key={year} year={year} games={statistics.gamesPerYear.get(year)!} onClick={onClick} />
      ))}
    </div>
  );
};

export default PerYearStatistics;
