import { useEffect, useRef } from "react";

export const useFocus = () => {
  const htmlElRef = useRef<HTMLInputElement>(null);

  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  useEffect(() => {
    if (htmlElRef && htmlElRef.current) {
      htmlElRef.current.focus();
    }
  }, [htmlElRef]);

  return [htmlElRef, setFocus];
};
