import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, Paper, Typography } from "@mui/material";
import { GameCategory, GameCollection, GameMode, Genre, Medium, Platform, PlayerPerspective, Theme, UserGameStatus } from "neorak-game-lib-model";
import React, { useMemo } from "react";
import { UserGameFilter, UserGameFilterData } from "../../../domain/userGameFilter";
import GameStatusInfo from "../../../lib/constants/gameStatusInfo";
import * as Icon from "../../../lib/constants/pageIcons";
import { enums } from "../../../lib/enum";
import { formatCategory } from "../../../lib/format";
import { sortRefdataByName } from "../../../lib/sort";
import { FilterBlock } from "./FilterBlock";

const Root = styled(Paper)`
  min-height: 100%;
  max-height: 100%;
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-bottom: 2rem;
`;

const Title = styled(Typography)`
  margin: 1rem 0;
`;

const Spacer = styled.div`
  flex: 1;
`;

type Props = {
  data: UserGameFilterData;
  onChange: (filter: UserGameFilter) => void;
};

export const FilterDrawer: React.FC<Props> = ({ data, onChange }) => {
  const currentCollection = useMemo(() => (data.filter.collection ? data.refdata.collections.find((c) => data.filter.collection === c._id) : undefined), [data]);
  const currentPlatform = useMemo(() => (data.filter.platform ? data.refdata.platformMedia.find((c) => data.filter.platform === c.platform._id)?.platform : undefined), [data]);
  const platforms = useMemo(() => data.refdata.platformMedia.map((pm) => pm.platform).sort(sortRefdataByName), [data]);
  const currentMedium = useMemo(
    () => (data.filter.platform && data.filter.medium ? data.refdata.platformMedia.find((c) => data.filter.platform === c.platform._id)?.media.find((m) => m._id === data.filter.medium) : undefined),
    [data]
  );
  const media = useMemo(() => (data.filter.platform ? data.refdata.platformMedia.find((c) => data.filter.platform === c.platform._id)?.media : undefined), [data]);
  const currentGenre = useMemo(() => (data.filter.genre ? data.refdata.genres.find((g) => data.filter.genre === g._id) : undefined), [data]);
  const currentGameMode = useMemo(() => (data.filter.gameMode ? data.refdata.gameModes.find((g) => data.filter.gameMode === g._id) : undefined), [data]);
  const currentPlayerperspective = useMemo(() => (data.filter.playerPerspective ? data.refdata.playerPerspectives.find((pp) => data.filter.playerPerspective === pp._id) : undefined), [data]);
  const currentTheme = useMemo(() => (data.filter.theme ? data.refdata.themes.find((t) => data.filter.theme === t._id) : undefined), [data]);
  const statistics: string[] = useMemo(() => {
    const result: string[] = [];
    let allGamesCount = 0;
    Object.keys(data.statistics.gameCount)
      .sort()
      .forEach((k) => {
        const category = k as GameCategory;
        const count = data.statistics.gameCount[category] || 0;
        allGamesCount += count;
        result.push(`${count} ${formatCategory(category)}s`);
      });
    result.push(`${allGamesCount} all-over Entries`);
    return result;
  }, [data]);

  return (
    <Root elevation={3}>
      <Title variant="h6">Filter</Title>
      <FilterBlock<GameCategory>
        value={data.filter.gameCategory}
        getId={(v) => v}
        getLabel={(v) => formatCategory(v)}
        title="Category"
        values={enums(GameCategory).sort()}
        onClick={(v) => onChange({ ...data.filter, gameCategory: v })}
        icon={<Icon.GAMES />}
      />
      <FilterBlock<GameCollection>
        value={currentCollection}
        getId={(v) => v._id!}
        getLabel={(v) => v.name}
        title="Collections"
        values={data.refdata.collections}
        onClick={(v) => onChange({ ...data.filter, collection: v?._id })}
        icon={<Icon.GAME_COLLECTION />}
      />
      <FilterBlock<GameMode>
        value={currentGameMode}
        getId={(v) => v._id!}
        getLabel={(v) => v.name}
        title="Game Mode"
        values={data.refdata.gameModes}
        onClick={(v) => onChange({ ...data.filter, gameMode: v?._id })}
        icon={<Icon.GAME_MODE />}
      />
      <FilterBlock<UserGameStatus>
        value={data.filter.status}
        getId={(v) => v}
        getLabel={(v) => GameStatusInfo[v].label}
        title="Game Status"
        values={enums(UserGameStatus).sort()}
        onClick={(v) => onChange({ ...data.filter, status: v })}
        icon={<Icon.GAME_STATUS />}
      />
      <FilterBlock<Genre>
        value={currentGenre}
        getId={(v) => v._id!}
        getLabel={(v) => v.name}
        title="Genre"
        values={data.refdata.genres}
        onClick={(v) => onChange({ ...data.filter, genre: v?._id })}
        icon={<Icon.GENRE />}
      />
      <FilterBlock<Platform>
        value={currentPlatform}
        getId={(v) => v._id!}
        getLabel={(v) => v.name}
        title="Platform"
        values={platforms}
        onClick={(v) => onChange({ ...data.filter, platform: v?._id, medium: undefined })}
        icon={<Icon.PLATFORM />}
      />
      {media && (
        <FilterBlock<Medium>
          value={currentMedium}
          getId={(v) => v._id!}
          getLabel={(v) => v.name}
          title="Platform-Medium"
          values={media}
          onClick={(v) => onChange({ ...data.filter, medium: v?._id })}
          icon={<Icon.MEDIUM />}
        />
      )}
      <FilterBlock<PlayerPerspective>
        value={currentPlayerperspective}
        getId={(v) => v._id!}
        getLabel={(v) => v.name}
        title="Player Perspective"
        values={data.refdata.playerPerspectives}
        onClick={(v) => onChange({ ...data.filter, playerPerspective: v?._id })}
        icon={<Icon.PLAYER_PERSPECTIVE />}
      />
      <FilterBlock<Theme>
        value={currentTheme}
        getId={(v) => v._id!}
        getLabel={(v) => v.name}
        title="Theme"
        values={data.refdata.themes}
        onClick={(v) => onChange({ ...data.filter, theme: v?._id })}
        icon={<Icon.THEME />}
      />
      <Title variant="h6">Counts</Title>
      {statistics.map((s, i) => (
        <Typography key={i} variant="caption">
          {s}
        </Typography>
      ))}
      <Spacer />
      <Button variant="contained" endIcon={<ClearIcon />} onClick={(v) => onChange({})} fullWidth>
        Clear
      </Button>
    </Root>
  );
};
