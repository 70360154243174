import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/de";
import React from "react";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../../services/storage";
import AppRouter from "./AppRouter";

//TODO: Edit ThirdParty-Game-Attributes
//TODO: Create custom ThirdParty-Game

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const AppView: React.FC<{}> = () => {
  moment.locale("de");
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={darkTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
            <Router>
              <AppRouter />
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppView;
