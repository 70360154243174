import moment from "moment";
import { nanoid } from "nanoid";
import { GamePlatformMedium, GameStatusHistory, UserGame, UserGameStatus } from "neorak-game-lib-model";

export type ChangePlaytime = {
  playtime: string;
  calcPlaytime: string;
  statusHistory: StatusHistory[];
};

export type StatusHistory = {
  id: string;
  status: UserGameStatus;
  playtime?: string;
  statusChangedAt?: moment.Moment;
  platformMedium?: GamePlatformMedium;
  bundleId?: string;
};

export const toChangePlaytime = (game: UserGame) => {
  return {
    playtime: `${game.playtime || ""}`,
    calcPlaytime: `${game.calcPlaytime || ""}`,
    statusHistory:
      game.statusHistory.map(
        (sh) =>
          ({
            id: sh.id,
            status: sh.status,
            bundleId: sh.bundleId,
            createdAt: sh.createdAt,
            platformMedium: sh.platformMedium,
            playtime: `${sh.playtime || ""}`,
            statusChangedAt: sh.statusChangedAt ? moment(sh.statusChangedAt) : undefined,
          } as StatusHistory)
      ) || [],
  } as ChangePlaytime;
};

export const calculateStatus = (history: GameStatusHistory[] = []): UserGameStatus =>
  history.reduce<UserGameStatus>((acc, cur) => {
    switch (cur.status) {
      case UserGameStatus.FINISHED:
        return cur.status;
      case UserGameStatus.WILL_NOT_FINISH:
        if (acc === UserGameStatus.NONE) return cur.status;
        else return acc;
      case UserGameStatus.NONE:
        return acc;
      default:
        return acc;
    }
  }, UserGameStatus.NONE);

export const byChangedAt = (a: GameStatusHistory, b: GameStatusHistory) => (b.statusChangedAt || Number.MAX_VALUE) - (a.statusChangedAt || Number.MAX_VALUE);

export const updatePlaytime = (userGame: UserGame, pt: ChangePlaytime): UserGame => {
  const game: UserGame = { ...userGame };
  const updatedStatusHistory = pt.statusHistory || [];
  game.playtime = pt.playtime ? parseInt(pt.playtime) : undefined;
  game.calcPlaytime = pt.calcPlaytime ? parseInt(pt.calcPlaytime) : undefined;
  const deletedIds: string[] = [];
  const updatedIds: string[] = [];
  game.statusHistory.forEach((existing) => {
    const updated = updatedStatusHistory.find((s) => s.id === existing.id);
    if (updated) {
      existing.playtime = updated.playtime ? parseInt(updated.playtime) : undefined;
      existing.statusChangedAt = updated.statusChangedAt ? updated.statusChangedAt.toDate().getTime() : undefined;
      updatedIds.push(existing.id);
    } else {
      deletedIds.push(existing.id);
    }
  });
  game.statusHistory = game.statusHistory.filter((sh) => !deletedIds.includes(sh.id));
  const newHistoryEntries = updatedStatusHistory.filter((s) => !updatedIds.includes(s.id) && !deletedIds.includes(s.id));
  newHistoryEntries.forEach((newHistory) => {
    game.statusHistory.push({
      id: nanoid(),
      status: newHistory.status,
      playtime: newHistory.playtime ? parseInt(newHistory.playtime) : undefined,
      statusChangedAt: newHistory.statusChangedAt ? newHistory.statusChangedAt.toDate().getTime() : undefined,
      createdAt: Date.now(),
    });
  });
  game.statusHistory.sort(byChangedAt);
  game.status = calculateStatus(game.statusHistory);

  const lastHistory = game.statusHistory.reduce<GameStatusHistory | undefined>((last, history) => {
    if (!last || (last.statusChangedAt || 0) < (history.statusChangedAt || 0)) {
      return history;
    }
    return last;
  }, undefined);
  if (lastHistory && game.status === lastHistory.status) {
    game.statusChangedAt = lastHistory.statusChangedAt;
  }

  return game;
};
