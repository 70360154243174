import { ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  onClick: () => void;
  icon?: ReactNode;
  label?: string;
  disabled?: boolean;
  selected?: boolean;
  secondaryLabel?: string;
};

export const DataListItem: React.FC<Props> = ({ onClick, label, secondaryLabel, icon, disabled = false, selected = false }) => {
  return (
    <ListItem disableGutters>
      <ListItemButton onClick={onClick} disabled={disabled} selected={selected}>
        {icon && <ListItemAvatar>{icon}</ListItemAvatar>}
        <ListItemText inset={!icon} primary={label} secondary={secondaryLabel} />
      </ListItemButton>
    </ListItem>
  );
};
