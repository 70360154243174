import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { PropsWithChildren, ReactNode } from "react";
import { Themed } from "../../lib/types";

const StyledDialogContent = styled(DialogContent)<Themed>`
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

const StyledTitle = styled(DialogTitle)<Themed>`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
`;

const StyledForm = styled.form`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.span`
  flex: 1;
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  actions?: React.ReactNode;
  icon?: ReactNode;
  maxWidth?: Breakpoint | false;
  title: string | React.ReactNode;
  titleActionIcon?: React.ReactNode;
  titleAction?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
};

export const FittingDialog: React.FC<PropsWithChildren<Props>> = ({
  actions,
  children,
  icon,
  maxWidth,
  title,
  titleActionIcon = <CloseIcon color="inherit" />,
  titleAction,
  onClose,
  onSubmit,
}) => {
  const matchesMediaQuery = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <Dialog
      fullWidth
      fullScreen={matchesMediaQuery}
      maxWidth={maxWidth}
      onClose={onClose}
      open={true}
      TransitionComponent={Transition}
    >
      <StyledForm onSubmit={onSubmit}>
        <StyledTitle>
          {icon}
          {title}
          <Spacer />
          {titleAction && <IconButton onClick={titleAction}>{titleActionIcon}</IconButton>}
        </StyledTitle>
        <StyledDialogContent dividers>{children}</StyledDialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </StyledForm>
    </Dialog>
  );
};
