import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Medium } from "neorak-game-lib-model";
import { PlatformMedia } from "../../domain/platformMedia";
import { DataListItem } from "../shared/DataListItem";
import { FittingDialog } from "../shared/FittingDialog";

type Props = {
  media: Medium[];
  selected: PlatformMedia;
  onUpdate: (pm: PlatformMedia) => void | Promise<void>;
  onDelete: (pm: PlatformMedia) => void | Promise<void>;
  onSelect: (m: Medium) => void;
  onCancel: () => void;
  onFavorite: () => void;
};

export const EditMediaDialog: React.FC<Props> = ({
  media,
  selected,
  onDelete,
  onSelect,
  onCancel,
  onUpdate,
  onFavorite,
}) => {
  return (
    <FittingDialog
      maxWidth="sm"
      title={`What are your media for '${selected.platform.name}'?`}
      titleAction={() => onDelete(selected)}
      titleActionIcon={<DeleteForeverIcon />}
      actions={
        <>
          <Button autoFocus onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={() => onUpdate(selected)} autoFocus disabled={selected.media.length === 0}>
            Save
          </Button>
        </>
      }
    >
      <List
        sx={{ pt: 0 }}
        subheader={<ListSubheader style={{ backgroundColor: "transparent" }}>Favorite</ListSubheader>}
      >
        <Divider />
        <ListItemButton onClick={onFavorite}>
          <ListItemIcon>{selected.favorite ? <StarRateIcon /> : <StarOutlineIcon />}</ListItemIcon>
          <ListItemText primary="This is my favorite platform" />
        </ListItemButton>
      </List>
      <List sx={{ pt: 0 }} subheader={<ListSubheader style={{ backgroundColor: "transparent" }}>Media</ListSubheader>}>
        <Divider />
        {media.map((medium) => (
          <DataListItem
            key={medium._id}
            label={medium.name}
            icon={
              <Checkbox
                edge="start"
                checked={selected.media.find((m) => medium._id === m._id) !== undefined}
                tabIndex={-1}
                disableRipple
              />
            }
            onClick={() => onSelect(medium)}
          />
        ))}
      </List>
    </FittingDialog>
  );
};
