import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlayerPerspective } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type PlayerPerspectiveState = {
  playerPerspectives: PlayerPerspective[];
  selectedPlayerPerspective?: PlayerPerspective;
  isLoadingPlayerPerspective: boolean;
};

const initialState: PlayerPerspectiveState = {
  isLoadingPlayerPerspective: false,
  playerPerspectives: [],
};

const playerPerspectiveSlice = createSlice({
  name: "playerPerspective",
  initialState,
  reducers: {
    starLoadingPlayerPerspectives: (state: PlayerPerspectiveState) => {
      state.isLoadingPlayerPerspective = true;
    },
    loadingPlayerPerspectivesSuccessful: (
      state: PlayerPerspectiveState,
      action: PayloadAction<PlayerPerspective[]>
    ) => {
      state.isLoadingPlayerPerspective = false;
      state.playerPerspectives = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const playerPerspectiveSelector = (state: AppRootState): PlayerPerspectiveState =>
  state.refdata.playerPerspective;

export const { loadingPlayerPerspectivesSuccessful, starLoadingPlayerPerspectives, reset } =
  playerPerspectiveSlice.actions;

export default playerPerspectiveSlice.reducer;
