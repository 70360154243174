import { useLocation, useNavigate } from "react-router-dom";
import { RequireAuthRouteState } from "../../lib/types";

export type NavigationService = {
  from?: string;
  navigateToAndReplace: (link: string) => void;
  navigateTo: (link: string, state?: string) => void;
};

export const useNavigationServiceImpl = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const from = state ? (state as RequireAuthRouteState).from : undefined;

  return {
    from,
    navigateToAndReplace: (link: string) => navigate(link, { replace: true }),
    navigateTo: (link: string, state?: string) => navigate(link, { state }),
  };
};
