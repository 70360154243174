import { PlatformMedium } from "neorak-game-lib-model";
import { deleteJson, getJson, postJson, putJson } from "../../lib/http";

const API_PATH = "/api/v2/platformMedium";

export const getPlatformMedia = async (token?: string): Promise<PlatformMedium[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get platform/medium-entries");
};

export const createPlatformMedium = async (pm: PlatformMedium, token?: string): Promise<void> => {
  await postJson(API_PATH, pm, { token });
};

export const updatePlatformMedium = async (pm: PlatformMedium, token?: string): Promise<void> => {
  await putJson(`${API_PATH}/${pm._id}`, pm, { token });
};

export const deletePlatformMedium = async (pm: PlatformMedium, token?: string): Promise<void> => {
  await deleteJson(`${API_PATH}/${pm._id}`, { token });
};
