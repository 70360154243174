import { UserGame } from "neorak-game-lib-model";
import { getImageId } from "../../domain/dbImage";
import { ApiService, ImageStorageService, ProfileStorageService } from "../../domain/ports";
import { IGDB_IMAGE_TYPE, bufferToBase64 } from "../../lib/image";

export type SaveGameDependencies = Pick<ApiService, "updateUserGame"> & Pick<ProfileStorageService, "storeUpdatedUserGameEntry"> & Pick<ImageStorageService, "updateImages" | "getImage">;

export const saveGame = async (userGame: UserGame, newCoverData: Uint8Array | undefined, selectGame: (game?: UserGame) => void, deps: SaveGameDependencies) => {
  const storedCover = await deps.getImage(getImageId(userGame));
  userGame.updatedAt = Date.now();
  await deps.updateUserGame({
    game: userGame,
    cover: newCoverData
      ? {
          data: await bufferToBase64(newCoverData),
          contentType: IGDB_IMAGE_TYPE,
        }
      : undefined,
  });
  let coverData: Uint8Array | undefined;
  if (newCoverData) coverData = newCoverData;
  else if (storedCover) coverData = storedCover.data;

  if (coverData) {
    await deps.updateImages([
      {
        id: getImageId(userGame),
        updated: userGame.updatedAt!,
        data: coverData,
      },
    ]);
  }
  deps.storeUpdatedUserGameEntry(userGame);
  selectGame(undefined);
};
