import StarRateIcon from "@mui/icons-material/StarRate";
import { Medium, Platform } from "neorak-game-lib-model";
import { useState } from "react";
import { PlatformMedia } from "../../domain/platformMedia";
import * as Icon from "../../lib/constants/pageIcons";
import { MaintainAction } from "../../lib/types";
import { RefdataListDialog } from "../Refdata/RefdataListDialog";
import { DataListDialog } from "../shared/DataListDialog";
import { EditMediaDialog } from "./EditMediaDialog";

type Props = {
  values: PlatformMedia[];
  platforms: Platform[];
  media: Medium[];
  selected?: PlatformMedia;
  onClose: () => void;
  onUpdate: (pm: PlatformMedia) => void | Promise<void>;
  onDelete: (pm: PlatformMedia) => void | Promise<void>;
  onCreate: (p: Platform) => void;
  onMediumSelect: (m: Medium) => void;
  onPlatformMediaDeselect: () => void;
  onEdit: (pm: PlatformMedia) => void | Promise<void>;
  onPlatformFavorite: () => void;
};

export const PlatformMediaDialog: React.FC<Props> = ({
  media,
  platforms,
  selected,
  values,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  onMediumSelect,
  onPlatformMediaDeselect,
  onUpdate,
  onPlatformFavorite,
}) => {
  const [action, setAction] = useState<MaintainAction | undefined>(undefined);
  return (
    <>
      <DataListDialog
        title={`Platform/Media ${values.length > 0 ? `[${values.length}]` : ""}`}
        icon={<Icon.PLATFORM_MEDIA />}
        values={values}
        onClose={onClose}
        getIcon={(v) => (v.favorite ? <StarRateIcon /> : <Icon.PLATFORM_MEDIA />)}
        getId={(value) => value.platform._id || ""}
        getLabel={(value) => value.platform.name}
        getSecondaryLabel={(value) =>
          `${value.media
            .map((m) => m.name)
            .sort()
            .join(", ")}`
        }
        onClick={onEdit}
        onAddClick={() => setAction("create")}
      />
      {action === "create" && !selected && (
        <RefdataListDialog
          title="What's your Platform?"
          icon={<Icon.PLATFORM />}
          values={platforms}
          onClose={() => setAction(undefined)}
          onClick={(p) => {
            setAction(undefined);
            onCreate(p);
          }}
        />
      )}
      {selected && (
        <EditMediaDialog
          media={media}
          selected={selected}
          onCancel={onPlatformMediaDeselect}
          onDelete={onDelete}
          onSelect={onMediumSelect}
          onUpdate={onUpdate}
          onFavorite={onPlatformFavorite}
        />
      )}
    </>
  );
};
