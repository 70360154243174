"use strict";
exports.__esModule = true;
var PlatformCategory;
(function (PlatformCategory) {
    PlatformCategory["console"] = "console";
    PlatformCategory["arcade"] = "arcade";
    PlatformCategory["platform"] = "platform";
    PlatformCategory["operating_system"] = "operating_system";
    PlatformCategory["portable_console"] = "portable_console";
    PlatformCategory["computer"] = "computer";
})(PlatformCategory = exports.PlatformCategory || (exports.PlatformCategory = {}));
