import { GameToMigrate } from "neorak-game-lib-model";
import { MigrationStorage } from "../../domain/ports";

export type SelectGameToMigrateDependencies = Pick<
  MigrationStorage,
  "selectGameToMigrate" | "saveFoundGames" | "startAction" | "saveUserGame"
>;

export const selectGameToMigrateAction = (game: GameToMigrate | undefined, deps: SelectGameToMigrateDependencies) => {
  deps.selectGameToMigrate(game);
  deps.saveFoundGames([]);
  deps.startAction();
  deps.saveUserGame(undefined);
};
