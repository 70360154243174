import { Game, UserGame } from "neorak-game-lib-model";

export const sortGame = (a: Game, b: Game) => {
  const result = a.releaseYear - b.releaseYear;
  if (result !== 0) return result;
  if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
  if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
  return 0;
};

export const getName = (g: UserGame) => g.customName || g.game.name;
