import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { LoadingStatusIcon } from "./LoadingStatusIcon";

type Props = {
  gameStatistics: DataloadingStatistic[];
};

export const GameLoadingStatistic: React.FC<Props> = ({ gameStatistics }) => (
  <>
    <Typography variant="h5">Game Data</Typography>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {gameStatistics.map((stat) => (
          <TableRow key={stat.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              {stat.name}
            </TableCell>
            <TableCell align="right">
              <LoadingStatusIcon statistic={stat} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);
