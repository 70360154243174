import { sortGame } from "../../domain/game";
import { AddGameStorage, ApiService } from "../../domain/ports";

type SearchGamesActionDependencies = Pick<AddGameStorage, "saveFoundGames" | "startSearching" | "searchingFinished" | "startAction"> & Pick<ApiService, "searchGames">;

export const searchGamesAction = async (title: string, deps: SearchGamesActionDependencies) => {
  deps.startAction("Search");
  deps.startSearching();
  deps.saveFoundGames((await deps.searchGames({ title })).sort(sortGame));
  deps.searchingFinished();
};
