import styled from "@emotion/styled";
import { Themed } from "../../lib/types";

export const SourceListColumn = styled.div<Themed & { maxWidth?: string }>`
  flex: 1;
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : "")}
  border-right: rgba(255, 255, 255, 0.12) solid 2px;
  overflow: auto;
  scrollbar-width: thin;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: 2rem 1rem;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;
