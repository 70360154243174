import { combineReducers } from "redux";
import favoritePlatformReducer from "./favoritePlatformReducer";
import gameCollectionsReducer from "./gameCollectionsReducer";
import platformMediaReducer from "./platformMediaReducer";
import userGameReducer from "./userGameReducer";

export const profileReducer = combineReducers({
  platformMedia: platformMediaReducer,
  favoritePlatform: favoritePlatformReducer,
  gameCollections: gameCollectionsReducer,
  userGames: userGameReducer,
});
