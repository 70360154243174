import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { UserGame } from "neorak-game-lib-model";
import * as React from "react";
import { getImageId } from "../../domain/dbImage";
import { FinishedGame } from "../../domain/userGame";
import { Themed } from "../../lib/types";
import { Cover } from "../Home/Cover";
import { IMAGE_MIN_WIDTH } from "../shared/CoverImage";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Root = styled.div<Themed & { maxWidth?: string }>`
  align-items: baseline;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${IMAGE_MIN_WIDTH}, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

const Wrapper = styled.div`
  padding-left: 1.5rem;
  flex-grow: 1;
`;

type PerYearStatisticsItemProps = {
  games: FinishedGame[];
  year: number;
  onClick: (game: UserGame) => void;
};

const PerYearStatisticsItem: React.FC<PerYearStatisticsItemProps> = ({ games, year, onClick }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Center>
          <Typography>
            {year} ({games.length})
          </Typography>
        </Center>
      </AccordionSummary>
      <AccordionDetails>
        <Wrapper>
          <Root>
            {games.map((g, i) => (
              <Cover key={`${getImageId(g.game)}-${i}`} game={g.game} onClick={() => onClick(g.game)} />
            ))}
          </Root>
        </Wrapper>
      </AccordionDetails>
    </Accordion>
  );
};
export default PerYearStatisticsItem;
