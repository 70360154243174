import { BASE_URL } from "./constants/routes";
import { HttpConfig, HttpResult } from "./types";

const handleResponse = async (response: Response): Promise<HttpResult> => {
  if (response.status === 403) {
    throw Error("NOT_AUTHORIZED");
  } else if (!response.ok) {
    throw Error(response.statusText);
  }
  const isImage = response.headers.get("Content-Type")?.toLowerCase().startsWith("image");
  if (isImage) {
    return {
      status: "SUCCESS",
      binary: await response.arrayBuffer(),
    };
  }
  const json = await response.json();
  return {
    status: "SUCCESS",
    json,
  };
};

export const postJson = async (api: string, body: any, config: HttpConfig): Promise<HttpResult> => {
  const headers: HeadersInit = {
    "content-type": "application/json",
  };
  if (config.token) {
    headers["Authorization"] = `bearer ${config.token}`;
  }

  const response = await fetch(`${BASE_URL}${api}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers,
  });
  return handleResponse(response);
};

export const getJson = async (api: string, config: HttpConfig): Promise<HttpResult> => {
  const headers: HeadersInit = {
    "content-type": "application/json",
  };
  if (config.token) {
    headers["Authorization"] = `bearer ${config.token}`;
  }

  const response = await fetch(`${BASE_URL}${api}`, {
    method: "GET",
    headers,
  });
  return handleResponse(response);
};

export const putJson = async (api: string, body: any, config: HttpConfig): Promise<HttpResult> => {
  const headers: HeadersInit = {
    "content-type": "application/json",
  };
  if (config.token) {
    headers["Authorization"] = `bearer ${config.token}`;
  }

  const response = await fetch(`${BASE_URL}${api}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers,
  });
  return handleResponse(response);
};

export const deleteJson = async (api: string, config: HttpConfig): Promise<HttpResult> => {
  const headers: HeadersInit = {
    "content-type": "application/json",
  };
  if (config.token) {
    headers["Authorization"] = `bearer ${config.token}`;
  }

  const response = await fetch(`${BASE_URL}${api}`, {
    method: "DELETE",
    headers,
  });
  return handleResponse(response);
};

export const getRawData = async (api: string, config: HttpConfig): Promise<HttpResult> => {
  const headers: HeadersInit = {
    "content-type": "application/json",
  };
  if (config.token) {
    headers["Authorization"] = `bearer ${config.token}`;
  }

  const response = await fetch(`${BASE_URL}${api}`, {
    method: "GET",
    headers,
  });
  return handleResponse(response);
};
