import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { useState } from "react";
import { ConfirmDialog } from "./ConfirmDialog";

type Props = {
  message: string;
  label: string;
  onClick: () => void;
};

export const DeleteButton: React.FC<Props> = ({ message, label, onClick }) => {
  const [confirm, setConfirm] = useState(false);
  return (
    <>
      <Button variant="outlined" endIcon={<DeleteIcon />} fullWidth onClick={() => setConfirm(true)}>
        {label}
      </Button>
      {confirm && (
        <ConfirmDialog
          title="Delete"
          message={message}
          onCancel={() => setConfirm(false)}
          onConfirm={() => {
            setConfirm(false);
            onClick();
          }}
        />
      )}
    </>
  );
};
