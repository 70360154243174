import { FavoritePlatform, Game, GameCollection, GameMode, GameToMigrate, Genre, LoadStatistic, Medium, Platform, PlatformFamily, PlayerPerspective, Theme, UserGame } from "neorak-game-lib-model";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { PlatformMedia } from "../../domain/platformMedia";
import { AddGameStorage, DataLoadingStatisticStorage, HomeStorage, MigrationStorage, ProfileStorageService, RefdataStorageService, SystemStorageService } from "../../domain/ports";
import { GameToStore } from "../../domain/userGame";
import { UserGameFilter } from "../../domain/userGameFilter";
import { AddGameAction, AuthToken, MaintainAction, MigrationAction } from "../../lib/types";
import {
  addGameLoadingStatistics,
  addRefdataLoadingStatistics,
  dataLoadingStatisticSelector,
  reset as resetStatistics,
  updateGameLoadingStatistic,
  updateRefdataLoadingStatistic,
} from "./redux/dataLoadingStatistic";
import { favoritePlatformSelector, loadingFavoritePlatformsSuccessful, starLoadingFavoritePlatforms } from "./redux/favoritePlatformReducer";
import {
  createGameCollection,
  editGameCollection,
  endMaintainGameCollection,
  gameCollectionsSelector,
  loadingGameCollectionsSuccessful,
  startLoadingGameCollections,
} from "./redux/gameCollectionsReducer";
import { gameModeSelector, loadingGameModesSuccessful, starLoadingGameModes } from "./redux/gameModeReducer";
import { genreSelector, loadingGenresSuccessful, starLoadingGenres } from "./redux/genreReducer";
import { loginSelector, loginSuccess, reset as resetLogin, startLogin } from "./redux/loginReducer";
import { loadingMediaSuccessful, mediumSelector, selectMedium, starLoadingMedia } from "./redux/mediumReducer";
import { loadingPlatformFamiliesSuccessful, platformFamilySelector, starLoadingPlaformFamilies } from "./redux/platformFamilyReducer";
import {
  addMediumToPlatformMedia,
  createPlatformMedia,
  editPlatformMedia,
  endMaintainPlatformMedia,
  loadingPlatformMediaSuccessful,
  platformMediaSelector,
  removeMediumFromPlatformMedia,
  starLoadingPlatformMedia,
} from "./redux/platformMediaReducer";
import { loadingPlatformsSuccessful, platformSelector, starLoadingPlatforms } from "./redux/platformReducer";
import { loadingPlayerPerspectivesSuccessful, playerPerspectiveSelector, starLoadingPlayerPerspectives } from "./redux/playerPerspectiveReducer";
import { loadingThemesSuccessful, starLoadingThemes, themeSelector } from "./redux/themeReducer";
import { loadingThirdPartyStatistics, loadingThirdPartyStatisticsSuccessful, thirdPartyRefdataStatisticSelector } from "./redux/thirdPartyRefdataStatistic";
import { addUserGameToStore, loadingUserGameSuccessful, removeUserGameFromStore, storeUpdatedUserGameEntry, updateFilter, userGameSelector } from "./redux/userGameReducer";

export const useSystemStorage = (): SystemStorageService => {
  const dispatch = useDispatch();
  const { token } = useSelector(loginSelector);
  return {
    token,
    startLogin: useCallback(() => dispatch(startLogin()), [dispatch]),
    loginSuccess: useCallback((token: AuthToken) => dispatch(loginSuccess(token.token)), [dispatch]),
    removeToken: useCallback(() => dispatch(resetLogin()), [dispatch]),
  };
};

export const useRefdataStorage = (): RefdataStorageService => {
  const dispatch = useDispatch();
  const { isLoadingThirdParty, statistics } = useSelector(thirdPartyRefdataStatisticSelector);
  const { genres } = useSelector(genreSelector);
  const { gameModes } = useSelector(gameModeSelector);
  const { media, selectedMedium } = useSelector(mediumSelector);
  const { playerPerspectives } = useSelector(playerPerspectiveSelector);
  const { themes } = useSelector(themeSelector);
  const { platforms } = useSelector(platformSelector);
  const { platformFamilies } = useSelector(platformFamilySelector);
  return {
    gameModes,
    genres,
    isLoadingThirdParty,
    media,
    playerPerspectives,
    themes,
    platforms,
    platformFamilies,
    selectedMedium,
    thirdPartyStatistics: statistics,
    startLoadingThirdPartyRefdata: useCallback(() => dispatch(loadingThirdPartyStatistics()), [dispatch]),
    loadingThirdPartyRefdataSuccess: useCallback((statistics: LoadStatistic[]) => dispatch(loadingThirdPartyStatisticsSuccessful(statistics)), [dispatch]),
    startLoadingGenres: useCallback(() => dispatch(starLoadingGenres()), [dispatch]),
    loadingGenresSuccessful: useCallback((genres: Genre[]) => dispatch(loadingGenresSuccessful(genres)), [dispatch]),
    startLoadingThemes: useCallback(() => dispatch(starLoadingThemes()), [dispatch]),
    loadingThemesSuccessful: useCallback((themes: Theme[]) => dispatch(loadingThemesSuccessful(themes)), [dispatch]),
    startLoadingGameModes: useCallback(() => dispatch(starLoadingGameModes()), [dispatch]),
    loadingGameModesSuccessful: useCallback((gameModes: GameMode[]) => dispatch(loadingGameModesSuccessful(gameModes)), [dispatch]),
    startLoadingPlayerPerspectives: useCallback(() => dispatch(starLoadingPlayerPerspectives()), [dispatch]),
    loadingPlayerPerspectivesSuccessful: useCallback((playerPerspectives: PlayerPerspective[]) => dispatch(loadingPlayerPerspectivesSuccessful(playerPerspectives)), [dispatch]),
    startLoadingMedia: useCallback(() => dispatch(starLoadingMedia()), [dispatch]),
    loadingMediaSuccessful: useCallback((media: Medium[]) => dispatch(loadingMediaSuccessful(media)), [dispatch]),
    selectMedium: useCallback((medium?: Medium) => dispatch(selectMedium(medium)), [dispatch]),
    startLoadingPlatforms: useCallback(() => dispatch(starLoadingPlatforms()), [dispatch]),
    loadingPlatformsSuccessful: useCallback((platforms: Platform[]) => dispatch(loadingPlatformsSuccessful(platforms)), [dispatch]),
    startLoadingPlatformFamilies: useCallback(() => dispatch(starLoadingPlaformFamilies()), [dispatch]),
    loadingPlatformFamiliesSuccessful: useCallback((platformFamilies: PlatformFamily[]) => dispatch(loadingPlatformFamiliesSuccessful(platformFamilies)), [dispatch]),
  };
};

export const useProfileStorage = (): ProfileStorageService => {
  const dispatch = useDispatch();
  const { platformMedia, selectedPlatformMedia, isLoadingPlatformMedia, maintainAction: maintainPlatformMediaAction } = useSelector(platformMediaSelector);
  const { isLoadingFavoritePlatforms, favoritePlatforms } = useSelector(favoritePlatformSelector);
  const { loadingGameCollections, collections, selectedGameCollection, maintainAction: maintainGameCollectionAction } = useSelector(gameCollectionsSelector);
  const { filter, userGames, isLoadingGames, maintainAction: maintainUserGameAction, selectedUserGame } = useSelector(userGameSelector);
  return {
    favoritePlatforms,
    filter,
    gameCollections: collections,
    isLoadingFavoritePlatforms,
    isLoadingGameCollections: loadingGameCollections,
    isLoadingPlatformMedia,
    isLoadingUserGames: isLoadingGames,
    maintainGameCollectionAction,
    maintainPlatformMediaAction,
    platformMedia,
    selectedPlatformMedia,
    selectedGameCollection,
    selectedUserGame,
    maintainUserGameAction,
    userGames,
    addUserGame: useCallback((game: UserGame) => dispatch(addUserGameToStore(game)), [dispatch]),
    addMediumToPlatformMedia: useCallback((medium: Medium) => dispatch(addMediumToPlatformMedia(medium)), [dispatch]),
    createNewGameCollection: useCallback((collection: GameCollection) => dispatch(createGameCollection(collection)), [dispatch]),
    createPlatformMedia: useCallback((pm: PlatformMedia) => dispatch(createPlatformMedia(pm)), [dispatch]),
    editGameCollection: useCallback((collection: GameCollection) => dispatch(editGameCollection(collection)), [dispatch]),
    editPlatformMedia: useCallback((pm: PlatformMedia) => dispatch(editPlatformMedia(pm)), [dispatch]),
    endMaintainPlatformMedia: useCallback(() => dispatch(endMaintainPlatformMedia()), [dispatch]),
    endMaintainGameCollection: useCallback(() => dispatch(endMaintainGameCollection()), [dispatch]),
    loadingFavoritePlatformsSuccessful: useCallback((fps: FavoritePlatform[]) => dispatch(loadingFavoritePlatformsSuccessful(fps)), [dispatch]),
    loadingGameCollectionsSuccessful: useCallback((cols: GameCollection[]) => dispatch(loadingGameCollectionsSuccessful(cols)), [dispatch]),
    loadingPlatformMediaSuccessful: useCallback((platformMedia: PlatformMedia[]) => dispatch(loadingPlatformMediaSuccessful(platformMedia)), [dispatch]),
    loadingUserGamesSuccessful: useCallback((userGames: UserGame[]) => dispatch(loadingUserGameSuccessful(userGames)), [dispatch]),
    removeMediumFromPlatformMedia: useCallback((medium: Medium) => dispatch(removeMediumFromPlatformMedia(medium)), [dispatch]),
    removeUserGameFromStore: useCallback((userGame: UserGame) => dispatch(removeUserGameFromStore(userGame)), [dispatch]),
    startLoadingFavoritePlatforms: useCallback(() => dispatch(starLoadingFavoritePlatforms()), [dispatch]),
    startLoadingGameCollections: useCallback(() => dispatch(startLoadingGameCollections()), [dispatch]),
    startLoadingPlatformMedia: useCallback(() => dispatch(starLoadingPlatformMedia()), [dispatch]),
    startLoadingUserGames: useCallback(() => dispatch(startLoadingGameCollections()), [dispatch]),
    storeUpdatedUserGameEntry: useCallback((userGame: UserGame) => dispatch(storeUpdatedUserGameEntry(userGame)), [dispatch]),
    updateGameFilter: useCallback((filter: UserGameFilter) => dispatch(updateFilter(filter)), [dispatch]),
  };
};

export const useMigrationStorage = (): MigrationStorage => {
  const [gtms, setGtms] = useState<GameToMigrate[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [action, setAction] = useState<MigrationAction | undefined>();
  const [selectedGtm, setSelectedGtm] = useState<GameToMigrate | undefined>();
  const [userGame, setUserGame] = useState<UserGame | undefined>();
  const [bundle, setBundle] = useState<GameToStore>();
  const [bundledGames, setBundledGames] = useState<Game[]>();
  const [bundledGame, setBundledGame] = useState<UserGame>();
  return {
    bundle,
    bundledGame,
    bundledGames,
    foundGames: games,
    gamesToMigrate: gtms,
    isSearching: searching,
    migrationAction: action,
    selectedGameToMigrate: selectedGtm,
    userGame,
    saveFoundGames: setGames,
    saveGamesToMigrate: setGtms,
    saveUserGame: setUserGame,
    searchingFinished: () => setSearching(false),
    selectGameToMigrate: (gtm?: GameToMigrate) => setSelectedGtm(gtm),
    setBundle,
    setBundledGame,
    setBundledGames,
    startAction: (action?: MigrationAction) => setAction(action),
    startSearching: () => setSearching(true),
  };
};

export const useDataLoadingStatisticStorage = (): DataLoadingStatisticStorage => {
  const dispatch = useDispatch();
  const { game, refdata } = useSelector(dataLoadingStatisticSelector);
  return {
    gameStatistics: game,
    refdataStatistics: refdata,
    addGameLoadingStatistics: useCallback((stat: DataloadingStatistic) => dispatch(addGameLoadingStatistics(stat)), [dispatch]),
    addRefdataLoadingStatistics: useCallback((stat: DataloadingStatistic) => dispatch(addRefdataLoadingStatistics(stat)), [dispatch]),
    updateGameLoadingStatistic: useCallback((stat: DataloadingStatistic) => dispatch(updateGameLoadingStatistic(stat)), [dispatch]),
    updateRefdataLoadingStatistic: useCallback((stat: DataloadingStatistic) => dispatch(updateRefdataLoadingStatistic(stat)), [dispatch]),
    resetStatistics: useCallback(() => dispatch(resetStatistics()), [dispatch]),
  };
};

export const useHomeStorage = (): HomeStorage => {
  const [coverUrl, setCoverUrl] = useState<string | undefined>();
  const [selected, setSelected] = useState<UserGame | undefined>();
  const [relatedGames, setRelatedGames] = useState<UserGame[]>([]);
  const [maintainAction, setMaintainAction] = useState<MaintainAction | undefined>();
  return {
    coverUrl,
    maintainAction,
    relatedGames,
    selected,
    setCoverUrl,
    setMaintainAction,
    setRelatedGames,
    setSelected,
  };
};

export const useAddGameStorage = (): AddGameStorage => {
  const [games, setGames] = useState<Game[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [action, setAction] = useState<AddGameAction>("Search");
  const [userGame, setUserGame] = useState<UserGame | undefined>();
  const [bundle, setBundle] = useState<GameToStore>();
  const [bundledGames, setBundledGames] = useState<Game[]>();
  const [bundledGame, setBundledGame] = useState<UserGame>();
  return {
    bundle,
    bundledGame,
    bundledGames,
    foundGames: games,
    isSearching: searching,
    addAction: action,
    userGame,
    saveFoundGames: setGames,
    saveUserGame: setUserGame,
    searchingFinished: () => setSearching(false),
    setBundle,
    setBundledGame,
    setBundledGames,
    startAction: setAction,
    startSearching: () => setSearching(true),
  };
};
