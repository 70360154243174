import { Avatar, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import React from "react";

type Props = {
  icon: React.ReactNode | string;
  primary: string;
  secondary?: string | number | React.ReactNode;
  onClick?: () => void;
};

export const EditUserGameItemItem: React.FC<Props> = ({ icon, primary, secondary, onClick }) => {
  return (
    <>
      <ListItem>
        <ListItemButton onClick={onClick}>
          <ListItemAvatar>
            <Avatar>{icon}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
