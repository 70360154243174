import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppRootState } from "..";

export type LoginState = {
  isLoading: boolean;
  token?: string;
};

const initialState: LoginState = {
  isLoading: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    startLogin: (state: LoginState) => {
      state.isLoading = true;
    },
    loginSuccess: (state: LoginState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.token = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const loginSelector = (state: AppRootState): LoginState => state.login;

export const { loginSuccess, startLogin, reset } = loginSlice.actions;

export default loginSlice.reducer;
