import { GameCollection } from "neorak-game-lib-model";
import { ApiService, ProfileStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

type SaveGameCollectionActionDependencies = Pick<
  ApiService,
  "createGameCollection" | "updateGameCollection" | "getGameCollections"
> &
  Pick<
    ProfileStorageService,
    | "endMaintainGameCollection"
    | "startLoadingGameCollections"
    | "maintainGameCollectionAction"
    | "loadingGameCollectionsSuccessful"
  >;

export const saveGameCollection = async (col: GameCollection, deps: SaveGameCollectionActionDependencies) => {
  deps.startLoadingGameCollections();
  if (deps.maintainGameCollectionAction === "create") {
    await deps.createGameCollection(col);
  } else {
    await deps.updateGameCollection(col);
  }
  const cols = await deps.getGameCollections();
  deps.loadingGameCollectionsSuccessful(cols.sort(sortRefdataByName));
};
