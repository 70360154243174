import { PlatformMedia, toPlatformMedium } from "../../domain/platformMedia";
import { ApiService, ProfileStorageService } from "../../domain/ports";

type SavePlatformMediaActionDependencies = Pick<
  ProfileStorageService,
  "endMaintainPlatformMedia" | "startLoadingPlatformMedia"
> &
  Pick<
    ApiService,
    "createPlatformMedium" | "deletePlatformMedium" | "deleteFavoritePlatform" | "createFavoritePlatform"
  >;

export const savePlatformMediaAction = async (pm: PlatformMedia, deps: SavePlatformMediaActionDependencies) => {
  deps.startLoadingPlatformMedia();
  const { created, deleted } = toPlatformMedium(pm);
  await Promise.all([
    ...created.map((entity) => deps.createPlatformMedium(entity)),
    ...deleted.map((entity) => deps.deletePlatformMedium(entity)),
  ]);
  if (pm.favorite && !pm.favoritePlatform) {
    await deps.createFavoritePlatform({ platformId: pm.platform._id! });
  } else if (!pm.favorite && pm.favoritePlatform) {
    await deps.deleteFavoritePlatform(pm.favoritePlatform);
  }
  deps.endMaintainPlatformMedia();
};
