import { Avatar, Divider, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import { UserGame, UserGameStatus } from "neorak-game-lib-model";
import React, { useEffect, useState } from "react";
import { getImageId } from "../../domain/dbImage";
import { getName } from "../../domain/game";
import GameStatusInfo from "../../lib/constants/gameStatusInfo";
import { formatCategory } from "../../lib/format";
import { getImageUrl } from "../../lib/image";
import { useImageStorage } from "../../services/db/dbAdapter";

type Props = {
  game: UserGame;
  onClick?: () => void;
};

export const RelatedGame: React.FC<Props> = ({ game, onClick }) => {
  const [coverUrl, setCoverUrl] = useState<string | undefined>();
  const imageStorage = useImageStorage();
  useEffect(() => {
    const loadCover = async () => {
      const cover = await imageStorage.getImage(getImageId(game));
      if (cover) {
        setCoverUrl(getImageUrl(cover.data));
      }
    };
    loadCover();
    return () => {
      coverUrl && URL.revokeObjectURL(coverUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.updatedAt]);
  if (!coverUrl) return <></>;
  return (
    <React.Fragment key={game._id}>
      <ListItemButton alignItems="flex-start" onClick={onClick}>
        <ListItemAvatar>
          <Avatar alt={getName(game)} src={coverUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={`${getName(game)} (${moment(game.game.firstReleaseDate).format("YYYY")})`}
          secondary={
            <React.Fragment>
              <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                {formatCategory(game.game.category)}
                {game.status !== UserGameStatus.NONE ? ` | ${GameStatusInfo[game.status].label}` : ""}
                {game.calcPlaytime !== undefined && game.calcPlaytime > 0 ? ` | ${game.calcPlaytime}h` : ""}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItemButton>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};
