import "moment/locale/de";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_DEFAULT, ROUTE_HOME, ROUTE_LOGIN, ROUTE_MIGRATE, ROUTE_STATISTICS } from "../../lib/constants/routes";
import { HomeView } from "../Home/HomeView";
import { LoginView } from "../Login/LoginView";
import { MigrateView } from "../Migrate/MigrateView";
import { StatisticsView } from "../Statistics/StatisticsView";
import { RequireAuth } from "../shared/RequireAuth";

const AppRouter: React.FC<{}> = () => (
  <Routes>
    <Route element={<RequireAuth />}>
      <Route path={ROUTE_HOME} element={<HomeView />} />
      <Route path={ROUTE_MIGRATE} element={<MigrateView />} />
      <Route path={ROUTE_STATISTICS} element={<StatisticsView />} />
    </Route>
    <Route path={ROUTE_LOGIN} element={<LoginView />} />
    <Route path="/*" element={<Navigate to={ROUTE_DEFAULT} />} />
  </Routes>
);

export default AppRouter;
