import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Platform } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type PlatformState = {
  platforms: Platform[];
  selectedPlatform?: Platform;
  isLoadingGenre: boolean;
};

const initialState: PlatformState = {
  isLoadingGenre: false,
  platforms: [],
};

const platformSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    starLoadingPlatforms: (state: PlatformState) => {
      state.isLoadingGenre = true;
    },
    loadingPlatformsSuccessful: (state: PlatformState, action: PayloadAction<Platform[]>) => {
      state.isLoadingGenre = false;
      state.platforms = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const platformSelector = (state: AppRootState): PlatformState => state.refdata.platform;

export const { loadingPlatformsSuccessful, starLoadingPlatforms, reset } = platformSlice.actions;

export default platformSlice.reducer;
