import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { toPlatformMedia } from "../../domain/platformMedia";
import { ApiService, DataLoadingStatisticStorage, ProfileStorageService, RefdataStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

export const loadPlatformMedia = async (
  deps: Pick<ApiService, "getMedia" | "getPlatforms" | "getPlatformMedia" | "getFavoritePlatforms"> &
    Pick<RefdataStorageService, "loadingMediaSuccessful" | "startLoadingMedia" | "loadingPlatformsSuccessful" | "startLoadingPlatforms"> &
    Pick<DataLoadingStatisticStorage, "addRefdataLoadingStatistics" | "updateRefdataLoadingStatistic"> &
    Pick<ProfileStorageService, "loadingFavoritePlatformsSuccessful" | "startLoadingFavoritePlatforms" | "loadingPlatformMediaSuccessful" | "startLoadingPlatformMedia">
) => {
  const mediaStat: DataloadingStatistic = { id: "medium", name: "Medium", status: "running" };
  const platStat: DataloadingStatistic = { id: "platform", name: "Platform", status: "running" };
  const pmStat: DataloadingStatistic = { id: "platformMedium", name: "Platform Medium", status: "running" };
  const fpStat: DataloadingStatistic = { id: "favoritePlatform", name: "Favorite Platform", status: "running" };
  try {
    deps.startLoadingMedia();
    deps.startLoadingPlatforms();
    deps.startLoadingPlatformMedia();
    deps.startLoadingFavoritePlatforms();

    deps.addRefdataLoadingStatistics(mediaStat);
    deps.addRefdataLoadingStatistics(platStat);
    deps.addRefdataLoadingStatistics(pmStat);
    deps.addRefdataLoadingStatistics(fpStat);

    const [media, platforms, platformMedia, favoritePlatforms] = await Promise.all([deps.getMedia(), deps.getPlatforms(), deps.getPlatformMedia(), deps.getFavoritePlatforms()]);

    deps.loadingMediaSuccessful(media ? media.sort(sortRefdataByName) : []);
    deps.loadingPlatformsSuccessful(platforms ? platforms.sort(sortRefdataByName) : []);
    deps.loadingFavoritePlatformsSuccessful(favoritePlatforms);
    deps.loadingPlatformMediaSuccessful(toPlatformMedia(platformMedia, favoritePlatforms, platforms, media));

    deps.updateRefdataLoadingStatistic({ ...mediaStat, status: "finished" });
    deps.updateRefdataLoadingStatistic({ ...platStat, status: "finished" });
    deps.updateRefdataLoadingStatistic({ ...pmStat, status: "finished" });
    deps.updateRefdataLoadingStatistic({ ...fpStat, status: "finished" });
    return media;
  } catch (e) {
    console.log(e);
    deps.updateRefdataLoadingStatistic({ ...mediaStat, status: "failed" });
    deps.updateRefdataLoadingStatistic({ ...platStat, status: "failed" });
    deps.updateRefdataLoadingStatistic({ ...pmStat, status: "failed" });
    deps.updateRefdataLoadingStatistic({ ...fpStat, status: "failed" });
    return [];
  }
};
