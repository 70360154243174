import { GameCollection } from "neorak-game-lib-model";
import { useCallback } from "react";
import { useApiService } from "../../services/api";
import { useProfileStorage } from "../../services/storage";
import { createGameCollectionAction } from "./createGameCollectionAction";
import { deleteGameCollectionAction } from "./deleteGameCollectionAction";
import { editGameCollectionAction } from "./editGameCollectionAction";
import { endMaintainGameCollectionAction } from "./endMaintainGameCollectionAction";
import { loadGameCollectionAction } from "./loadGameCollectionAction";
import { saveGameCollection } from "./saveGameCollectionAction";

export const useGameCollection = () => {
  const apiService = useApiService();
  const profileStorage = useProfileStorage();
  return {
    action: profileStorage.maintainGameCollectionAction,
    isLoading: profileStorage.isLoadingPlatformMedia,
    collections: profileStorage.gameCollections,
    selected: profileStorage.selectedGameCollection,
    create: useCallback(() => createGameCollectionAction({ ...profileStorage }), [profileStorage]),
    edit: useCallback((col: GameCollection) => editGameCollectionAction(col, { ...profileStorage }), [profileStorage]),
    endMaintain: useCallback(() => endMaintainGameCollectionAction({ ...profileStorage }), [profileStorage]),
    load: useCallback(
      () => loadGameCollectionAction({ ...apiService, ...profileStorage }),
      [apiService, profileStorage]
    ),
    save: useCallback(
      (col: GameCollection) => saveGameCollection(col, { ...apiService, ...profileStorage }),
      [apiService, profileStorage]
    ),
    deleteGameCollection: useCallback(
      (col: GameCollection) => deleteGameCollectionAction(col, { ...apiService, ...profileStorage }),
      [apiService, profileStorage]
    ),
  };
};
