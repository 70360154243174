import { Game, GameToMigrate, UserGame, UserGameRequest } from "neorak-game-lib-model";
import { BASE_URL } from "../../lib/constants/routes";
import { deleteJson, getJson, getRawData, postJson, putJson } from "../../lib/http";

const API_PATH = "/api/v2/game";

export type SearchGameQueryParam = { title?: string; bundleId?: number };

export const getGamesToMigrate = async (token?: string): Promise<GameToMigrate[]> => {
  const result = await getJson(`${API_PATH}/migrate`, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get games to migrate");
};

export const deleteGameToMigrate = async (game: GameToMigrate, token?: string): Promise<void> => {
  await deleteJson(`/api/game/${game.id}`, { token });
};

export const getCoverUrl = (game: GameToMigrate, token?: string): string =>
  `${BASE_URL}${API_PATH}/cover/old/${game.id}?token=${encodeURI(token || "")}&time=${game.updatedAt ? btoa(game.updatedAt) : Math.random()}`;

export const searchGames = async (query: SearchGameQueryParam, token?: string): Promise<Game[]> => {
  const urlQuery = query.bundleId ? `bundleId=${query.bundleId}` : `title=${encodeURIComponent(query.title || "")}`;
  const result = await getJson(`${API_PATH}/search?${urlQuery}`, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to search for game");
};

export const createUserGame = async (gameRequest: UserGameRequest, token?: string): Promise<UserGame> => {
  const result = await postJson(API_PATH, gameRequest, { token });
  return result.json;
};

export const updateUserGame = async (gameRequest: UserGameRequest, token?: string): Promise<void> => {
  await putJson(`${API_PATH}/${gameRequest.game._id}`, gameRequest, { token });
};

export const deleteUserGame = async (game: UserGame, token?: string): Promise<void> => {
  await deleteJson(`${API_PATH}/${game._id}`, { token });
};

export const getUserGames = async (token?: string): Promise<UserGame[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get games to migrate");
};

export const getUserGameCover = async (gameId: string, token?: string): Promise<ArrayBuffer | undefined> => {
  const result = await getRawData(`${API_PATH}/cover/${gameId}`, { token });
  if (result.status === "SUCCESS" && result.binary) {
    return result.binary;
  }
  throw Error(`Failed to load game cover: '${gameId}'`);
};

export const getUserGameCoverUrl = (gameId: string, token?: string): string => `${BASE_URL}${API_PATH}/cover/old/${gameId}?token=${encodeURI(token || "missing")}`;
