import React from "react";
import { LoginCredentials } from "../domain/loginCredentials";
import { ApiService, SystemStorageService } from "../domain/ports";
import { ROUTE_DEFAULT } from "../lib/constants/routes";
import { useApiService } from "../services/api";
import { NavigationService, useNavigationServiceImpl } from "../services/navigation";
import { useSystemStorage } from "../services/storage";

type LoginDependencies = Pick<ApiService, "login"> &
  Pick<SystemStorageService, "loginSuccess" | "startLogin"> &
  Pick<NavigationService, "from" | "navigateTo">;

export const loginAction = async (credentials: LoginCredentials, deps: LoginDependencies) => {
  deps.startLogin();
  const token = await deps.login(credentials);
  if (token) {
    deps.loginSuccess(token);
    deps.navigateTo(deps.from || ROUTE_DEFAULT);
  }
};

type LogoutDeps = Pick<SystemStorageService, "removeToken">;

export const logoutAction = async (deps: LogoutDeps) => {
  //TODO: Das JWT-Token sollte auch serverseitg ungültig gemacht werden. Geht das überhaupt?
  deps.removeToken();
};

export const useAuthentication = () => {
  const apiService = useApiService();
  const storageService = useSystemStorage();
  const navigationService = useNavigationServiceImpl();

  return {
    login: React.useCallback(
      async (credentials: LoginCredentials) =>
        await loginAction(credentials, {
          login: apiService.login,
          loginSuccess: storageService.loginSuccess,
          startLogin: storageService.startLogin,
          navigateTo: navigationService.navigateTo,
          from: navigationService.from,
        }),
      [apiService, storageService, navigationService]
    ),
    logout: React.useCallback(async () => {
      await logoutAction({
        removeToken: storageService.removeToken,
      });
    }, [storageService]),
  };
};
