import { Game, GameToMigrate, UserGame } from "neorak-game-lib-model";
import { useCallback } from "react";
import { useApiService } from "../../services/api";
import { useImageStorage } from "../../services/db/dbAdapter";
import { useMigrationStorage, useProfileStorage, useRefdataStorage } from "../../services/storage";
import { deleteGameToMigrateAction } from "./deleteGameToMigrate";
import { loadGamesToMigrateAction } from "./loadGamesToMigrate";
import { saveBundledGame } from "./saveBundledGame";
import { saveMigratedGame } from "./saveMigratedGame";
import { searchGamesAction } from "./searchGames";
import { selectBundledGame } from "./selectBundledGame";
import { selectGameToMigrateAction } from "./selectGameToMigrate";
import { startMigrationAction } from "./startMigration";

export const useMigrate = () => {
  const apiService = useApiService();
  const migrationStorage = useMigrationStorage();
  const imageStorage = useImageStorage();
  const { addUserGame, platformMedia, gameCollections, userGames } = useProfileStorage();
  const { genres, platforms, gameModes, playerPerspectives, themes, media } = useRefdataStorage();
  const { foundGames, isSearching, gamesToMigrate, selectedGameToMigrate, migrationAction, userGame, bundle, bundledGame, bundledGames, setBundle, setBundledGame, setBundledGames } = migrationStorage;
  return {
    bundle,
    bundledGame,
    bundledGames,
    foundGames,
    gameModes,
    gamesToMigrate,
    genres,
    isSearching,
    media,
    migrationAction,
    platforms,
    playerPerspectives,
    selectedGameToMigrate,
    themes,
    userGame,
    platformMedia,
    gameCollections,
    userGames,
    setBundledGame,
    loadGamesToMigrate: useCallback(
      () =>
        loadGamesToMigrateAction({
          getGamesToMigrate: apiService.getGamesToMigrate,
          saveGamesToMigrate: migrationStorage.saveGamesToMigrate,
        }),
      [apiService.getGamesToMigrate, migrationStorage.saveGamesToMigrate]
    ),
    searchGames: useCallback(
      (title: string) =>
        searchGamesAction(title, {
          saveFoundGames: migrationStorage.saveFoundGames,
          searchGames: apiService.searchGames,
          searchingFinished: migrationStorage.searchingFinished,
          startSearching: migrationStorage.startSearching,
          startAction: migrationStorage.startAction,
          saveUserGame: migrationStorage.saveUserGame,
        }),
      [migrationStorage.saveFoundGames, migrationStorage.searchingFinished, migrationStorage.startSearching, apiService.searchGames, migrationStorage.startAction, migrationStorage.saveUserGame]
    ),
    selectGameToMigrate: useCallback(
      (gtm: GameToMigrate) =>
        selectGameToMigrateAction(gtm, {
          saveFoundGames: migrationStorage.saveFoundGames,
          selectGameToMigrate: migrationStorage.selectGameToMigrate,
          startAction: migrationStorage.startAction,
          saveUserGame: migrationStorage.saveUserGame,
        }),
      [migrationStorage.saveFoundGames, migrationStorage.selectGameToMigrate, migrationStorage.startAction, migrationStorage.saveUserGame]
    ),
    deleteGameToMigrate: useCallback(
      (gtm: GameToMigrate) =>
        deleteGameToMigrateAction(gtm, {
          deleteGameToMigrate: apiService.deleteGameToMigrate,
          getGamesToMigrate: apiService.getGamesToMigrate,
          saveFoundGames: migrationStorage.saveFoundGames,
          saveGamesToMigrate: migrationStorage.saveGamesToMigrate,
          saveUserGame: migrationStorage.saveUserGame,
          startAction: migrationStorage.startAction,
          selectGameToMigrate: migrationStorage.selectGameToMigrate,
        }),
      [
        migrationStorage.saveFoundGames,
        apiService.deleteGameToMigrate,
        migrationStorage.startAction,
        migrationStorage.saveUserGame,
        apiService.getGamesToMigrate,
        migrationStorage.saveGamesToMigrate,
        migrationStorage.selectGameToMigrate,
      ]
    ),
    startMigration: useCallback(
      (gtm: GameToMigrate, game: Game) =>
        startMigrationAction(gtm, game, {
          gameCollections,
          gameModes,
          genres,
          platformMedia,
          playerPerspectives,
          themes,
          saveUserGame: migrationStorage.saveUserGame,
          startAction: migrationStorage.startAction,
        }),
      [gameModes, genres, playerPerspectives, themes, gameCollections, platformMedia, migrationStorage.startAction, migrationStorage.saveUserGame]
    ),
    saveMigratedGame: useCallback(
      (ug: UserGame, coverData?: Uint8Array) =>
        saveMigratedGame(ug, coverData, {
          ...migrationStorage,
          ...apiService,
          ...imageStorage,
          addUserGame,
          userGames,
        }),
      [migrationStorage, apiService, imageStorage, userGames, addUserGame]
    ),
    saveBundledGame: useCallback(
      (ug: UserGame, coverData?: Uint8Array) =>
        saveBundledGame(ug, coverData, {
          ...migrationStorage,
          ...apiService,
          ...imageStorage,
          addUserGame,
        }),
      [migrationStorage, apiService, imageStorage, addUserGame]
    ),
    cancelImportOfBundle: useCallback(() => {
      setBundle(undefined);
      setBundledGame(undefined);
      setBundledGames(undefined);
    }, [setBundle, setBundledGame, setBundledGames]),
    selectBundledGame: useCallback(
      (game: Game) =>
        selectBundledGame(game, {
          gameModes,
          genres,
          playerPerspectives,
          themes,
          setBundledGame: migrationStorage.setBundledGame,
          bundle,
        }),
      [bundle, gameModes, genres, playerPerspectives, themes, migrationStorage.setBundledGame]
    ),
  };
};
