import { Medium } from "neorak-game-lib-model";
import { ProfileStorageService } from "../../domain/ports";

type SelectMediumActionDependencies = Pick<
  ProfileStorageService,
  "selectedPlatformMedia" | "addMediumToPlatformMedia" | "removeMediumFromPlatformMedia"
>;

export const selectMediumAction = async (medium: Medium, deps: SelectMediumActionDependencies) => {
  if (deps.selectedPlatformMedia) {
    const selected = deps.selectedPlatformMedia.media.find((m) => medium._id === m._id);
    if (selected) deps.removeMediumFromPlatformMedia(medium);
    else deps.addMediumToPlatformMedia(medium);
  }
};
