import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Checkbox, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { PlatformMedia } from "../../../domain/platformMedia";
import * as Icons from "../../../lib/constants/pageIcons";
import { toKey } from "./EditPlatformDialog";

type Props = {
  value: PlatformMedia;
  selectedPlatform?: string;
  selectedValues: string[];
  onPlatformClick: (pId: string) => void;
  onMediumClick: (pId: string, mId: string) => void;
};

export const PlatformListItem: React.FC<Props> = ({ value, selectedPlatform, selectedValues, onMediumClick, onPlatformClick }) => {
  const open = selectedPlatform === value.platform._id;
  return (
    <React.Fragment key={value.platform._id}>
      <ListItemButton onClick={() => onPlatformClick(value.platform._id!)}>
        <ListItemIcon>
          <Icons.PLATFORM color={selectedValues.find((s) => s.startsWith(value.platform._id!)) ? "success" : undefined} />
        </ListItemIcon>
        <ListItemText primary={value.platform.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {value.media.map((m) => (
            <ListItem key={m._id}>
              <ListItemButton onClick={() => onMediumClick(value.platform._id!, m._id!)}>
                <ListItemIcon>
                  <Icons.MEDIUM />
                </ListItemIcon>
                <ListItemText primary={m.name} />
                <Checkbox edge="end" checked={selectedValues.includes(toKey(value.platform._id, m._id))} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
