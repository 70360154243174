import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";

interface HeaderProps {
  icon?: React.ReactElement<React.ReactNode>;
  label: string;
}

const IconWrapper = styled.div`
  margin-top: 0.2rem;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.4rem;
`;

const Title = styled(Typography)`
  margin-left: 0.4rem !important;
`;

const Header: React.FC<HeaderProps> = ({ icon, label }) => {
  return (
    <div>
      <TitleBlock>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <Title variant="h6" color="inherit" noWrap={true}>
          {label}
        </Title>
      </TitleBlock>
    </div>
  );
};

export default Header;
