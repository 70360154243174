import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { UserGame } from "neorak-game-lib-model";
import React from "react";
import { Field, Form } from "react-final-form";
import { ChangeGameName } from "../../../domain/changeGameName";
import { Themed } from "../../../lib/types";
import { FittingDialog } from "../FittingDialog";
import TextInput from "../TextInput";

const FormBody = styled.div<Themed>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`;

type Props = {
  game: UserGame;
  onUpdate: (value: ChangeGameName) => void | Promise<void>;
  onCancel: () => void;
};

export const EditGameNameDialog: React.FC<Props> = ({ game, onCancel, onUpdate }) => {
  return (
    <Form
      onSubmit={onUpdate}
      initialValues={{ gameName: game.game.name, customName: game.customName, sortName: game.sortName } as ChangeGameName}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <FittingDialog
          title="Change Game Name"
          onSubmit={handleSubmit}
          actions={
            <>
              <Button autoFocus onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary" autoFocus disabled={submitting || pristine || !valid}>
                Save
              </Button>
            </>
          }
        >
          <FormBody>
            <Field<string> name="gameName" component={TextInput} label="Original Game Name" disabled />
            <Field<string> name="customName" component={TextInput} label="Custom Game Name" />
            <Field<string> name="sortName" component={TextInput} label="Sort Name" />
          </FormBody>
        </FittingDialog>
      )}
    />
  );
};
