import { UserGame } from "neorak-game-lib-model";

export const sortRefdataByName = (a: { name: string }, b: { name: string }) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const sortUserGamesByName = (a: UserGame, b: UserGame) => {
  const nameA = (a.sortName || a.customName || a.game.name).toLowerCase();
  const nameB = (b.sortName || b.customName || b.game.name).toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};
