import { Button, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { GamePlatformMedium } from "neorak-game-lib-model";
import React, { useState } from "react";
import { PlatformMedia } from "../../../domain/platformMedia";
import { FittingDialog } from "../FittingDialog";
import { PlatformListItem } from "./PlatformListItem";

const gpmToString = (gpm: GamePlatformMedium) => toKey(gpm.platformId, gpm.mediumId);
export const toKey = (pId?: string, mId?: string) => `${pId}-${mId}`;
const toGPM = (key: string) => {
  const [pId, mId] = key.split("-");
  return { platformId: pId, mediumId: mId };
};

type Props = {
  values: GamePlatformMedium[];
  platformMedia: PlatformMedia[];
  onCancel: () => void;
  onUpdate: (selected: GamePlatformMedium[]) => void;
};

export const EditPlatformDialog: React.FC<Props> = ({ values, platformMedia, onCancel, onUpdate }) => {
  const [selected, setSelected] = useState<string[]>(values.map(gpmToString));
  const [open, setOpen] = useState<string | undefined>();
  const handleToggle = (pId?: string, mId?: string) => {
    const key = toKey(pId, mId);
    if (selected.includes(key)) setSelected(selected.filter((s) => s !== key));
    else setSelected([...selected, key]);
  };
  const favorites = platformMedia.filter((pm) => pm.favorite);
  const rest = platformMedia.filter((pm) => !pm.favorite);
  return (
    <FittingDialog
      title="Update Platform/Media"
      maxWidth="sm"
      actions={
        <>
          <Button autoFocus onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={() => onUpdate(selected.map(toGPM))} autoFocus disabled={selected.length === 0}>
            Save
          </Button>
        </>
      }
    >
      <List sx={{ width: "100%" }} component="nav" aria-labelledby="nested-list-subheader">
        <ListItem disablePadding>
          <ListItemButton disabled>
            <ListItemText primary="Favorites" />
          </ListItemButton>
        </ListItem>
        {favorites.map((pm) => (
          <PlatformListItem
            key={pm.platform._id}
            value={pm}
            selectedPlatform={open}
            selectedValues={selected}
            onPlatformClick={(pId) => setOpen((prev) => (prev === pId ? undefined : pId))}
            onMediumClick={handleToggle}
          />
        ))}
      </List>
      <List sx={{ width: "100%" }} component="nav" aria-labelledby="nested-list-subheader">
        <ListItem disablePadding>
          <ListItemButton disabled>
            <ListItemText primary="Other" />
          </ListItemButton>
        </ListItem>
        {rest.map((pm) => (
          <PlatformListItem
            key={pm.platform._id}
            value={pm}
            selectedPlatform={open}
            selectedValues={selected}
            onPlatformClick={(pId) => setOpen((prev) => (prev === pId ? undefined : pId))}
            onMediumClick={handleToggle}
          />
        ))}
      </List>
    </FittingDialog>
  );
};
