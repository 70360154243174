import { FavoritePlatform, Medium, Platform, PlatformMedium } from "neorak-game-lib-model";

export type PlatformMedia = {
  platformMedia: PlatformMedium[];
  platform: Platform;
  media: Medium[];
  favorite: boolean;
  favoritePlatform?: FavoritePlatform;
};

export const toPlatformMedia = (
  pmList: PlatformMedium[],
  fpList: FavoritePlatform[],
  platforms: Platform[],
  media: Medium[]
): PlatformMedia[] => {
  const result: Record<string, PlatformMedia> = {};

  pmList.forEach((pm) => {
    const platform = platforms.find((p) => p._id === pm.platformId);
    const medium = media.find((m) => m._id === pm.mediumId);
    if (platform && medium) {
      if (!result[pm.platformId]) {
        result[pm.platformId] = createNewPlatformMedia(platform, fpList);
      }
      result[pm.platformId] = addMedium(result[pm.platformId], medium, pm);
    }
  });

  return Object.keys(result)
    .map((key) => result[key])
    .sort((a, b) => {
      if (a.platform.name.toLocaleLowerCase() < b.platform.name.toLocaleLowerCase()) return -1;
      if (a.platform.name.toLocaleLowerCase() > b.platform.name.toLocaleLowerCase()) return 1;
      return 0;
    });
};

export const createNewPlatformMedia = (platform: Platform, fpList: FavoritePlatform[]): PlatformMedia => {
  const favoritePlatfom = fpList.find((fp) => fp.platformId === platform._id);
  return {
    media: [],
    platform,
    platformMedia: [],
    favorite: !!favoritePlatfom,
    favoritePlatform: favoritePlatfom,
  };
};

export const addMedium = (platformMedia: PlatformMedia, medium: Medium, pm?: PlatformMedium): PlatformMedia => {
  return {
    media: [...platformMedia.media, medium],
    platform: platformMedia.platform,
    platformMedia: [...platformMedia.platformMedia, ...(pm ? [pm] : [])],
    favorite: platformMedia.favorite,
    favoritePlatform: platformMedia.favoritePlatform,
  };
};

export const removeMedium = (platformMedia: PlatformMedia, medium: Medium): PlatformMedia => {
  return {
    media: [...platformMedia.media].filter((m) => m._id !== medium._id),
    platform: platformMedia.platform,
    platformMedia: [...platformMedia.platformMedia],
    favorite: platformMedia.favorite,
    favoritePlatform: platformMedia.favoritePlatform,
  };
};

export type PlatformMediaResult = {
  created: PlatformMedium[];
  deleted: PlatformMedium[];
};

export const toPlatformMedium = (pm: PlatformMedia): PlatformMediaResult => {
  const deleted: PlatformMedium[] = [];
  const created: PlatformMedium[] = [];

  const current: PlatformMedium[] = pm.media.map(
    (m) => ({ mediumId: m._id, platformId: pm.platform._id } as PlatformMedium)
  );
  current.forEach((entry) => {
    const existing = pm.platformMedia.find(
      (ex) => ex.mediumId === entry.mediumId && ex.platformId === entry.platformId
    );
    if (!existing) {
      created.push(entry);
    }
  });
  pm.platformMedia.forEach((entry) => {
    const existing = current.find(
      (edited) => edited.mediumId === entry.mediumId && edited.platformId === entry.platformId
    );
    if (!existing) {
      deleted.push(entry);
    }
  });

  return { deleted, created };
};
