import { LoadStatistic } from "neorak-game-lib-model";
import { useCallback } from "react";
import { ApiService, DataLoadingStatisticStorage, RefdataStorageService } from "../../domain/ports";
import { useApiService } from "../../services/api";
import { useImageStorage } from "../../services/db/dbAdapter";
import { useDataLoadingStatisticStorage, useProfileStorage, useRefdataStorage } from "../../services/storage";
import { loadRefdata } from "./loadRefdata";

type ThirdPartyRefdataDependencies = Pick<ApiService, "reloadGenres" | "reloadGameModes" | "reloadPlayerPerspectives" | "reloadThemes" | "reloadPlatformFamilies" | "reloadPlatforms"> &
  Pick<RefdataStorageService, "startLoadingThirdPartyRefdata" | "loadingThirdPartyRefdataSuccess">;

export const reloadThirdPartyRefdataStatisticsAction = async (deps: ThirdPartyRefdataDependencies) => {
  deps.startLoadingThirdPartyRefdata();
  const statistics: LoadStatistic[] = [];
  statistics.push(await deps.reloadGenres());
  statistics.push(await deps.reloadGameModes());
  statistics.push(await deps.reloadPlayerPerspectives());
  statistics.push(await deps.reloadPlatforms());
  statistics.push(await deps.reloadPlatformFamilies());
  statistics.push(await deps.reloadThemes());
  deps.loadingThirdPartyRefdataSuccess(statistics);
};

type RemoveThirdPartyRefdataStatisticsDependencies = Pick<RefdataStorageService, "loadingThirdPartyRefdataSuccess">;

export const removeThirdPartyRefdataStatisticsAction = async (deps: RemoveThirdPartyRefdataStatisticsDependencies) => {
  deps.loadingThirdPartyRefdataSuccess([]);
};

type ResetLoadingStatisticsDependencies = Pick<DataLoadingStatisticStorage, "resetStatistics">;

export const resetLoadingStatisticsAction = async (deps: ResetLoadingStatisticsDependencies) => {
  deps.resetStatistics();
};

export const useRefdata = () => {
  const apiService = useApiService();
  const storageService = useRefdataStorage();
  const profileStorage = useProfileStorage();
  const dataLoadingStorage = useDataLoadingStatisticStorage();
  const imageStorage = useImageStorage();

  return {
    thirdPartyStatistics: storageService.thirdPartyStatistics,
    isLoading: storageService.isLoadingThirdParty,
    genres: storageService.genres,
    themes: storageService.themes,
    playerPerspectives: storageService.playerPerspectives,
    gameModes: storageService.gameModes,
    platforms: storageService.platforms,
    platformFamilies: storageService.platformFamilies,
    refdataLoadingStats: dataLoadingStorage.refdataStatistics,
    gameLoadingStats: dataLoadingStorage.gameStatistics,
    reload: useCallback(async () => {
      await reloadThirdPartyRefdataStatisticsAction({
        loadingThirdPartyRefdataSuccess: storageService.loadingThirdPartyRefdataSuccess,
        reloadGameModes: apiService.reloadGameModes,
        reloadGenres: apiService.reloadGenres,
        reloadPlatformFamilies: apiService.reloadPlatformFamilies,
        reloadPlatforms: apiService.reloadPlatforms,
        reloadPlayerPerspectives: apiService.reloadPlayerPerspectives,
        reloadThemes: apiService.reloadThemes,
        startLoadingThirdPartyRefdata: storageService.startLoadingThirdPartyRefdata,
      });
    }, [
      storageService.startLoadingThirdPartyRefdata,
      storageService.loadingThirdPartyRefdataSuccess,
      apiService.reloadGenres,
      apiService.reloadGameModes,
      apiService.reloadThemes,
      apiService.reloadPlayerPerspectives,
      apiService.reloadPlatforms,
      apiService.reloadPlatformFamilies,
    ]),
    removeStatistics: useCallback(async () => {
      await removeThirdPartyRefdataStatisticsAction({
        loadingThirdPartyRefdataSuccess: storageService.loadingThirdPartyRefdataSuccess,
      });
    }, [storageService.loadingThirdPartyRefdataSuccess]),
    resetLoadingStatistics: useCallback(async () => {
      await resetLoadingStatisticsAction({
        resetStatistics: dataLoadingStorage.resetStatistics,
      });
    }, [dataLoadingStorage.resetStatistics]),
    loadRefdata: useCallback(async () => {
      await loadRefdata({
        addGameLoadingStatistics: dataLoadingStorage.addGameLoadingStatistics,
        addRefdataLoadingStatistics: dataLoadingStorage.addRefdataLoadingStatistics,
        deleteImages: imageStorage.deleteImages,
        getFavoritePlatforms: apiService.getFavoritePlatforms,
        getGameCollections: apiService.getGameCollections,
        getGameModes: apiService.getGameModes,
        getGenres: apiService.getGenres,
        getImageIds: imageStorage.getImageIds,
        getMedia: apiService.getMedia,
        getPlatformFamilies: apiService.getPlatformFamilies,
        getPlatformMedia: apiService.getPlatformMedia,
        getPlatforms: apiService.getPlatforms,
        getPlayerPerspectives: apiService.getPlayerPerspectives,
        getThemes: apiService.getThemes,
        getUserGames: apiService.getUserGames,
        getUserGameCover: apiService.getUserGameCover,
        loadingFavoritePlatformsSuccessful: profileStorage.loadingFavoritePlatformsSuccessful,
        loadingGameCollectionsSuccessful: profileStorage.loadingGameCollectionsSuccessful,
        loadingGameModesSuccessful: storageService.loadingGameModesSuccessful,
        loadingGenresSuccessful: storageService.loadingGenresSuccessful,
        loadingMediaSuccessful: storageService.loadingMediaSuccessful,
        loadingPlatformFamiliesSuccessful: storageService.loadingPlatformFamiliesSuccessful,
        loadingPlatformMediaSuccessful: profileStorage.loadingPlatformMediaSuccessful,
        loadingPlatformsSuccessful: storageService.loadingPlatformsSuccessful,
        loadingPlayerPerspectivesSuccessful: storageService.loadingPlayerPerspectivesSuccessful,
        loadingThemesSuccessful: storageService.loadingThemesSuccessful,
        loadingUserGamesSuccessful: profileStorage.loadingUserGamesSuccessful,
        startLoadingFavoritePlatforms: profileStorage.startLoadingFavoritePlatforms,
        startLoadingGameCollections: profileStorage.startLoadingGameCollections,
        startLoadingGameModes: storageService.startLoadingGameModes,
        startLoadingGenres: storageService.startLoadingGenres,
        startLoadingMedia: storageService.startLoadingMedia,
        startLoadingPlatformFamilies: storageService.startLoadingPlatformFamilies,
        startLoadingPlatformMedia: profileStorage.startLoadingPlatformMedia,
        startLoadingPlatforms: storageService.startLoadingPlatforms,
        startLoadingPlayerPerspectives: storageService.startLoadingPlayerPerspectives,
        startLoadingThemes: storageService.startLoadingThemes,
        startLoadingUserGames: profileStorage.startLoadingUserGames,
        updateRefdataLoadingStatistic: dataLoadingStorage.updateRefdataLoadingStatistic,
        updateGameLoadingStatistic: dataLoadingStorage.updateGameLoadingStatistic,
        updateImages: imageStorage.updateImages,
        validateImageIds: imageStorage.validateImageIds,
      });
    }, [
      apiService.getFavoritePlatforms,
      apiService.getGameModes,
      apiService.getGameCollections,
      apiService.getGenres,
      apiService.getUserGames,
      apiService.getUserGameCover,
      apiService.getMedia,
      apiService.getPlatformFamilies,
      apiService.getPlatformMedia,
      apiService.getPlatforms,
      apiService.getPlayerPerspectives,
      apiService.getThemes,
      dataLoadingStorage.addRefdataLoadingStatistics,
      dataLoadingStorage.updateRefdataLoadingStatistic,
      dataLoadingStorage.addGameLoadingStatistics,
      dataLoadingStorage.updateGameLoadingStatistic,
      imageStorage.deleteImages,
      imageStorage.getImageIds,
      imageStorage.updateImages,
      imageStorage.validateImageIds,
      profileStorage.loadingFavoritePlatformsSuccessful,
      profileStorage.loadingGameCollectionsSuccessful,
      profileStorage.loadingPlatformMediaSuccessful,
      profileStorage.loadingUserGamesSuccessful,
      profileStorage.startLoadingFavoritePlatforms,
      profileStorage.startLoadingGameCollections,
      profileStorage.startLoadingPlatformMedia,
      profileStorage.startLoadingUserGames,
      storageService.loadingGameModesSuccessful,
      storageService.loadingGenresSuccessful,
      storageService.loadingMediaSuccessful,
      storageService.loadingPlatformFamiliesSuccessful,
      storageService.loadingPlatformsSuccessful,
      storageService.loadingPlayerPerspectivesSuccessful,
      storageService.loadingThemesSuccessful,
      storageService.startLoadingGameModes,
      storageService.startLoadingGenres,
      storageService.startLoadingMedia,
      storageService.startLoadingPlatformFamilies,
      storageService.startLoadingPlatforms,
      storageService.startLoadingPlayerPerspectives,
      storageService.startLoadingThemes,
    ]),
  };
};
