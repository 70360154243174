"use strict";
exports.__esModule = true;
var LegacyOwnership;
(function (LegacyOwnership) {
    LegacyOwnership["OWN"] = "OWN";
    LegacyOwnership["OWNED"] = "OWNED";
    LegacyOwnership["WANT_TO"] = "WANT_TO";
    LegacyOwnership["NONE"] = "NONE";
})(LegacyOwnership = exports.LegacyOwnership || (exports.LegacyOwnership = {}));
var Ownership;
(function (Ownership) {
    Ownership["OWN"] = "OWN";
    Ownership["OWNED"] = "OWNED";
    Ownership["NONE"] = "NONE";
    Ownership["ABO"] = "ABO";
})(Ownership = exports.Ownership || (exports.Ownership = {}));
