import { Game, GameToMigrate } from "neorak-game-lib-model";
import { MigrationStorage, ProfileStorageService, RefdataStorageService } from "../../domain/ports";
import { toUserGame } from "../../domain/userGame";

type StartMigrationActionDependencies = Pick<MigrationStorage, "startAction" | "saveUserGame"> &
  Pick<RefdataStorageService, "gameModes" | "themes" | "playerPerspectives" | "genres"> &
  Pick<ProfileStorageService, "gameCollections" | "platformMedia">;

export const startMigrationAction = async (gtm: GameToMigrate, game: Game, deps: StartMigrationActionDependencies) => {
  deps.startAction("Edit");
  const userGame = toUserGame(gtm, game, {
    gameModes: deps.gameModes,
    genres: deps.genres,
    playerPerspectives: deps.playerPerspectives,
    themes: deps.themes,
    gameCollections: deps.gameCollections,
    platformMedia: deps.platformMedia,
  });
  deps.saveUserGame(userGame);
};
