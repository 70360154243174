import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import { List } from "@mui/material";
import React, { useState } from "react";
import { useGameCollection } from "../../application/gameCollection/useGameCollection";
import { usePlatformMedia } from "../../application/platformMedia/usePlatformMedia";
import * as Icon from "../../lib/constants/pageIcons";
import { DataListItem } from "../shared/DataListItem";
import { FittingDialog } from "../shared/FittingDialog";
import { GameCollectionDialog } from "./GameCollectionDialog";
import { PlatformMediaDialog } from "./PlatformMediaDialog";

type Content = "PlatformMedium" | "GameCollection";

type Props = {
  onClose: () => void;
};

export const ProfileDialog: React.FC<Props> = ({ onClose }) => {
  const {
    media,
    platformMedia,
    platforms,
    selected,
    save,
    selectMedium,
    create,
    edit,
    endMaintain,
    load,
    deletePlatform,
    toggleFavortiePlatform,
  } = usePlatformMedia();
  const {
    action: collectionAction,
    collections,
    selected: selectedCollection,
    create: createCollection,
    deleteGameCollection,
    edit: editCollection,
    save: saveCollection,
    endMaintain: endMaintainCollection,
  } = useGameCollection();
  const [show, setShow] = useState<Content | undefined>(undefined);
  return (
    <>
      <FittingDialog maxWidth="xs" title="Profile" titleAction={onClose} icon={<AccountCircle />}>
        <List sx={{ pt: 0 }}>
          <DataListItem
            icon={<PersonIcon />}
            label="Username"
            secondaryLabel="<SHOW USERNAME HERE>"
            onClick={onClose}
            disabled
          />
          <DataListItem
            icon={<AlternateEmailIcon />}
            label="Email"
            secondaryLabel="<SHOW EMAIL HERE>"
            onClick={onClose}
            disabled
          />
          <DataListItem icon={<PasswordIcon />} label="Change Password" onClick={onClose} disabled />
          <DataListItem
            icon={<Icon.PLATFORM_MEDIA />}
            label="Maintain Platform/Media"
            onClick={() => setShow("PlatformMedium")}
          />
          <DataListItem
            icon={<Icon.GAME_COLLECTION />}
            label="Game Collection"
            onClick={() => setShow("GameCollection")}
          />
        </List>
      </FittingDialog>
      {show === "PlatformMedium" && (
        <PlatformMediaDialog
          values={platformMedia}
          platforms={platforms}
          media={media}
          selected={selected}
          onClose={() => setShow(undefined)}
          onDelete={async (pm) => {
            await deletePlatform(pm);
            await load();
          }}
          onCreate={create}
          onEdit={edit}
          onMediumSelect={selectMedium}
          onPlatformMediaDeselect={endMaintain}
          onPlatformFavorite={toggleFavortiePlatform}
          onUpdate={async (pm) => {
            await save(pm);
            await load();
          }}
        />
      )}
      {show === "GameCollection" && (
        <GameCollectionDialog
          collections={collections}
          edit={collectionAction === "edit"}
          selected={selectedCollection}
          onClose={() => setShow(undefined)}
          onCreate={createCollection}
          onSave={saveCollection}
          onDelete={deleteGameCollection}
          onCancel={endMaintainCollection}
          onSelect={editCollection}
        />
      )}
    </>
  );
};
