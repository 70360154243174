import { List, ListSubheader } from "@mui/material";
import { UserGame } from "neorak-game-lib-model";
import React from "react";
import { RelatedGame } from "../Home/RelatedGame";

type Props = {
  relatedGames?: UserGame[];
  onClick: (userGame: UserGame) => void;
};

export const RelatedGames: React.FC<Props> = ({ relatedGames, onClick }) => {
  return (
    <>
      {relatedGames && relatedGames.length > 0 && (
        <List
          sx={{ pt: 0 }}
          style={{ width: "100%" }}
          subheader={
            <ListSubheader disableSticky style={{ backgroundColor: "transparent" }}>
              Related
            </ListSubheader>
          }
        >
          {relatedGames.map((game) => (
            <RelatedGame key={game._id} game={game} onClick={() => onClick(game)} />
          ))}
        </List>
      )}
    </>
  );
};
