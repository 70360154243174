import { Button, Grid, Typography } from "@mui/material";
import { UserGame } from "neorak-game-lib-model";
import React, { useMemo, useState } from "react";
import { getImageUrl } from "../../../lib/image";
import { FittingDialog } from "../FittingDialog";
import { CoverUpload } from "./CoverUpload";
import { ThirdPartyCover } from "./ThirdPartyCover";

type Props = {
  coverUrl: string;
  game: UserGame;
  onCancel: () => void;
  onUpdate: (data: Uint8Array) => void;
};

export const EditCoverDialog: React.FC<Props> = ({ coverUrl, game, onCancel, onUpdate }) => {
  const [coverFile, setCoverFile] = useState<Uint8Array | undefined>();
  const newCoverUrl = useMemo(() => getImageUrl(coverFile), [coverFile]);
  return (
    <FittingDialog
      title="Change Cover"
      actions={
        <>
          <Button autoFocus onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={() => coverFile && onUpdate(coverFile)} autoFocus disabled={!coverFile}>
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Curerent Cover & Upload new Cover:</Typography>
          <CoverUpload game={game} url={newCoverUrl || coverUrl} onChange={setCoverFile} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Use IGDB Cover:</Typography>
          <ThirdPartyCover game={game} onClick={setCoverFile} />
        </Grid>
      </Grid>
    </FittingDialog>
  );
};
