import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from "@mui/material";
import { useState } from "react";
import { DataListItem } from "../DataListItem";

const Root = styled(Accordion)`
  width: 100%;
`;

type Props<T> = {
  icon: React.ReactNode;
  title: string;
  value?: T;
  values: T[];
  getIcon?: (value: T) => React.ReactNode;
  getId: (value: T) => string;
  getLabel: (value: T) => string;
  getSecondaryLabel?: (value: T) => string | undefined;
  isEqual?: (a?: T, b?: T) => boolean;
  onClick: (value?: T) => void;
};

export const FilterBlock = <T,>({ icon, title, value, values, onClick, getId, getLabel, getSecondaryLabel, getIcon, isEqual = (a, b) => a === b }: Props<T>) => {
  const [expanded, setExpanded] = useState<boolean>(!!value);

  return (
    <Root expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography sx={{ width: "55%", flexShrink: 0 }}>{title}</Typography>
        {value && <Typography sx={{ color: "text.secondary" }}>Active</Typography>}
      </AccordionSummary>
      <AccordionDetails>
        <List sx={{ pt: 0 }}>
          {values.map((v) => (
            <DataListItem
              icon={getIcon ? getIcon(v) : icon}
              key={getId(v)}
              label={getLabel(v)}
              secondaryLabel={getSecondaryLabel ? getSecondaryLabel(v) : undefined}
              onClick={() => onClick(isEqual(value, v) ? undefined : v)}
              selected={isEqual(value, v)}
            />
          ))}
        </List>
      </AccordionDetails>
    </Root>
  );
};
