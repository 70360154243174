import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { Game, GameMode, Genre, Platform, PlayerPerspective, Theme } from "neorak-game-lib-model";
import React from "react";
import { formatCategory } from "../../lib/format";

type Props = {
  game: Game;
  genres: Genre[];
  platforms: Platform[];
  gameModes: GameMode[];
  playerPerspectives: PlayerPerspective[];
  themes: Theme[];
  onClick: () => void;
};

export const GameSearchResultItem: React.FC<Props> = ({
  game,
  genres,
  platforms,
  gameModes,
  playerPerspectives,
  themes,
  onClick,
}) => {
  //TODO: Cover aspect ratio: 3:4
  return (
    <Card key={game.thirdPartyId}>
      <CardActionArea
        onClick={onClick}
        sx={{ display: "flex", width: "100%", overflow: "unset", cursor: "pointer", alignItems: "start" }}
      >
        {game.cover && (
          <CardMedia
            component="img"
            sx={{ width: 180, objectFit: "contain" }}
            image={`https://images.igdb.com/igdb/image/upload/t_cover_big_2x/${game.cover}.jpg`}
            alt={`${game.name} cover`}
          />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <CardContent sx={{ flex: "1 0 auto", padding: "2px 16px" }}>
            <Typography component="div" variant="h5">
              {game.name}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {game.releaseYear} ({formatCategory(game.category)}) {game.status ? `| ${game.status}` : ""}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {game.platforms &&
                game.platforms
                  .map((pid) => platforms.find((p) => p.thirdPartyId === pid)?.name || "-")
                  .sort()
                  .join(", ")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {game.franchises && game.franchises.join(", ")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {game.genres &&
                game.genres
                  .map((gid) => genres.find((gr) => gr.thirdPartyId === gid)?.name || "-")
                  .sort()
                  .join(", ")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {game.gameModes &&
                game.gameModes
                  .map((gid) => gameModes.find((gm) => gm.thirdPartyId === gid)?.name || "-")
                  .sort()
                  .join(", ")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {game.themes &&
                game.themes
                  .map((tid) => themes.find((t) => t.thirdPartyId === tid)?.name || "-")
                  .sort()
                  .join(", ")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {game.playerPerspectives &&
                game.playerPerspectives
                  .map((ppid) => playerPerspectives.find((pp) => pp.thirdPartyId === ppid)?.name || "-")
                  .sort()
                  .join(", ")}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};
