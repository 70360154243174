import { Medium } from "neorak-game-lib-model";
import { useCallback } from "react";
import { ApiService, RefdataStorageService } from "../domain/ports";
import { sortRefdataByName } from "../lib/sort";
import { useApiService } from "../services/api";
import { useRefdataStorage } from "../services/storage";

type UpdateMediumDependencies = Pick<ApiService, "updateMedium" | "getMedia"> &
  Pick<RefdataStorageService, "startLoadingMedia" | "loadingMediaSuccessful" | "selectMedium">;

export const updateMediumAction = async (medium: Medium, deps: UpdateMediumDependencies) => {
  deps.startLoadingMedia();
  await deps.updateMedium(medium);
  const media = await deps.getMedia();
  deps.loadingMediaSuccessful(media.sort(sortRefdataByName));
  deps.selectMedium(undefined);
};

type CreateMediumDependencies = Pick<ApiService, "createMedium" | "getMedia"> &
  Pick<RefdataStorageService, "startLoadingMedia" | "loadingMediaSuccessful" | "selectMedium">;

export const createMediumAction = async (medium: Medium, deps: CreateMediumDependencies) => {
  deps.startLoadingMedia();
  await deps.createMedium(medium);
  const media = await deps.getMedia();
  deps.loadingMediaSuccessful(media.sort(sortRefdataByName));
  deps.selectMedium(undefined);
};

type DeleteMediumDependencies = Pick<ApiService, "deleteMedium" | "getMedia"> &
  Pick<RefdataStorageService, "startLoadingMedia" | "loadingMediaSuccessful" | "selectMedium">;

export const deleteMediumAction = async (medium: Medium, deps: DeleteMediumDependencies) => {
  deps.startLoadingMedia();
  await deps.deleteMedium(medium);
  const media = await deps.getMedia();
  deps.loadingMediaSuccessful(media.sort(sortRefdataByName));
  deps.selectMedium(undefined);
};

type SelectMediumDependencies = Pick<RefdataStorageService, "selectMedium">;

const selectMediumAction = async (medium: Medium | undefined, deps: SelectMediumDependencies) => {
  deps.selectMedium(medium);
};

export const useMedium = () => {
  const apiService = useApiService();
  const storageService = useRefdataStorage();

  return {
    media: storageService.media,
    selectedMedium: storageService.selectedMedium,
    createMedium: useCallback(
      async (medium: Medium) =>
        await createMediumAction(medium, {
          getMedia: apiService.getMedia,
          createMedium: apiService.createMedium,
          loadingMediaSuccessful: storageService.loadingMediaSuccessful,
          startLoadingMedia: storageService.startLoadingMedia,
          selectMedium: storageService.selectMedium,
        }),
      [
        apiService.getMedia,
        apiService.createMedium,
        storageService.loadingMediaSuccessful,
        storageService.startLoadingMedia,
        storageService.selectMedium,
      ]
    ),
    updateMedium: useCallback(
      async (medium: Medium) =>
        await updateMediumAction(medium, {
          getMedia: apiService.getMedia,
          updateMedium: apiService.updateMedium,
          loadingMediaSuccessful: storageService.loadingMediaSuccessful,
          startLoadingMedia: storageService.startLoadingMedia,
          selectMedium: storageService.selectMedium,
        }),
      [
        apiService.getMedia,
        apiService.updateMedium,
        storageService.loadingMediaSuccessful,
        storageService.startLoadingMedia,
        storageService.selectMedium,
      ]
    ),
    deleteMedium: useCallback(
      async (medium: Medium) =>
        await deleteMediumAction(medium, {
          getMedia: apiService.getMedia,
          deleteMedium: apiService.deleteMedium,
          loadingMediaSuccessful: storageService.loadingMediaSuccessful,
          startLoadingMedia: storageService.startLoadingMedia,
          selectMedium: storageService.selectMedium,
        }),
      [
        apiService.getMedia,
        apiService.deleteMedium,
        storageService.loadingMediaSuccessful,
        storageService.startLoadingMedia,
        storageService.selectMedium,
      ]
    ),
    selectMedium: useCallback(
      async (medium: Medium | undefined) => {
        await selectMediumAction(medium, { selectMedium: storageService.selectMedium });
      },
      [storageService.selectMedium]
    ),
  };
};
