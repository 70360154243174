import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Medium } from "neorak-game-lib-model";
import { addMedium, PlatformMedia, removeMedium } from "../../../domain/platformMedia";
import { MaintainAction } from "../../../lib/types";
import { AppRootState } from "./store";

export type PlatformMediaState = {
  platformMedia: PlatformMedia[];
  selectedPlatformMedia?: PlatformMedia;
  isLoadingPlatformMedia: boolean;
  maintainAction?: MaintainAction;
};

const initialState: PlatformMediaState = {
  isLoadingPlatformMedia: false,
  platformMedia: [],
};

const platformMediaSlice = createSlice({
  name: "platformMedia",
  initialState,
  reducers: {
    starLoadingPlatformMedia: (state: PlatformMediaState) => {
      state.isLoadingPlatformMedia = true;
    },
    loadingPlatformMediaSuccessful: (state: PlatformMediaState, action: PayloadAction<PlatformMedia[]>) => {
      state.isLoadingPlatformMedia = false;
      state.platformMedia = action.payload;
      state.selectedPlatformMedia = undefined;
      state.maintainAction = undefined;
    },
    addMediumToPlatformMedia: (state: PlatformMediaState, action: PayloadAction<Medium>) => {
      if (state.selectedPlatformMedia) {
        state.selectedPlatformMedia = addMedium(state.selectedPlatformMedia, action.payload);
      }
    },
    createPlatformMedia: (state: PlatformMediaState, action: PayloadAction<PlatformMedia>) => {
      state.selectedPlatformMedia = action.payload;
      state.maintainAction = "create";
    },
    editPlatformMedia: (state: PlatformMediaState, action: PayloadAction<PlatformMedia>) => {
      state.selectedPlatformMedia = action.payload;
      state.maintainAction = "edit";
    },
    endMaintainPlatformMedia: (state: PlatformMediaState) => {
      state.selectedPlatformMedia = undefined;
      state.maintainAction = undefined;
    },
    removeMediumFromPlatformMedia: (state: PlatformMediaState, action: PayloadAction<Medium>) => {
      if (state.selectedPlatformMedia) {
        state.selectedPlatformMedia = removeMedium(state.selectedPlatformMedia, action.payload);
      }
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const platformMediaSelector = (state: AppRootState): PlatformMediaState => state.profile.platformMedia;

export const {
  addMediumToPlatformMedia,
  loadingPlatformMediaSuccessful,
  removeMediumFromPlatformMedia,
  starLoadingPlatformMedia,
  createPlatformMedia,
  editPlatformMedia,
  endMaintainPlatformMedia,
  reset,
} = platformMediaSlice.actions;

export default platformMediaSlice.reducer;
