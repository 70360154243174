import { LoadStatistic, Theme } from "neorak-game-lib-model";
import { getJson } from "../../lib/http";

const API_PATH = "/api/v2/theme";

export const reloadThemes = async (token?: string): Promise<LoadStatistic> => {
  const result = await getJson(`${API_PATH}/load`, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to reload themes");
};

export const getThemes = async (token?: string): Promise<Theme[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get themes");
};
