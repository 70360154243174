import { Game, UserGame } from "neorak-game-lib-model";
import { useCallback } from "react";
import { useApiService } from "../../services/api";
import { useImageStorage } from "../../services/db/dbAdapter";
import { useAddGameStorage, useProfileStorage, useRefdataStorage } from "../../services/storage";
import { editNewGameAction } from "./editNewGameAction";
import { saveBundledGame } from "./saveBundledGame";
import { saveNewGame } from "./saveNewGame";
import { searchGamesAction } from "./searchGamesAction";
import { selectBundledGame } from "./selectBundledGame";

export const useAddGame = (finish: () => void) => {
  const { searchGames, createUserGame, getUserGameCoverUrl } = useApiService();
  const { updateImages } = useImageStorage();
  const { gameCollections, platformMedia, userGames, addUserGame } = useProfileStorage();
  const {
    addAction,
    bundle,
    bundledGame,
    bundledGames,
    foundGames,
    isSearching,
    userGame,
    saveFoundGames,
    saveUserGame,
    searchingFinished,
    setBundle,
    setBundledGame,
    setBundledGames,
    startAction,
    startSearching,
  } = useAddGameStorage();
  const { genres, platforms, gameModes, media, playerPerspectives, themes } = useRefdataStorage();

  return {
    addAction,
    bundle,
    bundledGame,
    bundledGames,
    foundGames,
    gameCollections,
    gameModes,
    genres,
    isSearching,
    media,
    platformMedia,
    platforms,
    playerPerspectives,
    themes,
    userGame,
    editNewGame: useCallback(
      (game: Game) =>
        editNewGameAction(game, {
          gameModes,
          genres,
          playerPerspectives,
          themes,
          saveUserGame,
          startAction,
        }),
      [gameModes, genres, playerPerspectives, themes, startAction, saveUserGame]
    ),
    saveNewGame: useCallback(
      (ug: UserGame, coverData?: Uint8Array) =>
        saveNewGame(ug, coverData, finish, {
          createUserGame,
          saveFoundGames,
          saveUserGame,
          searchGames,
          setBundle,
          setBundledGame,
          setBundledGames,
          startAction,
          updateImages,
          addUserGame,
          userGames,
        }),
      [createUserGame, finish, saveFoundGames, saveUserGame, searchGames, setBundle, setBundledGame, setBundledGames, startAction, updateImages, addUserGame, userGames]
    ),
    saveBundledGame: useCallback(
      (ug: UserGame, coverData?: Uint8Array) =>
        saveBundledGame(ug, coverData, finish, {
          addUserGame,
          createUserGame,
          saveFoundGames,
          saveUserGame,
          setBundle,
          setBundledGame,
          setBundledGames,
          startAction,
          updateImages,
          bundle,
          bundledGames,
        }),
      [addUserGame, createUserGame, finish, saveFoundGames, saveUserGame, setBundle, setBundledGame, setBundledGames, startAction, updateImages, bundle, bundledGames]
    ),
    searchGames: useCallback(
      (title: string) =>
        searchGamesAction(title, {
          saveFoundGames: saveFoundGames,
          searchGames: searchGames,
          searchingFinished: searchingFinished,
          startSearching: startSearching,
          startAction: startAction,
        }),
      [saveFoundGames, searchingFinished, startSearching, searchGames, startAction]
    ),
    selectBundledGame: useCallback(
      (game: Game) =>
        selectBundledGame(game, {
          bundle,
          gameModes,
          genres,
          playerPerspectives,
          setBundledGame,
          startAction,
          themes,
        }),
      [bundle, gameModes, genres, playerPerspectives, setBundledGame, startAction, themes]
    ),
    getUserGameCoverUrl,
    startAction,
  };
};
