import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";

type Props = {
  letter: string;
  primary: string;
  secondary?: string | number;
};

export const GtmDetailsItem: React.FC<Props> = ({ letter, primary, secondary }) => {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>{letter}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
