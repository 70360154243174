import { combineReducers } from "redux";
import gameModeReducer from "./gameModeReducer";
import genreReducer from "./genreReducer";
import mediumReducer from "./mediumReducer";
import platformFamilyReducer from "./platformFamilyReducer";
import platformReducer from "./platformReducer";
import playerPerspectiveReducer from "./playerPerspectiveReducer";
import themeReducer from "./themeReducer";
import thirdPartyRefdataStatisticReducer from "./thirdPartyRefdataStatistic";

export const refdatatReducer = combineReducers({
  genre: genreReducer,
  theme: themeReducer,
  playerPerspective: playerPerspectiveReducer,
  gameMode: gameModeReducer,
  thirdPartyRefdataStatistic: thirdPartyRefdataStatisticReducer,
  medium: mediumReducer,
  platform: platformReducer,
  platformFamily: platformFamilyReducer,
});
