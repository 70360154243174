import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { ApiService, DataLoadingStatisticStorage, ProfileStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

export const loadGameCollections = async (
  deps: Pick<ApiService, "getGameCollections"> &
    Pick<ProfileStorageService, "loadingGameCollectionsSuccessful" | "startLoadingGameCollections"> &
    Pick<DataLoadingStatisticStorage, "addRefdataLoadingStatistics" | "updateRefdataLoadingStatistic">
) => {
  const stat: DataloadingStatistic = { id: "gameCollection", name: "Game Collection", status: "running" };
  try {
    deps.startLoadingGameCollections();
    deps.addRefdataLoadingStatistics(stat);
    const collections = await deps.getGameCollections();
    deps.loadingGameCollectionsSuccessful(collections ? collections.sort(sortRefdataByName) : []);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "finished" });
    return collections;
  } catch (e) {
    console.log(e);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "failed" });
    return [];
  }
};
