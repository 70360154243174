import { Medium } from "neorak-game-lib-model";
import { deleteJson, getJson, postJson, putJson } from "../../lib/http";

const API_PATH = "/api/v2/medium";

export const getMedia = async (token?: string): Promise<Medium[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get genres");
};

export const createMedium = async (medium: Medium, token?: string): Promise<void> => {
  await postJson(API_PATH, medium, { token });
};

export const updateMedium = async (medium: Medium, token?: string): Promise<void> => {
  await putJson(`${API_PATH}/${medium._id}`, medium, { token });
};

export const deleteMedium = async (medium: Medium, token?: string): Promise<void> => {
  await deleteJson(`${API_PATH}/${medium._id}`, { token });
};
