import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlatformFamily } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type PlatformFamilyState = {
  platformFamilies: PlatformFamily[];
  selectedPlatformFamily?: PlatformFamily;
  isLoadingGenre: boolean;
};

const initialState: PlatformFamilyState = {
  isLoadingGenre: false,
  platformFamilies: [],
};

const platformFamilySlice = createSlice({
  name: "platformFamily",
  initialState,
  reducers: {
    starLoadingPlaformFamilies: (state: PlatformFamilyState) => {
      state.isLoadingGenre = true;
    },
    loadingPlatformFamiliesSuccessful: (state: PlatformFamilyState, action: PayloadAction<PlatformFamily[]>) => {
      state.isLoadingGenre = false;
      state.platformFamilies = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const platformFamilySelector = (state: AppRootState): PlatformFamilyState => state.refdata.platformFamily;

export const { loadingPlatformFamiliesSuccessful, starLoadingPlaformFamilies, reset } = platformFamilySlice.actions;

export default platformFamilySlice.reducer;
