import { UserGame } from "neorak-game-lib-model";
import { AddGameStorage, ApiService, ImageStorageService, ProfileStorageService } from "../../domain/ports";
import { createUserGame } from "../createUserGame";

export type SaveMigratedGameDependencies = Pick<AddGameStorage, "saveFoundGames" | "bundle" | "bundledGames" | "saveUserGame" | "setBundle" | "setBundledGame" | "setBundledGames" | "startAction"> &
  Pick<ApiService, "createUserGame"> &
  Pick<ProfileStorageService, "addUserGame"> &
  Pick<ImageStorageService, "updateImages">;

export const saveBundledGame = async (userGame: UserGame, coverData: Uint8Array | undefined, finish: () => void, deps: SaveMigratedGameDependencies) => {
  await createUserGame(userGame, coverData, deps);
  deps.setBundledGame(undefined);
  const bundledGames = deps.bundledGames?.filter((g) => g.thirdPartyId !== userGame.game.thirdPartyId) || [];
  deps.setBundledGames(bundledGames);
  if (deps.bundle && bundledGames.length === 0) {
    deps.startAction("Search");
    await createUserGame(deps.bundle.userGame, deps.bundle.coverData, deps);
    deps.setBundledGames(undefined);
    deps.setBundle(undefined);
    deps.saveFoundGames([]);
    deps.saveUserGame(undefined);
    finish();
  } else {
    deps.startAction("ShowBundledGames");
  }
};
