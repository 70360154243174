import { Button } from "@mui/material";
import React from "react";
import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { FittingDialog } from "../shared/FittingDialog";
import { GameLoadingStatistic } from "./GameLoadingStatistic";
import { RefdataLoadingStatistic } from "./RefdataLoadingStatistic";

type Props = {
  refdataStatistics: DataloadingStatistic[];
  gameStatistics: DataloadingStatistic[];
  onClose: () => void;
};

export const RefdataLoadingStatisticDialog: React.FC<Props> = ({ refdataStatistics, gameStatistics, onClose }) => (
  <>
    <FittingDialog
      title="Loading Data Statistic"
      maxWidth="xs"
      actions={
        <>
          <Button onClick={onClose}>Close</Button>
        </>
      }
    >
      <RefdataLoadingStatistic refdataStatistics={refdataStatistics} />
      <GameLoadingStatistic gameStatistics={gameStatistics} />
    </FittingDialog>
  </>
);
