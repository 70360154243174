import { FavoritePlatform } from "neorak-game-lib-model";
import { deleteJson, getJson, postJson } from "../../lib/http";

const API_PATH = "/api/v2/favoritePlatform";

export const getFavoritePlatforms = async (token?: string): Promise<FavoritePlatform[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get favorite platforms");
};

export const createFavoritePlatform = async (fp: FavoritePlatform, token?: string): Promise<void> => {
  await postJson(API_PATH, fp, { token });
};

export const deleteFavoritePlatform = async (fp: FavoritePlatform, token?: string): Promise<void> => {
  await deleteJson(`${API_PATH}/${fp._id}`, { token });
};
