import { Typography } from "@mui/material";
import { Game, GameCollection, GameMode, GameToMigrate, Genre, Medium, Platform, PlayerPerspective, Theme, UserGame } from "neorak-game-lib-model";
import React from "react";
import { PlatformMedia } from "../../domain/platformMedia";
import { EditUserGame } from "../shared/EditUserGame/EditUserGame";
import { GameSearch } from "./GameSearch";
import { SourceListColumn } from "./SourceListColumn";

type SearchEditColumnProps = {
  coverUrl: string;
  foundGames: Game[];
  gameCollections: GameCollection[];
  gameModes: GameMode[];
  genres: Genre[];
  media: Medium[];
  platforms: Platform[];
  playerPerspectives: PlayerPerspective[];
  platformMedia: PlatformMedia[];
  searching: boolean;
  selectedGameToMigrate: GameToMigrate;
  themes: Theme[];
  userGame?: UserGame;

  onSave: (userGame: UserGame, coverData?: Uint8Array) => void;
  onSearch: (title: string) => void;
  onSearchSelect: (selectedGameToMigrate: GameToMigrate, game: Game) => void;
};
export const SearchEditColumn: React.FC<SearchEditColumnProps> = ({
  coverUrl,
  foundGames,
  userGame,
  gameCollections,
  gameModes,
  genres,
  media,
  platforms,
  playerPerspectives,
  platformMedia,
  searching,
  selectedGameToMigrate,
  themes,
  onSave,
  onSearch,
  onSearchSelect,
}) => {
  if (userGame) {
    return (
      <SourceListColumn>
        <EditUserGame
          key={userGame.game.thirdPartyId}
          coverUrl={coverUrl}
          game={userGame}
          gameCollections={gameCollections}
          gameModes={gameModes}
          genres={genres}
          media={media}
          platformMedia={platformMedia}
          platforms={platforms}
          playerPerspectives={playerPerspectives}
          themes={themes}
          onSave={onSave}
        />
      </SourceListColumn>
    );
  }
  return (
    <SourceListColumn>
      <Typography variant="h4">Search Source Game</Typography>
      <GameSearch
        gameModes={gameModes}
        gameTitle={selectedGameToMigrate.title}
        games={foundGames}
        genres={genres}
        platforms={platforms}
        playerPerspectives={playerPerspectives}
        searching={searching}
        themes={themes}
        onClick={(game) => onSearchSelect(selectedGameToMigrate, game)}
        onSearch={onSearch}
      />
    </SourceListColumn>
  );
};
