import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FavoritePlatform } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type FavoritePlatformState = {
  favoritePlatforms: FavoritePlatform[];
  isLoadingFavoritePlatforms: boolean;
};

const initialState: FavoritePlatformState = {
  isLoadingFavoritePlatforms: false,
  favoritePlatforms: [],
};

const favoritePlatformSlice = createSlice({
  name: "favoritePlatform",
  initialState,
  reducers: {
    starLoadingFavoritePlatforms: (state: FavoritePlatformState) => {
      state.isLoadingFavoritePlatforms = true;
    },
    loadingFavoritePlatformsSuccessful: (state: FavoritePlatformState, action: PayloadAction<FavoritePlatform[]>) => {
      state.isLoadingFavoritePlatforms = false;
      state.favoritePlatforms = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const favoritePlatformSelector = (state: AppRootState): FavoritePlatformState => state.profile.favoritePlatform;

export const { loadingFavoritePlatformsSuccessful, reset, starLoadingFavoritePlatforms } =
  favoritePlatformSlice.actions;

export default favoritePlatformSlice.reducer;
