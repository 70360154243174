import { GameToMigrate } from "neorak-game-lib-model";
import { sortGamesToMigrate } from "../../domain/gameToMigrate";
import { ApiService, MigrationStorage } from "../../domain/ports";

export type DeleteGameToMigrateDependencies = Pick<MigrationStorage, "saveFoundGames" | "startAction" | "saveGamesToMigrate" | "saveUserGame" | "selectGameToMigrate"> &
  Pick<ApiService, "deleteGameToMigrate" | "getGamesToMigrate">;

export const deleteGameToMigrateAction = async (game: GameToMigrate, deps: DeleteGameToMigrateDependencies) => {
  await deps.deleteGameToMigrate(game);
  deps.saveFoundGames([]);
  deps.startAction();
  deps.saveUserGame(undefined);
  const games = await deps.getGamesToMigrate();
  deps.saveGamesToMigrate(games ? games.sort(sortGamesToMigrate) : []);
  deps.selectGameToMigrate(undefined);
};
