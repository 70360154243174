import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GameMode } from "neorak-game-lib-model";
import { AppRootState } from "./store";

export type GameModeState = {
  gameModes: GameMode[];
  selectedGameMode?: GameMode;
  isLoadingGameMode: boolean;
};

const initialState: GameModeState = {
  isLoadingGameMode: false,
  gameModes: [],
};

const gameModeSlice = createSlice({
  name: "gameMode",
  initialState,
  reducers: {
    starLoadingGameModes: (state: GameModeState) => {
      state.isLoadingGameMode = true;
    },
    loadingGameModesSuccessful: (state: GameModeState, action: PayloadAction<GameMode[]>) => {
      state.isLoadingGameMode = false;
      state.gameModes = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

// exports
export const gameModeSelector = (state: AppRootState): GameModeState => state.refdata.gameMode;

export const { loadingGameModesSuccessful, starLoadingGameModes, reset } = gameModeSlice.actions;

export default gameModeSlice.reducer;
