import { FavoritePlatform, GameCollection, GameToMigrate, Medium, PlatformMedium, UserGame, UserGameRequest } from "neorak-game-lib-model";
import { useCallback } from "react";
import { LoginCredentials } from "../../domain/loginCredentials";
import { ApiService } from "../../domain/ports";
import { ROUTE_LOGIN } from "../../lib/constants/routes";
import { useNavigationServiceImpl } from "../navigation";
import { useSystemStorage } from "../storage";
import { createFavoritePlatform, deleteFavoritePlatform, getFavoritePlatforms } from "./favoritePlatform";
import { createGameCollection, deleteGameCollection, getGameCollections, updateGameCollection } from "./gameCollection";
import { getGameModes, reloadGameModes } from "./gameModeService";
import {
  SearchGameQueryParam,
  createUserGame,
  deleteGameToMigrate,
  deleteUserGame,
  getCoverUrl,
  getGamesToMigrate,
  getUserGameCover,
  getUserGameCoverUrl,
  getUserGames,
  searchGames,
  updateUserGame,
} from "./gameService";
import { getGenres, reloadGenres } from "./genreService";
import { login } from "./loginService";
import { createMedium, deleteMedium, getMedia, updateMedium } from "./mediumService";
import { getPlatforms, reloadPlatforms } from "./platform";
import { getPlatformFamilies, reloadPlatformFamilies } from "./platformFamily";
import { createPlatformMedium, deletePlatformMedium, getPlatformMedia, updatePlatformMedium } from "./platformMedia";
import { getPlayerPerspectives, reloadPlayerPerspectives } from "./playerPerspectiveService";
import { getThemes, reloadThemes } from "./themeService";

export const useApiService = (): ApiService => {
  const { token } = useSystemStorage();
  const { navigateTo } = useNavigationServiceImpl();

  //Higher Order Function
  const withErrorHandling = useCallback(
    function withErrorHandlingFunc<T extends (...args: any[]) => any>(func: T): (...funcArgs: Parameters<T>) => ReturnType<T> {
      // Return a new function that tracks how long the original took
      return (...args: Parameters<T>): ReturnType<T> => {
        return func(...args).catch(() => {
          navigateTo(ROUTE_LOGIN);
          return undefined;
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    createFavoritePlatform: useCallback((fp: FavoritePlatform) => withErrorHandling(createFavoritePlatform)(fp, token), [token, withErrorHandling]),
    createMedium: useCallback((medium: Medium) => createMedium(medium, token), [token]),
    createPlatformMedium: useCallback((pm: PlatformMedium) => withErrorHandling(createPlatformMedium)(pm, token), [token, withErrorHandling]),
    createGameCollection: useCallback((entity: GameCollection) => withErrorHandling(createGameCollection)(entity, token), [token, withErrorHandling]),
    createUserGame: useCallback((ugr: UserGameRequest) => withErrorHandling(createUserGame)(ugr, token), [token, withErrorHandling]),
    deleteFavoritePlatform: useCallback((fp: FavoritePlatform) => withErrorHandling(deleteFavoritePlatform)(fp, token), [token, withErrorHandling]),
    deleteGameCollectino: useCallback((entity: GameCollection) => withErrorHandling(deleteGameCollection)(entity, token), [token, withErrorHandling]),
    deleteGameToMigrate: useCallback((game: GameToMigrate) => withErrorHandling(deleteGameToMigrate)(game, token), [token, withErrorHandling]),
    deleteMedium: useCallback((medium: Medium) => withErrorHandling(deleteMedium)(medium, token), [token, withErrorHandling]),
    deletePlatformMedium: useCallback((pm: PlatformMedium) => withErrorHandling(deletePlatformMedium)(pm, token), [token, withErrorHandling]),
    deleteUserGame: useCallback((game: UserGame) => withErrorHandling(deleteUserGame)(game, token), [token, withErrorHandling]),
    getCoverUrl: useCallback((game: GameToMigrate) => getCoverUrl(game, token), [token]),
    getFavoritePlatforms: useCallback(() => withErrorHandling(getFavoritePlatforms)(token), [token, withErrorHandling]),
    getGameCollections: useCallback(() => withErrorHandling(getGameCollections)(token), [token, withErrorHandling]),
    getGameModes: useCallback(() => withErrorHandling(getGameModes)(token), [token, withErrorHandling]),
    getGenres: useCallback(() => withErrorHandling(getGenres)(token), [token, withErrorHandling]),
    getMedia: useCallback(() => withErrorHandling(getMedia)(token), [token, withErrorHandling]),
    getGamesToMigrate: useCallback(() => withErrorHandling(getGamesToMigrate)(token), [token, withErrorHandling]),
    getPlatformFamilies: useCallback(() => withErrorHandling(getPlatformFamilies)(token), [token, withErrorHandling]),
    getPlatformMedia: useCallback(() => withErrorHandling(getPlatformMedia)(token), [token, withErrorHandling]),
    getPlatforms: useCallback(() => withErrorHandling(getPlatforms)(token), [token, withErrorHandling]),
    getPlayerPerspectives: useCallback(() => withErrorHandling(getPlayerPerspectives)(token), [token, withErrorHandling]),
    getThemes: useCallback(() => withErrorHandling(getThemes)(token), [token, withErrorHandling]),
    getUserGames: useCallback(() => withErrorHandling(getUserGames)(token), [token, withErrorHandling]),
    getUserGameCover: useCallback((gameId: string) => getUserGameCover(gameId, token), [token]),

    getUserGameCoverUrl: useCallback((gameId: string) => getUserGameCoverUrl(gameId, token), [token]),

    login: useCallback((credentials: LoginCredentials) => withErrorHandling(login)(credentials), [withErrorHandling]),
    reloadGameModes: useCallback(() => withErrorHandling(reloadGameModes)(token), [token, withErrorHandling]),
    reloadGenres: useCallback(() => withErrorHandling(reloadGenres)(token), [token, withErrorHandling]),
    reloadPlayerPerspectives: useCallback(() => withErrorHandling(reloadPlayerPerspectives)(token), [token, withErrorHandling]),
    reloadPlatformFamilies: useCallback(() => withErrorHandling(reloadPlatformFamilies)(token), [token, withErrorHandling]),
    reloadPlatforms: useCallback(() => withErrorHandling(reloadPlatforms)(token), [token, withErrorHandling]),
    reloadThemes: useCallback(() => withErrorHandling(reloadThemes)(token), [token, withErrorHandling]),
    searchGames: useCallback((query: SearchGameQueryParam) => withErrorHandling(searchGames)(query, token), [token, withErrorHandling]),
    updateGameCollection: useCallback((entity: GameCollection) => withErrorHandling(updateGameCollection)(entity, token), [token, withErrorHandling]),
    updateMedium: useCallback((medium: Medium) => withErrorHandling(updateMedium)(medium, token), [token, withErrorHandling]),
    updatePlatformMedium: useCallback((pm: PlatformMedium) => withErrorHandling(updatePlatformMedium)(pm, token), [token, withErrorHandling]),
    updateUserGame: useCallback((ugr: UserGameRequest) => withErrorHandling(updateUserGame)(ugr, token), [token, withErrorHandling]),
  };
};
