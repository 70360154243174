import React, { useState } from "react";
import { useMedium } from "../../application/medium";
import { useRefdata } from "../../application/refdata/useRefdata";
import * as Icon from "../../lib/constants/pageIcons";
import { LoadingIndicator } from "./LoadingIndicator";
import { MediaDialog } from "./MediaDialog";
import { RefdataListDialog } from "./RefdataListDialog";
import { RefdataOptionsDialog } from "./RefdataOptionsDialog";
import { ThirdPartyStatisticsDialog } from "./ThirdPartyStatisticsDialog";

type Content = "GameModes" | "Genres" | "Media" | "PlatformFamily" | "Platform" | "PlayerPerspectives" | "RefdataOptions" | "Spinner" | "Themes" | "ThirdPartyStatistics";

type Props = {
  onClose: () => void;
};

export const RefdataDialog: React.FC<Props> = ({ onClose }) => {
  const { genres, gameModes, playerPerspectives, themes, thirdPartyStatistics, platformFamilies, platforms, isLoading, reload, removeStatistics, loadRefdata } = useRefdata();
  const { media, selectedMedium, createMedium, updateMedium, selectMedium, deleteMedium } = useMedium();
  const [show, setShow] = useState<Content | undefined>(undefined);
  const handleCloseRefdataList = () => setShow(undefined);

  let content: Content = "RefdataOptions";
  if (isLoading) content = "Spinner";
  else if (thirdPartyStatistics.length > 0) content = "ThirdPartyStatistics";
  else if (show) content = show;

  return (
    <>
      {content === "Spinner" && <LoadingIndicator />}
      {content === "ThirdPartyStatistics" && (
        <ThirdPartyStatisticsDialog
          thirdPartyStatistics={thirdPartyStatistics}
          onClose={() => {
            removeStatistics();
            onClose();
          }}
        />
      )}
      {content === "RefdataOptions" && (
        <RefdataOptionsDialog
          counts={{
            gameModes: gameModes.length,
            genres: genres.length,
            media: media.length,
            platformFamilies: platformFamilies.length,
            platforms: platforms.length,
            playerPerspectives: playerPerspectives.length,
            themes: themes.length,
          }}
          onClose={onClose}
          onReloadClick={async () => {
            await reload();
            await loadRefdata();
          }}
          onGenreClick={() => setShow("Genres")}
          onGameModeClick={() => setShow("GameModes")}
          onPlayerPerspectiveClick={() => setShow("PlayerPerspectives")}
          onThemeClick={() => setShow("Themes")}
          onMediumClick={() => setShow("Media")}
          onPlatformClick={() => setShow("Platform")}
          onPlatformFamilyClick={() => setShow("PlatformFamily")}
        />
      )}
      {content === "Genres" && <RefdataListDialog title="Genres" icon={<Icon.GENRE />} values={genres} onClose={handleCloseRefdataList} onClick={handleCloseRefdataList} />}
      {content === "GameModes" && <RefdataListDialog title="Game Modes" icon={<Icon.GAME_MODE />} values={gameModes} onClose={handleCloseRefdataList} onClick={handleCloseRefdataList} />}
      {content === "Themes" && <RefdataListDialog title="Themes" icon={<Icon.THEME />} values={themes} onClose={handleCloseRefdataList} onClick={handleCloseRefdataList} />}
      {content === "PlayerPerspectives" && (
        <RefdataListDialog title="Player Perspectives" icon={<Icon.PLAYER_PERSPECTIVE />} values={playerPerspectives} onClose={handleCloseRefdataList} onClick={handleCloseRefdataList} />
      )}
      {content === "Media" && (
        <MediaDialog selected={selectedMedium} media={media} onClose={handleCloseRefdataList} onCreate={createMedium} onUpdate={updateMedium} onSelect={selectMedium} onDelete={deleteMedium} />
      )}
      {content === "Platform" && <RefdataListDialog title="Platform" icon={<Icon.PLATFORM />} values={platforms} onClose={handleCloseRefdataList} onClick={handleCloseRefdataList} />}
      {content === "PlatformFamily" && (
        <RefdataListDialog title="Platform Family" icon={<Icon.PLATFORM_FAMILY />} values={platformFamilies} onClose={handleCloseRefdataList} onClick={handleCloseRefdataList} />
      )}
    </>
  );
};
