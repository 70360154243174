import { Ownership } from "neorak-game-lib-model";

type GameOwnershipDetail = {
  label: string;
};

type GameStatusInfoType = { [key in Ownership]: GameOwnershipDetail };

const GameOwnershipInfo: GameStatusInfoType = {
  [Ownership.ABO]: {
    label: "Abo",
  },
  [Ownership.OWN]: {
    label: "Own",
  },
  [Ownership.OWNED]: {
    label: "Owned",
  },
  [Ownership.NONE]: {
    label: "Not Owned",
  },
};

export default GameOwnershipInfo;
