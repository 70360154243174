export const getImageUrl = (data?: Uint8Array) => (data ? URL.createObjectURL(new Blob([data], { type: IGDB_IMAGE_TYPE })) : undefined);

export const IGDB_IMAGE_TYPE = "image/jpeg";

export const bufferToBase64 = async (buffer: Uint8Array) => {
  // use a FileReader to generate a base64 data URI:
  const base64url = await new Promise<string>((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result!.toString());
    reader.readAsDataURL(new Blob([buffer]));
  });
  // remove the `data:...;base64,` part from the start
  return base64url.substring(base64url.indexOf(",") + 1);
};
