import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { ApiService, DataLoadingStatisticStorage, RefdataStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

export const loadPlatformFamilies = async (
  deps: Pick<ApiService, "getPlatformFamilies"> &
    Pick<RefdataStorageService, "loadingPlatformFamiliesSuccessful" | "startLoadingPlatformFamilies"> &
    Pick<DataLoadingStatisticStorage, "addRefdataLoadingStatistics" | "updateRefdataLoadingStatistic">
) => {
  const stat: DataloadingStatistic = { id: "platformFamily", name: "Platform Family", status: "running" };
  try {
    deps.startLoadingPlatformFamilies();
    deps.addRefdataLoadingStatistics(stat);
    const platformFamilies = await deps.getPlatformFamilies();
    deps.loadingPlatformFamiliesSuccessful(platformFamilies ? platformFamilies.sort(sortRefdataByName) : []);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "finished" });
    return platformFamilies;
  } catch (e) {
    console.log(e);
    deps.updateRefdataLoadingStatistic({ ...stat, status: "failed" });
    return [];
  }
};
