import { toPlatformMedia } from "../../domain/platformMedia";
import { ApiService, ProfileStorageService, RefdataStorageService } from "../../domain/ports";

type LoadPlatformMediaActionDependencies = Pick<ApiService, "getPlatformMedia" | "getFavoritePlatforms"> &
  Pick<RefdataStorageService, "media" | "platforms"> &
  Pick<
    ProfileStorageService,
    | "loadingPlatformMediaSuccessful"
    | "startLoadingPlatformMedia"
    | "startLoadingFavoritePlatforms"
    | "loadingFavoritePlatformsSuccessful"
  >;

export const loadPlatformMediaAction = async (deps: LoadPlatformMediaActionDependencies) => {
  deps.startLoadingPlatformMedia();
  deps.startLoadingFavoritePlatforms();
  const [pms, fps] = await Promise.all([deps.getPlatformMedia(), deps.getFavoritePlatforms()]);
  deps.loadingPlatformMediaSuccessful(toPlatformMedia(pms, fps, deps.platforms, deps.media));
  deps.loadingFavoritePlatformsSuccessful(fps);
};
