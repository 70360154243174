import styled from "@emotion/styled";
import { UserGame } from "neorak-game-lib-model";
import React from "react";
import { useInputFileImageCompressor } from "../../../lib/imagecompressor";
import CoverImage from "../CoverImage";

const IconContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const Upload = styled.input`
  position: absolute;
  top: -10;
  right: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 0%;
  opacity: 0;
  z-index: 2;
`;

type Props = {
  url: string;
  game: UserGame;
  onChange: (data: Uint8Array) => void;
};

export const CoverUpload: React.FC<Props> = ({ url, game, onChange }) => {
  const inputEl = React.useRef<HTMLInputElement>(null);
  const compress = useInputFileImageCompressor(onChange);

  return (
    <IconContainer>
      <CoverImage alt={game.game.name} src={url} onClick={() => inputEl.current?.click()} />
      <Upload key={url} type="file" accept=".jpg, .png" title={`Select Game Cover`} ref={inputEl} onChange={compress} />
    </IconContainer>
  );
};
