import { sortGamesToMigrate } from "../../domain/gameToMigrate";
import { ApiService, MigrationStorage } from "../../domain/ports";

export type LoadGamesToMigrateActionDependencies = Pick<MigrationStorage, "saveGamesToMigrate"> & Pick<ApiService, "getGamesToMigrate">;

export const loadGamesToMigrateAction = async (deps: LoadGamesToMigrateActionDependencies) => {
  const games = await deps.getGamesToMigrate();
  // if (games) {
  //   const platforms = new Set<string>();
  //   const media = new Set<string>();
  //   games.forEach((game) => {
  //     Object.keys(game.platformMedia).forEach((platform) => {
  //       platforms.add(platform);
  //       game.platformMedia[platform].forEach((medium) => {
  //         media.add(medium);
  //       });
  //     });
  //   });
  //   console.log(Array.from(platforms), Array.from(media));
  // }
  deps.saveGamesToMigrate(games ? games.sort(sortGamesToMigrate) : []);
};
