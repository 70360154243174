import { GameMode, LoadStatistic } from "neorak-game-lib-model";
import { getJson } from "../../lib/http";

const API_PATH = "/api/v2/gameMode";

export const reloadGameModes = async (token?: string): Promise<LoadStatistic> => {
  const result = await getJson(`${API_PATH}/load`, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to reload game modes");
};

export const getGameModes = async (token?: string): Promise<GameMode[]> => {
  const result = await getJson(API_PATH, { token });
  if (result.status === "SUCCESS" && result.json) {
    return result.json;
  }
  throw Error("Failed to get game modes");
};
