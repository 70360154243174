import { GameCollection } from "neorak-game-lib-model";
import { ApiService, ProfileStorageService } from "../../domain/ports";
import { sortRefdataByName } from "../../lib/sort";

type DeleteGameCollectionDependencies = Pick<ApiService, "deleteGameCollectino" | "getGameCollections"> &
  Pick<ProfileStorageService, "startLoadingGameCollections" | "loadingGameCollectionsSuccessful">;

export const deleteGameCollectionAction = async (col: GameCollection, deps: DeleteGameCollectionDependencies) => {
  deps.startLoadingGameCollections();
  await deps.deleteGameCollectino(col);
  const cols = await deps.getGameCollections();
  deps.loadingGameCollectionsSuccessful(cols.sort(sortRefdataByName));
};
