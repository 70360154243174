import EngineeringIcon from "@mui/icons-material/Engineering";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Divider, List } from "@mui/material";
import React from "react";
import * as Icon from "../../lib/constants/pageIcons";
import { RefdataCounts } from "../../lib/types";
import { DataListItem } from "../shared/DataListItem";
import { FittingDialog } from "../shared/FittingDialog";

type Props = {
  counts: RefdataCounts;
  onClose: () => void;
  onReloadClick: () => void;
  onGenreClick: () => void;
  onGameModeClick: () => void;
  onThemeClick: () => void;
  onPlayerPerspectiveClick: () => void;
  onMediumClick: () => void;
  onPlatformClick: () => void;
  onPlatformFamilyClick: () => void;
};

export const RefdataOptionsDialog: React.FC<Props> = ({
  counts,
  onClose,
  onGenreClick,
  onMediumClick,
  onPlatformClick,
  onPlatformFamilyClick,
  onReloadClick,
  onGameModeClick,
  onThemeClick,
  onPlayerPerspectiveClick,
}) => (
  <>
    <FittingDialog maxWidth="xs" title="Refdata" titleAction={onClose} icon={<EngineeringIcon />}>
      <List sx={{ pt: 0 }}>
        <DataListItem
          icon={<Icon.GENRE />}
          label="Genre"
          secondaryLabel={`${counts.genres} Items`}
          onClick={onGenreClick}
        />
        <DataListItem
          icon={<Icon.GAME_MODE />}
          label="Game Mode"
          secondaryLabel={`${counts.gameModes} Items`}
          onClick={onGameModeClick}
        />
        <DataListItem
          icon={<Icon.THEME />}
          label="Theme"
          secondaryLabel={`${counts.themes} Items`}
          onClick={onThemeClick}
        />
        <DataListItem
          icon={<Icon.PLAYER_PERSPECTIVE />}
          label="Player Perspective"
          secondaryLabel={`${counts.playerPerspectives} Items`}
          onClick={onPlayerPerspectiveClick}
        />
        <DataListItem
          icon={<Icon.MEDIUM />}
          label="Medium"
          secondaryLabel={`${counts.media} Items`}
          onClick={onMediumClick}
        />
        <DataListItem
          icon={<Icon.PLATFORM />}
          label="Platform"
          secondaryLabel={`${counts.platforms} Items`}
          onClick={onPlatformClick}
        />
        <DataListItem
          icon={<Icon.PLATFORM_FAMILY />}
          label="Platform Family"
          secondaryLabel={`${counts.platformFamilies} Items`}
          onClick={onPlatformFamilyClick}
        />
        <Divider />
        <DataListItem icon={<RefreshIcon />} label="Reload Third-Party Refdata" onClick={onReloadClick} />
      </List>
    </FittingDialog>
  </>
);
