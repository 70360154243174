import { Medium } from "neorak-game-lib-model";
import { FormValidation, ValidationError } from "../lib/types";

export const validateMedium: FormValidation<Medium> = (values: Medium) => {
  const errors: ValidationError<Medium> = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

export const createNewMedium = (): Medium => ({
  name: "",
});
