import { ApiService, ProfileStorageService } from "../../domain/ports";

type LoadGameCollectionActionDependencies = Pick<ApiService, "getGameCollections"> &
  Pick<ProfileStorageService, "loadingGameCollectionsSuccessful" | "startLoadingGameCollections">;

export const loadGameCollectionAction = async (deps: LoadGameCollectionActionDependencies) => {
  deps.startLoadingGameCollections();
  const cols = await deps.getGameCollections();
  deps.loadingGameCollectionsSuccessful(cols);
};
