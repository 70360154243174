import { useEffect, useRef, useState } from "react";

// const isElementInViewport = (el: Element | null) => {
//   if (null == el) {
//     return false;
//   }
//   var rect = el.getBoundingClientRect();

//   return (
//     rect.top >= 0 &&
//     rect.left >= 0 &&
//     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
//     rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
//   );
// };

export const useIsInView = (querySelector: string, rootMargin?: string, shouldAlwaysBeVisible?: boolean) => {
  const ref = useRef(null);
  const [inView, setInView] = useState(shouldAlwaysBeVisible ? true : false);
  useEffect(() => {
    // const visible = isElementInViewport(ref.current);
    let observer: IntersectionObserver;
    // if (visible) {
    //   setInView(true);
    // } else {
    if (!inView && ref.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const { isIntersecting } = entry;
            if (isIntersecting) {
              setInView(true);
              observer.disconnect();
            }
          });
        },
        {
          rootMargin,
        }
      );
      observer.observe(ref.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [querySelector, rootMargin, inView]);
  return {
    ref,
    inView,
  };
};
