import { Button, Checkbox, List, ListSubheader } from "@mui/material";
import { GameCollection, UserGame } from "neorak-game-lib-model";
import { useState } from "react";
import { getName } from "../../../domain/game";
import { DataListItem } from "../DataListItem";
import { FittingDialog } from "../FittingDialog";

type Props = {
  collections: GameCollection[];
  game: UserGame;
  onUpdate: (collectionIds: string[]) => void | Promise<void>;
  onCancel: () => void;
};

export const EditGameCollectionDialog: React.FC<Props> = ({ collections, game, onCancel, onUpdate }) => {
  const [selected, setSelected] = useState(game.collections);
  return (
    <FittingDialog
      maxWidth="sm"
      title={`What are your collections for '${getName(game)}'?`}
      titleAction={onCancel}
      actions={
        <>
          <Button autoFocus onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={() => onUpdate(selected)} autoFocus>
            Save
          </Button>
        </>
      }
    >
      <List sx={{ pt: 0 }} subheader={<ListSubheader style={{ backgroundColor: "transparent" }}>Collections</ListSubheader>}>
        {collections.map((collection) => (
          <DataListItem
            key={collection._id}
            label={collection.name}
            icon={<Checkbox edge="start" checked={selected.find((c) => collection._id === c) !== undefined} tabIndex={-1} disableRipple />}
            onClick={() => setSelected((cs) => (cs.includes(collection._id!) ? cs.filter((c) => c !== collection._id) : [...cs, collection._id!]))}
          />
        ))}
      </List>
    </FittingDialog>
  );
};
