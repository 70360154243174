import { GameCategory, GameCollection, GameMode, Genre, PlayerPerspective, Theme, UserGame, UserGameStatus } from "neorak-game-lib-model";
import { PlatformMedia } from "./platformMedia";

export type UserGameFilter = {
  collection?: string;
  gameCategory?: GameCategory;
  gameMode?: string;
  genre?: string;
  medium?: string;
  name?: string;
  platform?: string;
  playerPerspective?: string;
  status?: UserGameStatus;
  theme?: string;
};

export type UserGameFilterData = {
  filter: UserGameFilter;
  refdata: UserGameFilterRefdata;
  statistics: UserGameStatistics;
};

export type UserGameFilterRefdata = {
  collections: GameCollection[];
  platformMedia: PlatformMedia[];
  genres: Genre[];
  gameModes: GameMode[];
  playerPerspectives: PlayerPerspective[];
  themes: Theme[];
};

export type UserGameStatistics = {
  gameCount: UserGameCount;
};

export type UserGameCount = {
  [key in GameCategory]: number;
};

const isFilteredByName = (g: UserGame, name?: string): boolean => {
  if (name) {
    const filteredName = name.toLowerCase();
    return (g.customName && g.customName.toLowerCase().includes(filteredName)) || g.game.name.toLowerCase().includes(filteredName);
  }
  return true;
};

const isFilteredByPlatformAndMedium = (game: UserGame, platform?: string, medium?: string): boolean => {
  if (platform) {
    if (game.platformMedia) {
      if (medium) {
        return !!game.platformMedia.find((platformMedium) => platformMedium.platformId === platform && platformMedium.mediumId === medium);
      } else {
        return !!game.platformMedia.find((platformMedium) => platformMedium.platformId === platform);
      }
    }
    return false;
  }
  return true;
};

const isFilteredByStatus = (g: UserGame, filterStatus?: UserGameStatus): boolean => (filterStatus ? g.status === filterStatus : true);

const isFilteredByGameCategory = (g: UserGame, cat?: GameCategory): boolean => (cat ? g.game.category === cat : true);

const isFilteredByGenre = (g: UserGame, genre?: string): boolean => (genre ? g.game.genres.includes(genre) : true);

const isFilteredByCollection = (g: UserGame, collection?: string): boolean => (collection ? g.collections.includes(collection) : true);

const isFilteredByGameMode = (g: UserGame, gameMode?: string): boolean => (gameMode ? g.game.gameModes.includes(gameMode) : true);

const isFilteredByPlayerPerspective = (g: UserGame, playerPerspective?: string): boolean => (playerPerspective ? g.game.playerPerspectives.includes(playerPerspective) : true);

const isFilteredByTheme = (g: UserGame, theme?: string): boolean => (theme ? g.game.themes.includes(theme) : true);

export const filterGames =
  (filter: UserGameFilter) =>
  (g: UserGame): boolean =>
    isFilteredByName(g, filter.name) &&
    isFilteredByStatus(g, filter.status) &&
    isFilteredByGenre(g, filter.genre) &&
    isFilteredByPlatformAndMedium(g, filter.platform, filter.medium) &&
    isFilteredByCollection(g, filter.collection) &&
    isFilteredByGameMode(g, filter.gameMode) &&
    isFilteredByPlayerPerspective(g, filter.playerPerspective) &&
    isFilteredByTheme(g, filter.theme) &&
    isFilteredByGameCategory(g, filter.gameCategory);

export const isFilterActive = (filter?: UserGameFilter) =>
  filter
    ? Object.keys(filter)
        .filter((k) => (k as keyof UserGameFilter) !== "name")
        .reduce((acc, cur) => (!acc && filter[cur as keyof UserGameFilter] !== undefined ? true : acc), false)
    : false;

export const countGames = (games: UserGame[]): UserGameCount =>
  games.reduce<UserGameCount>((acc, cur) => {
    const count: number = acc[cur.game.category] || 0;
    return { ...acc, [cur.game.category]: (count || 0) + 1 };
  }, {} as UserGameCount);
