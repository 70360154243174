import { UserGame } from "neorak-game-lib-model";
import { getImageId } from "../../domain/dbImage";
import { ApiService, ImageStorageService, ProfileStorageService } from "../../domain/ports";

export type DeleteGameDependencies = Pick<ApiService, "deleteUserGame"> & Pick<ProfileStorageService, "removeUserGameFromStore"> & Pick<ImageStorageService, "deleteImages">;

export const deleteGame = async (userGame: UserGame, newCoverData: Uint8Array | undefined, selectGame: (game?: UserGame) => void, deps: DeleteGameDependencies) => {
  await deps.deleteUserGame(userGame);
  await deps.deleteImages([getImageId(userGame)]);
  deps.removeUserGameFromStore(userGame);
  selectGame(undefined);
};
