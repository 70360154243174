import styled from "@emotion/styled";
import { Step, StepLabel, Stepper } from "@mui/material";

const StyledStepper = styled(Stepper)`
  margin-bottom: 1rem;
`;

const steps = ["Search Game", "Edit New Game", "Bundled Games (opt)", "Edit Bundled Game (opt)"];

type Props = {
  step: number;
};

export const AddGameStepper: React.FC<Props> = ({ step }) => {
  return (
    <StyledStepper activeStep={step} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
};
