import { DataloadingStatistic } from "../../domain/dataLoadingStatistic";
import { getImageId } from "../../domain/dbImage";
import { ApiService, DataLoadingStatisticStorage, ImageStorageService, ProfileStorageService } from "../../domain/ports";

export const loadGames = async (
  deps: Pick<ApiService, "getUserGames" | "getUserGameCover"> &
    Pick<ProfileStorageService, "startLoadingUserGames" | "loadingUserGamesSuccessful"> &
    Pick<DataLoadingStatisticStorage, "addGameLoadingStatistics" | "updateGameLoadingStatistic"> &
    Pick<ImageStorageService, "getImageIds" | "deleteImages" | "updateImages" | "validateImageIds">
) => {
  const gStatus: DataloadingStatistic = { id: "game", name: "Game", status: "running" };
  const gcStatus: DataloadingStatistic = { id: "gameCover", name: "Game Cover", status: "running" };
  try {
    deps.startLoadingUserGames();
    deps.addGameLoadingStatistics(gStatus);
    deps.addGameLoadingStatistics(gcStatus);
    const userGames = await deps.getUserGames();

    const storedImageIds = await deps.getImageIds();
    const gamesWithMissingImages = userGames.filter((g) => !storedImageIds.includes(getImageId(g)));
    const userGameIds = userGames.map(getImageId);
    const imageIdsToDelete = storedImageIds.filter((gid) => !userGameIds.includes(gid));

    await deps.deleteImages(imageIdsToDelete);

    let loadedCovers = 0;
    for (const game of gamesWithMissingImages) {
      const cover = await deps.getUserGameCover(game._id!);
      if (cover) {
        deps.updateImages([
          {
            id: getImageId(game),
            updated: game.updatedAt!,
            data: new Uint8Array(cover),
          },
        ]);
      } else {
        console.log(`MISSING GAMECOVER: for game id='${game._id}' | name='${game.game.name}'`);
      }
      deps.updateGameLoadingStatistic({ ...gcStatus, value: Math.round((loadedCovers / gamesWithMissingImages.length) * 100) });
    }

    console.log(`LOADED game covers: ${gamesWithMissingImages.length} | DELETED game covers: ${imageIdsToDelete.length}`);

    deps.loadingUserGamesSuccessful(userGames);

    deps.updateGameLoadingStatistic({ ...gStatus, status: "finished" });
    deps.updateGameLoadingStatistic({ ...gcStatus, status: "finished" });
    return userGames;
  } catch (e) {
    console.log(e);
    deps.updateGameLoadingStatistic({ ...gStatus, status: "failed" });
    deps.updateGameLoadingStatistic({ ...gcStatus, status: "failed" });
    return [];
  }
};
