import { ApiService, DataLoadingStatisticStorage, ImageStorageService, ProfileStorageService, RefdataStorageService } from "../../domain/ports";
import { loadGameCollections } from "./loadGameCollections";
import { loadGameModes } from "./loadGameModes";
import { loadGames } from "./loadGames";
import { loadGenres } from "./loadGenres";
import { loadPlatformFamilies } from "./loadPlatformFamilies";
import { loadPlatformMedia } from "./loadPlatformMedia";
import { loadPlayerPerspectives } from "./loadPlayerPerspectives";
import { loadThemes } from "./loadThemes";

export type LoadRefdataDependencies =
  | Pick<
      ApiService,
      | "getFavoritePlatforms"
      | "getGenres"
      | "getGameModes"
      | "getPlayerPerspectives"
      | "getThemes"
      | "getMedia"
      | "getPlatformFamilies"
      | "getPlatforms"
      | "getPlatformMedia"
      | "getUserGames"
      | "getUserGameCover"
      | "getGameCollections"
    > &
      Pick<
        RefdataStorageService,
        | "loadingGameModesSuccessful"
        | "loadingGenresSuccessful"
        | "loadingMediaSuccessful"
        | "loadingPlayerPerspectivesSuccessful"
        | "loadingPlatformsSuccessful"
        | "loadingPlatformFamiliesSuccessful"
        | "loadingThemesSuccessful"
        | "startLoadingGameModes"
        | "startLoadingGenres"
        | "startLoadingMedia"
        | "startLoadingPlatformFamilies"
        | "startLoadingPlatforms"
        | "startLoadingMedia"
        | "startLoadingPlayerPerspectives"
        | "startLoadingThemes"
      > &
      Pick<
        ProfileStorageService,
        | "loadingFavoritePlatformsSuccessful"
        | "loadingGameCollectionsSuccessful"
        | "loadingPlatformMediaSuccessful"
        | "loadingUserGamesSuccessful"
        | "startLoadingFavoritePlatforms"
        | "startLoadingGameCollections"
        | "startLoadingPlatformMedia"
        | "startLoadingUserGames"
      > &
      Pick<DataLoadingStatisticStorage, "addRefdataLoadingStatistics" | "updateRefdataLoadingStatistic" | "addGameLoadingStatistics" | "updateGameLoadingStatistic"> &
      Pick<ImageStorageService, "getImageIds" | "deleteImages" | "updateImages" | "validateImageIds">;

export const loadRefdata = async (deps: LoadRefdataDependencies) => {
  await Promise.all([
    loadGenres({ ...deps }),
    loadGameModes({ ...deps }),
    loadPlayerPerspectives({ ...deps }),
    loadThemes({ ...deps }),
    loadPlatformMedia({ ...deps }),
    loadPlatformFamilies({ ...deps }),
    loadGameCollections({ ...deps }),
    loadGames({ ...deps }),
  ]);
};
